// ts-ignore
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "comps/Button";
import FormError from "comps/FormError";
import { useUserAuthContext } from "context/UserAuthContext";
import Upload from "kodobe-react-upload";
import { Trash} from "../../../assets/svg-code"
import { Controller, useForm,   } from "react-hook-form";
import { documentationSchema } from "validations";
import { useEffect,  } from "react";
import { toast } from "react-hot-toast";
import "../../../App.css";
import { useCheckKYC, useUpdateKYC } from "services/auths";

type FormType = {
	businessCert: string;
	tinCert: string;
	CAC7: string;
	MEMART: string;
	utilityBill : string;
	COI : string;
	CAC2 : string;
};
 
type  CertType = "businessCert" | "tinCert" | "CAC7" | "MEMART" | "CAC2" | "COI" | "utilityBill";
const Documentation = () => {
	const { userDetails: { token, },} = useUserAuthContext();
	const { handlUpdateKYC, loading  } = useUpdateKYC()
	const { handleCheckKYCStatus, KYC  } = useCheckKYC()
	const {handleSubmit,control, formState: { errors, }, getValues, resetField, setValue } = useForm<FormType>({
		reValidateMode: "onBlur",
		defaultValues: {
			businessCert: "",
			tinCert: "",
			COI: "",
			CAC2: "",
			MEMART: '',
			utilityBill : "",
			CAC7: ""
		},
		resolver: yupResolver(documentationSchema),
	});


	
	const fetchUserDetails =   ( ) => {
		setValue("businessCert",  KYC?.businessCert );
		setValue("tinCert", KYC?.tinCert );
		setValue("CAC7", KYC?.meta?.CAC7 );
		setValue("MEMART", KYC?.meta?.MEMART );
		setValue("utilityBill", KYC?.meta?.utilityBill );
		setValue("COI", KYC?.meta?.COI );
		setValue("CAC2", KYC?.meta?.CAC2 );
	};

 
	useEffect(() => {
		fetchUserDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [KYC]);



	const { businessCert, tinCert, CAC7, MEMART, utilityBill , CAC2, COI} =getValues()

 


	const onSubmit = async(data: FormType) => {
		const res: any = await handlUpdateKYC({ ...data });
		if (res.result) {
			toast.success(`Success: KYC updated`, { duration: 3000,});
			handleCheckKYCStatus()
			return;
		} else {
			return toast.error(`Error: ${res?.data?.error}`, { duration: 3000,});
		}
	};

	const fields = [
		{
			label: "Business Card",
			value: businessCert,
			name: "businessCert"
		},
		{
			label: "TIN Certificate CAC",
			value: tinCert,
			name: "tinCert"
		},
		{
			label: "Certificate CAC7",
			value: CAC7,
			name: "CAC7"
		},
		{
			label: "Certificate MEMART",
			value: MEMART,
			name: "MEMART"
		},
		{
			label: "Certificate CAC2",
			value: CAC2,
			name: "CAC2"
		},
		{
			label: "Certificate COI",
			value: COI,
			name: "COI"
		},
		{
			label: "Business utility bill",
			value: utilityBill,
			name: "utilityBill"
		},
	]
   

	return (
		<div>
			<form
				onSubmit={handleSubmit(onSubmit, e=> console.log(e))}
				className=" w-full bg-white text-center md:w-6/12 mt-10 drop-shadow-sm mx-auto border border-[#D5D7D8] rounded-lg p-4 md:p-16 min-h-[440px]"
			>
				<span className="text-[#1A1B1C] general-sans text-2xl font-medium">Upload Documents</span>

				{
					fields.map((field) => (
						<div className="text-left mb-5" key={field.name}>
							<label>{field.label}</label>
								<Controller
									name={field.name as CertType}
									control={control}
									render={({ field: {onChange, value } }) =>  {
										return (
											<div >
												<div className="flex justify-between border border-current border-solid p-1 items-center rounded rm-img-border" >
													<Upload
														validImageTypesSrc={["jpg", "jpeg", "png", "pdf", "docs", "docx", "doc"]}
														uploadUrl={process.env.REACT_APP_UPLOAD_URL}
														fileName="file"
														title={"Select file"}
														onChange={(e) => {
															if (!e.completed) {
															} else if (e.completed) {
																onChange(  e?.responseFromServer?.data?.url );
															}
														}}
														headers={{ token: token, "client-id": process.env.REACT_APP_CLIENT_ID}}
													/>
													{value && <div onClick={() => resetField(field.label as CertType)} className="cursor-pointer"><Trash /></div>}
												</div>

												<FormError error={errors?.[field?.label as CertType]?.message} />
												<div className="spacer-10"></div>
												{
													value || field.value ? 
														<div style={{width: "50px", height: "50px"}}>
															<img src={value || field.value}   alt=""  className="object-contain w-full h-full"/> 
														</div>
														: null
												}

											</div>
										)
									}}
								/>
								{
								}
							
						</div>
					))
				}

 

				<Button
					variant="primary"
					type="submit"
					className="w-40 mx-auto flex justify-center mt-10 drop-shadow-md"
				>
					 {loading ? "..." : "Save Uploads"}
					
				</Button>
			</form>
		</div>
	);
};

export default Documentation;
