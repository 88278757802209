import { Tabs } from "antd";
import AuthLayout from "comps/AuthLayout";
import   { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import APIKeysWebhooks from "./comps/APIKeysWebhooks";
import ChangePassword from "./comps/ChangePassword";
import Documentation from "./comps/Documentation";
import Overview from "./comps/Overview";
import Personalization from "./comps/Personalization";
import { useGetAdmin } from "hooks/useAuth";
import SenderId from "./comps/senderId";

const Settings = () => {
	const [activeKey, setActiveKey] = useState<any>("item-1");
	const handleChange = (keyName: string) => {
		setActiveKey(keyName);
	};
	const location = useLocation();
	const {isAdmin } = useGetAdmin()
 

	useEffect(() => {
		setActiveKey(location?.state?.activeKey)
	}, [location?.state?.activeKey]);



	const settingsTabs = [
		{ label: "Overview", key: "item-1", children: <Overview /> },
		{ label: "Personalization", key: "item-2", children: <Personalization /> },
		{ label: "Documentation", key: "item-3", children: <Documentation /> },
		{ label: "Change Password", key: "item-4", children: <ChangePassword /> },
		{
			label: "API Keys & Webhooks",
			key: "item-5",
			children: <APIKeysWebhooks handleChange={handleChange} />,
		},
		{ label: "Sender ID", key: "item-6", children: <SenderId /> },
	];
	return (
		<AuthLayout name="Settings">
			{
				isAdmin ?
					<ChangePassword />
					:
					<Tabs
						items={settingsTabs}
						activeKey={activeKey}
						onChange={(activeKey) => {
							setActiveKey(activeKey);
						}}
					/>
			}
		</AuthLayout>
	);
};

export default Settings;
