import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "antd";
import Input from "comps/Input";
import { useDownLoadMerchantReport } from "hooks/useMerchants";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { DownLoadCSVSchema,   } from "validations";

type PayloadType = {
  from: string;
  to: string;
};

export  const getYYYYMMDD = (date: Date | string) => new Date(date).toISOString().split("T")[0];


type PropType = {
	openModal:  {type: string},
	setModal: React.Dispatch<React.SetStateAction<{type: string}>>
};
 


const DownloadReport: React.FC<PropType> = ({openModal, setModal}) => {
	const { handleDownloadMerchantReport, loading: loadingDowload} = useDownLoadMerchantReport()

	const { control, handleSubmit, formState: { errors }, reset,   } = useForm<PayloadType>({
		defaultValues: { from:    getYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - 30))), to:  getYYYYMMDD(new Date())},
		resolver: yupResolver(DownLoadCSVSchema),
	});

	
	const onSubmit = async (data: PayloadType) => {
		const res =  await handleDownloadMerchantReport({from: getYYYYMMDD(data.from), to: getYYYYMMDD(data.to)})
		 if(res?.data){
			reset()
			setModal({type: ""});
		 }
	};
 


	return (
		<Modal
			open={openModal.type === "DOWLOAD_REPORT" }
			title={ "Download Merchant Report"}
			onCancel={() => {
				setModal({type: ""});
			}}
			okText={ "Download"}
			okButtonProps={{ htmlType: "submit" }}
			onOk={handleSubmit(onSubmit,  (e) => [])}
			confirmLoading={loadingDowload}
		>
 
		 
				<Controller
					control={control}
					name="from"
					render={({ field  }) => (
						<Input
							label="Start date"
							extraClass="mb-5"
							type="date"
							error={errors.from?.message}
							{...field}
							ref={null}
						/>
					)}
				/>
				<Controller
					control={control}
					name="to"
					render={({ field}) => (
						<Input
							label="End date"
							extraClass="mb-5"
							type="date"
							error={errors.from?.message}
							{...field}
							ref={null}
						/>
					)}
				/>
	 
		</Modal>
	);
};

export default DownloadReport;
