// ts-ignore
import { SearchIcon } from "assets/svg-code";
import Button from "comps/Button";
import CustomTable from "comps/CustomTable";
import { useUserAuthContext } from "context/UserAuthContext";
import { useGetAdmin } from "hooks/useAuth";
import {  useGetTransaction } from "hooks/useTransactions";
import moment from "moment";
import {    useEffect, useState } from "react";
import * as _ from "underscore";
import DownloadReport from "./downloadReport";
 
 
const Transaction = ({ filterValue = "", filterKey = ""}: {filterValue?: string | number, filterKey?: string}) => {


	const { userDetails: { user: { userId }, }, } = useUserAuthContext();
	const [ready, setReady] = useState(false)
	const { isAdmin } = useGetAdmin()
	const [modal, setModal]  = useState({type: ""})
	const { handleGetTransactions , transactions, loading} = useGetTransaction(filterKey === "userId" ? filterValue : userId)
	const [filter, setFIlter] = useState(filterKey || "")
	const [filteredValue, setFilteredValue] = useState(filterValue || "")
	const optimisedTriggerHandler = _.throttle((e: any) => setFilteredValue(e), 2000, { leading: false });

	const [ , setPage] = useState<number>(1);

	
	useEffect(() => {
		setReady(true)
		if(ready){
			handleGetTransactions( filter &&filteredValue ? `${filter}=${filteredValue}` : "")
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredValue, filter, ready]);

	const columns = [
		{
			title: "S.N",
			index: "sn",
			dataIndex: "s/n",
			render: (text: string, record: {}, index: number) => (
				<div>{index + 1}</div>
			),
		},
		{
			title: <div>Date</div>,
			render: (record: any) => (
				<div>{moment(record?.createdAt * 1000).format("DD/MM/YYYY")}</div>
			),
		},
		{
			title: <div>Transaction ID</div>,
			render: (record: any) => {
				return <div>{record?.id}</div>;
			},
		},
		{
			title: "Business Name",
			render: (record: any) => {
				return <div>{record?.businessName || "-"}</div>;
			},
		},
		{
			title: "Transaction Channel",
			render: (record: any) => {
				return <div>{record?.channel || "-"}</div>;
			},
		},
		{
			title: <div>Phone Number</div>,
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			render: (phoneNumber: any) => <div>{phoneNumber}</div>,
		},
		{
			title: <div>Rate</div>,
			key: "rate",
			render: (record: any) => <div>{record?.serviceAmount}</div>,
		},
		{
			title: <div>Status</div>,
			key: "rate",
			render: (record: any) => <div>
				<span className={"px-5 py-2 text-xs"} 
					style={{backgroundColor: record?.status === "PENDING" ? "#f8fbaf" : record?.status === "SUCCESS" ? "#c5f4c5" : "#d6d6ff" }}
					>{record?.status}</span>
			</div>,
		},
	];


 


	
 

  return (
    <>
    	<div   className="py-3  lg:gap-6 xl:gap-6 flex  grid-cols-1 flex-wrap md:grid-cols-2">
			<div className="flex items-center">
				<span className="">Filter by</span>
			</div>
			<select  className="px-5 py-2 mx-4  rounded bg-transparent border border-slate-300 border-solid"  
				onChange={(e) => {
					setFIlter(e.target.value)
					setFilteredValue("")
				}}
			>
				<option value={""}>Select</option>
				{
					filterKey === "phoneNumber" ? null :
					<option value={"phoneNumber"}>Phone number</option>
				}
				<option value={"customerId"}>Customer ID</option>
			</select>
               

			  <div  className="flex-grow-0   w-auto  grow max-w-xs flex items-center border  border-[#D5D7D8] bg-[#FEFDFD]  rounded-lg  gap-x-2 px-2">
					<SearchIcon /> 
					<input
						className="border h-12 text-sm focus:outline-none block w-full   border-transparent p-2  "
						style={{cursor: !filter ? "not-allowed": "auto"}}
						type="search"
						name="search"
						disabled={!filter}
						onChange={(e) => optimisedTriggerHandler(e.target.value)}
						placeholder={!filter ? "Select a filter option" :`Search by ${filter}`}
					/>
            </div>
			{
				isAdmin ?
					<Button
						btnText={ "Download Report"}
						variant="primary"
						type="button"
						className="w-40 ml-auto lg:mt-0 mt-3 ml-3 flex justify-center  drop-shadow-md"
						onClick={() => setModal({type: "DOWLOAD_REPORT"})}
					/>
					: null
			}
			 
		</div>


      <div className="mt-10 w-full">
        <CustomTable
          tableName="Customers"
          dataSource={transactions?.docs?.map((data: {[id: string]: string}) => ({...data, key: data?.id}))}
          loading={loading}
          columns={columns}
          pagination={{
            pageSize: 10,
            total: transactions?.length,
            onChange: (page: any) => {
              setPage(page);
            },
          }}
        />
      </div>

	  <DownloadReport openModal={modal}  setModal={setModal}   />

    </>
  );
};

export default Transaction;
