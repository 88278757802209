 
import axios from "axios";
import { logOut } from "utils/utilis";

const safepro: any = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 120000,
});


 

// Add a response interceptor
safepro.interceptors.response.use(function (response:any) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // console.log("response >> interceptors", response);
  
  return response;
}, function (error:any) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log("error >> interceptors", error);
  if(error?.response?.status === 401 && error?.response?.data?.error === "You are unauthorised to access this resource!!!"){
    logOut()
  }
  return Promise.reject(error);
});
export default safepro;






export const safeproMiddleWare: any = axios.create({
  baseURL: process.env.REACT_APP_SAFEPRO_MIDDLEWARE + "/api/v1",
  timeout: 120000,
})

safeproMiddleWare.interceptors.response.use(function (response:any) {
  return response;
}, function (error:any) {
  if(error?.response?.status === 401 && error?.response?.data?.error === "You are unauthorised to access this resource!!!"){
    logOut()
  }
  return Promise.reject(error);
});




export const safeproMiddleWare2: any = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/otp-middleware/api/v1",
  timeout: 120000,
})

safeproMiddleWare2.interceptors.response.use(function (response:any) {
  return response;
}, function (error:any) {
  if(error?.response?.status === 401 && error?.response?.data?.error === "You are unauthorised to access this resource!!!"){
    logOut()
  }
  return Promise.reject(error);
});


