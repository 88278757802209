


import React from "react";
import "../../../App.css";


interface IntegrationLayoutTypes {
	sidebar: React.ReactNode;
	type: string;
}
const Layout = (props: IntegrationLayoutTypes) => {
	const { sidebar, type } = props;

	const documentations = {
		"2-way": "https://documenter.getpostman.com/view/10199411/2s93Jxs1uA",
		"direct": "https://documenter.getpostman.com/view/10199411/2s93Jxs1ps",
		//   "kyc": "https://documenter.getpostman.com/view/10199411/2s93Jxs1pp"
	}

	return (
		<div className="w-full flex flex-col   md:gap-y-0 md:flex-row">
			<div className="w-full lg:w-6/12 ">
				{sidebar}


				{
					(type === "redirect" || type === "kyc") ? null :
						<div className="mt-3">
							<span className="general-sans-light font-light text-xl text-[#1A1B1C]">
								To view the code documentations
								<button className="text-xl font-normal text-[#184E77] ml-2"
									onClick={() => window.open(documentations[type as ("direct" | "2-way")], "_blank")}
								>
									Click Here
								</button>
							</span>
						</div>
				}

			</div>

		</div>
	);
};

export default Layout;
