import {  leftarrow,   } from "assets/svg-code";
import Button from "comps/Button";
import Input from "comps/Input";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IntegrateCardDetailsType } from "utils/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { redirectUrlSchema, webhookUrlSchema } from "validations";
import    "../../../App.css";
import {   updateWebookUrl,} from "services";
import { useUserAuthContext } from "context/UserAuthContext";
import toast from "react-hot-toast";
 
 
 

const TwoWaySMS = (props: IntegrateCardDetailsType) => {
	const { title,  goBack,   } = props;
	const [webhookLoading, setWebhookLoading] = useState(false);
	const { user } = useUserAuthContext()

	useEffect(() => {
		fetchUserDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	const { userDetails: { token, user: { userId }, }, } = useUserAuthContext();
	const {  setValue,  } = useForm<{ redirectUrl: string; enableKYC: boolean; webhookUrl: string }>({
		reValidateMode: "onBlur",
		defaultValues: {
			redirectUrl: "",
			enableKYC: false,
			webhookUrl: "",
		},
		resolver: yupResolver(redirectUrlSchema),
	});
 
	const webHookForm = useForm<{ webhookUrl: string }>({
		reValidateMode: "onBlur",
		defaultValues: { webhookUrl: user?.profile?.webhookUrl || "", },
		resolver: yupResolver(webhookUrlSchema),
	});

 

	const submitWebhookUrl = async (data: any) => {
		setWebhookLoading(true);
		const res = await updateWebookUrl(userId, data?.webhookUrl, token);

		setWebhookLoading(false);
		if (res.status >= 200 && res.status <= 299) {
			toast.success(`Webhook created success`);
		} else {
			toast.error(`Error: ${res.data.error}`);
		}
	};

	
	const fetchUserDetails = async () => {
		setValue("redirectUrl", user?.profile?.redirectUrl);
		setValue("webhookUrl", user?.profile?.webhookUrl);
		if (user?.profile?.enableKYC) {
			setValue("enableKYC", true);
		}
	};


 
	return (
		<div className="flex flex-col">
			<div className="flex items-start">
				<button className="appearance-none pt-2" onClick={() => goBack("inactive")} >{leftarrow}</button>

				<div className="flex flex-col ml-5 w-full">
					<h1 className="text-2xl font-semibold   text-[#1A1B1C] mb-2 general-sans">
						{title}
					</h1>
		
              
			 		<span className="general-sans-light font-normal  text-base font-normal">
						After initiating  2-Way Messaging, Your customers receives an SMS from a shortcode and can verify via SMS,Whatsapp,Telegram etc. On success, you receive an event on your webhook.
					</span>
					 
				
         
					<form onSubmit={webHookForm.handleSubmit(submitWebhookUrl)} className="flex flex-col mt-8">
						<span className="text-xl general-sans" style={{color: "#1A1B1C"}}>
							Provide a Webhook URL
						</span>
						<p className="text-[#404345] text-base my-2 w-10/12 general-sans-light">
							This webhook URL gets notified once your customer provides a
							valid OTP
						</p>
						<Controller
							name="webhookUrl"
							control={webHookForm.control}
							render={({ field: { onChange, onBlur, value } }) => (
								<Input
									name="webhookUrl"
									className="w-10/12"
									placeholder="https://sanimara.com/url"
									onChange={onChange}
									onBlur={onBlur}
									value={value }
									type="url"
									error={ webHookForm.formState.errors?.webhookUrl?.message!}
								/>
							)}
						/>
						<Button
							type="submit"
							variant="hover"
							className="w-[100px] mt-5"
							disabled={webhookLoading}
						>
							Submit
						</Button>
					</form>
         
         
         
        </div>
      </div>
    </div>
  );
};

export default TwoWaySMS;
