import { safeproMiddleWare,   } from "axiosApi";
import { useUserAuthContext } from "context/UserAuthContext";
import { useState } from "react";
import { handleRequestError } from "utils";







export const useGetCustomers =   () => {
	const [loading, setLoading] = useState(false)
	const [customers, setCustomers] = useState({docs: [], total: 0})
	const { userDetails: { token, },} = useUserAuthContext();

	

	const handleGetCustomers = async (userId: string, queryString?: string) => {
		setLoading(true)
		try {
			
			const response =  await safeproMiddleWare.get( 
				`/otp/customers?businessId=${userId}${(queryString && queryString?.length > 0) ? `&${queryString}` : ""}`,
				{
					headers: {
					"client-id": process.env.REACT_APP_CLIENT_ID,
					"Authorization": `Bearer ${token}`
					},
				}
			);
			setLoading(false)
			if(response) {
				setCustomers(response?.data?.data)
			}
			return response;
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
	 return { handleGetCustomers, loading, customers }
};