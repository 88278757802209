import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "antd";
import { CopyIcon } from "assets/svg-code";
import Button from "comps/Button";
import Input from "comps/Input";
import { useApiKeysEnvironmentContext } from "context/ApiKeysEnvironment";
import { useUserAuthContext } from "context/UserAuthContext";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { fetchBusiness, getApiKeys, login } from "services";
import { getUser, useUpdateUser } from "services/auths";
import { loginFormTypes } from "utils/types";
import { loginSchema } from "validations";

type BusinessDetails = {
  businessName: string;
  clientId: string;
  createdAt: string;
  id: string;
  publicKey: string;
  secret: string;
  updatedAt: string;
  userId: string;
  meta?: {};
};
const APIKeysWebhooks = (props: any) => {
  const { handleChange } = props;
  const [secretType, setSecretType] = useState<"password" | "text">("password");
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apikeKeys, setApikeys] = useState(null);
  const [businessDetails, setBusinessDetails] = useState({} as BusinessDetails);
  const { userDetails: { user: { userId }, user, }, setUserDetails, } = useUserAuthContext();

  
	const { handleUpdateUser } = useUpdateUser()
 


	const { control, handleSubmit, formState: { errors },} = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
		mode: "onTouched",
		resolver: yupResolver(loginSchema),
	});

	type ApiKeyForm = {
		// apiKey: string;
		publicKey: string;
		callbackUrl: string;
		webhookUrl: string;
	};
	const apiForm = useForm<ApiKeyForm>({
		defaultValues: {
			publicKey: businessDetails.publicKey || "",
			callbackUrl: user?.profile?.redirectUrl || "",
			webhookUrl: user?.profile?.webhookUrl || "",
		},
		mode: "onTouched",
	});

	const fetchUserDetails = async () => {
		const res = await getUser(userId);
		if (res.status >= 200 && res.status <= 299) {
			apiForm.setValue("webhookUrl", res.data?.data?.profile?.webhookUrl);
			apiForm.setValue("callbackUrl", res.data?.data?.profile?.callbackUrl);
		} else {
		}
	};

	useEffect(() => {
		fetchUserDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	




	const onSubmitApiKeys = async (data: ApiKeyForm) => {
		// console.log(data);
		await handleUpdateUser({
			webhookUrl: data?.webhookUrl,
			callbackUrl: data?.callbackUrl,
		})
		fetchUserDetails()
	};

	const onSubmit = async (data: loginFormTypes) => {
		const { email, password } = data;
		setLoading(true);
		const res = await login({ email, password });
		setLoading(false);
		if (res.status >= 200 && res.status < 299) {
			const keys = await getApiKeys(userId);
			console.log("api - keys", keys);
			if (keys.status >= 200 && keys.status <= 299) {
				toast.success(`${keys?.data?.message || "Success"}`);
				setSecretType("text");
				setApikeys(keys?.data?.data?.secretKey);
				setLoginModal(!loginModal);
			} else toast.error(`Error: No Api-keys yet! try again`);

			setUserDetails(res?.data?.data);
			localStorage.setItem("authDetails", JSON.stringify(res?.data?.data));
			return;
		} else {
		return toast.error(`Error: ${res?.data?.error}`, {
			duration: 3000,
		});
		}
	};

  const fetcBusinessDetails = async () => {
    const data = await fetchBusiness(userId);
    if (data.status >= 200 && data.status <= 299) {
      setBusinessDetails(data?.data?.data?.docs?.[0]);
			apiForm.setValue("publicKey", data?.data?.data?.docs?.[0].publicKey);
			apiForm.setValue("callbackUrl", user?.profile?.redirectUrl);
			apiForm.setValue("webhookUrl", user?.profile?.webhookUrl);
    }
  };
  useEffect(() => {
    fetcBusinessDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyPayload = (payload: string) => {
    navigator.clipboard.writeText(payload);
    toast.success(`Public Key Copied!`);
  };

  const { apikeysEnvironment } = useApiKeysEnvironmentContext();
  if (apikeysEnvironment === "test") {
    return (
      <div className=" w-full bg-white text-center md:w-6/12 mt-10 drop-shadow-sm mx-auto border border-[#D5D7D8] rounded-lg p-4 py-10 min-h-[440px]">
        <Modal
          open={loginModal}
          title="Provide Account Details"
          onCancel={() => setLoginModal(!loginModal)}
          okText="Submit"
          okButtonProps={{ htmlType: "submit" }}
          onOk={handleSubmit(onSubmit)}
          confirmLoading={loading}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <small className="text-pink-700 font-bold">
              Provide your account details to get your secret api keys!
            </small>
            <div className="flex flex-col gap-y-5">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    extraClass="w-full"
                    error={errors?.email?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Password"
                    type="password"
                    placeholder="Enter your password"
                    extraClass="w-full"
                    error={errors.password?.message}
                  />
                )}
              />
            </div>
          </form>
        </Modal>
        <div className="w-11/12 mx-auto">
          <h1 className="mb-6">API Configuration</h1>
          <form
            onSubmit={apiForm.handleSubmit(onSubmitApiKeys, e=> console.log(e))}
            className="w-11/12 mx-auto text-left"
          >
            <div className="flex items-center gap-x-3">
              <Input
                label="Secret Key"
                placeholder="********************"
                extraClass="mb-4 flex-1"
                className="font-bold text-black"
                disabled
                type={secretType}
                value={apikeKeys!}
              />
              <Button
                onClick={() => setLoginModal(!loginModal)}
                className="text-xs mt-1 p-0 w-fit h-fit"
                type="button"
              >
                View Secret
              </Button>
            </div>
            <div className="mb-4 flex items-end w-full gap-x-2">
              <Controller
                control={apiForm.control}
                name="publicKey"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Public Key"
                    placeholder="not provided ..."
                    extraClass="w-full"
                    disabled
                    className="text-gray-400 font-bold"
                  />
                )}
              />
              <button
                type="button"
                title="copy public key"
                onClick={() => copyPayload(businessDetails?.publicKey)}
                className="bg-gray-100 rounded-[4px] p-[10px] shadow-sm flex items-center justify-center"
              >
                <CopyIcon />
              </button>
            </div>
            <Controller
              control={apiForm.control}
              name="callbackUrl"
              render={({ field }) => (
                <Input
                  {...field}
                  name="callbackUrl"
                  label="Redirect URL"
                  placeholder="Redirect URL"
                  extraClass="mb-4"
                />
              )}
            />

            <Controller
              control={apiForm.control}
              name="webhookUrl"
              render={({ field }) => (
                <Input
                  {...field}
                  name="webhookUrl"
                  label="Webhook URL"
                  placeholder="Webhook URL"
                  extraClass="mb-4"
                />
              )}
            />

            <Button
              variant="primary"
              type="submit"
              // disabled={imageData?.loading || loading}
              className="w-40 mx-auto flex justify-center mt-10 drop-shadow-md"
            >
              Save Changes
            </Button>
          </form>
        </div>
      </div>
    );
  } else
    return (
      <div className="flex items-center justify-center w-full bg-white text-center md:w-6/12 mt-10 drop-shadow-sm mx-auto border border-[#D5D7D8] rounded-lg p-4 py-10 min-h-[440px]">
        <div className="bg-[#F3F5F8] p-3 h-fit">
          <p className="text-primary">
            To access the Live mode,Kindly provide the documents required by{" "}
            <button type="submit" onClick={() => handleChange("item-3")}>
              click here
            </button>
          </p>
        </div>
      </div>
    );
};

export default APIKeysWebhooks;
