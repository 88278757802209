

import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "antd";
import Input from "comps/Input";
import { useCreateSenderId,   } from "hooks/useSederId";
import { InputComponent } from "pages/SenderId/senderIdModal";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form"; 
import { createSenderIdSchema } from "validations";
 

 
type SenderIdType = {
	senderId:  string,
	gloIntentLetter:  string,
	mtnIntentLetter:  string,
	"9mobileIntentLetter": string,
	"airtelIntentLetter": string,
  };
   
type PropType = {
	openModal:  {type: string},
	setModal: React.Dispatch<React.SetStateAction<{type: string}>>
	onDone: () => void
	senderId:  {[e: string]: any},
	setSenderId: React.Dispatch<React.SetStateAction< {[e: string]: any}>>
  };
   


const CreateSenderIdModal: React.FC<PropType> = ({openModal, setModal, onDone, senderId, setSenderId}) => {
 

  const { handleCreateSenderId, loading  } =  useCreateSenderId()


 

	  const { control, handleSubmit, formState: { errors }, setValue , reset} = useForm<SenderIdType>({
		defaultValues: {
			senderId: "",
			gloIntentLetter: "",
			mtnIntentLetter: "",
			"9mobileIntentLetter": "",
			"airtelIntentLetter": "",
		},
		resolver: yupResolver(createSenderIdSchema),
	  });


	  useEffect(() => {
		if(Object.keys(senderId)?.length > 0) {
			setValue("senderId" ,  senderId["senderId"])
			setValue("gloIntentLetter" ,  senderId["gloIntentLetter"])
			setValue("mtnIntentLetter" ,  senderId["mtnIntentLetter"])
			setValue("9mobileIntentLetter",  senderId["9mobileIntentLetter"])
			setValue("airtelIntentLetter",  senderId["airtelIntentLetter"])
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ senderId?.senderId, senderId?.gloIntentLetter , senderId?.mtnIntentLetter , senderId["9mobileIntentLetter"], senderId?.airtelIntentLetter  , setValue,  ])


	useEffect(() => {
		if(openModal.type === "CREATE_SENDER_ID") {
			reset()
		}
	}, [openModal.type, reset])

	  const onSubmit = async (data: SenderIdType) => {
		if(Object.keys(senderId)?.length > 0) {
			setModal({type: ""})
			setSenderId({})
		}else {
			const res = await handleCreateSenderId(  data);
			if(res?.data) {
				onDone()
				setModal({type: ""})
			}
		}
	  };



  return (
		<Modal
			open={openModal.type === "CREATE_SENDER_ID" || openModal.type ==="VIEW_SENDER_ID"}
			title={`${openModal.type === "VIEW_SENDER_ID" ? "View" : "Create"}   Sender ID`}
			
			onCancel={() => {
				setModal({type: ""});
				setSenderId({})
			}}
			okText={ openModal.type === "VIEW_SENDER_ID"  ? "" : "Create Sender ID"}
			okButtonProps={{ htmlType: "submit" }}
			onOk={handleSubmit(onSubmit,  
				(e) => {
					if(Object.keys(senderId)?.length > 0) {
						setModal({type: ""})
						setSenderId({})
					}
				}
			)}
			confirmLoading={loading}
		>

			{/* <span>To create sender ID, kindly provide the  following documents.</span> */}
		 
		 <br />
		 <br />
			<form onSubmit={handleSubmit(onSubmit)} >
				<Controller
					control={control}
					name="senderId"
					render={({ field }) => (
						<Input
							label="Sender ID"
							placeholder="Enter your senderId"
							extraClass="mb-5"
							type="text"
							error={errors.senderId?.message}
							{...field}
						/>
					)}
				/>
			 

			 <Controller
					control={control}
					name="gloIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: openModal.type === "VIEW_SENDER_ID", iconUrl: "https://static-00.iconduck.com/assets.00/globacom-limited-icon-1024x1024-upx9lebq.png", name: "gloIntentLetter"}}/>}
				/>

				<Controller
					control={control}
					name="mtnIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: openModal.type === "VIEW_SENDER_ID", iconUrl: "https://static-00.iconduck.com/assets.00/mtn-icon-2048x2048-b2l264nq.png", name: "mtnIntentLetter"}}/>}
				/>

				<Controller
					control={control}
					name="9mobileIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: openModal.type === "VIEW_SENDER_ID", iconUrl: "https://static-00.iconduck.com/assets.00/9mobile-icon-295x512-n7s4ssgv.png", name: "9mobileIntentLetter"}}/>}
				/>

				<Controller
					control={control}
					name="airtelIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: openModal.type === "VIEW_SENDER_ID", iconUrl: "https://seeklogo.com/images/A/airtel-logo-2F959F4207-seeklogo.com.png", name: "airtelIntentLetter"}}/>}
				/>

			</form>
		</Modal>

  );
};

export default CreateSenderIdModal;
