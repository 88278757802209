

import {   leftarrow,     } from "assets/svg-code";
import { StepType } from "utils/types";
import    "../../../App.css";


 

const KYC_BVN = (props: {title: string, goBack: (e: StepType) => void}) => {
  const { title,  goBack,   } = props;
 

	
	return (
		<div className="flex flex-col">
			<div className="flex items-start">
				<button className="appearance-none pt-2" onClick={() => goBack("kyc")}>
				{leftarrow}
				</button>
				<div className="flex flex-col ml-5 w-full">
					<div>
						<h1 className="text-2xl font-semibold font-sourceSans text-[#1A1B1C] mb-2">
							<span className="capitalize">{title}</span>
						</h1>
					</div>
				<p  className="w-10/12  general-sans-light font-normal text-xl text-[#404345]">
					The Bank Verification Number (BVN) is a unique 11-digit identifier designed to enhance the security and efficiency of banking operations in Nigeria. It serves as a universal identifier across all banks and financial institutions in the country, linking an individual's biometric data with their banking information. By creating a secure and reliable way to verify customer identities, the BVN system reduces the risk of fraudulent activities, protects customer information, and streamlines banking operations.	
				</p>
				</div>
			</div>


		</div>
	);
};

export default KYC_BVN;
