import { rightarrow } from "assets/svg-code";
import { useUserAuthContext } from "context/UserAuthContext";
import { CardCompInterface } from "utils/types";
import    "../App.css";
import { CURRENCY_STRING } from "../constants";



const Card = (props: CardCompInterface  ) => {
  const { title, description, moreDescription,  handleStep, type, amount } = props;
  const {   KYC } = useUserAuthContext();


	const comingSoon = ["DeviceAuth"].includes(type)
	const disabledByKYC = ["kyc", "2-way"].includes(type) &&  KYC?.status !== "APPROVED" ;
	const disabled = ["DeviceAuth"].includes(type)  ;

	return (
		<div
			onClick={() =>  disabledByKYC || disabled ? [] : handleStep(type)}
			className={`rounded-2xl border p-5  mb-5 w-full space-y-5 
				${  disabledByKYC || disabled ? "opacity-20 cursor-not-allowed" : "cursor-pointer "}
			`}
		>
			<div className="flex justify-between items-center">

				<div className="flex items-center">
					<h1 className=" font-medium text-[34px] general-sans-regular m-0 mr-4">{title}</h1>
					{
						comingSoon ?
						<span className=" font-medium text-[16px] general-sans-regular m-0 mr-4 text-[#cc0e0e] ">(coming soon)</span>
						: null
					}
					{
						disabledByKYC ?
						<span className=" font-medium text-[16px] general-sans-regular m-0 mr-4 text-[#cc0e0e] ">(Complete KYC)</span>
						: null
					}
				</div>
				{
					(typeof(amount) === "string" || typeof(amount) === "number" )?
					<span className="text-[#184E77] text-base font-medium">{CURRENCY_STRING}{amount}/Request</span>
					: null
				}
			</div>

			<small>{moreDescription}</small>

			<div className="flex justify-between items-center leading-7 text-[#404345] pb-10">
				<p className="w-10/12  general-sans-light font-normal text-2xl"
					style={{color: "#404345"}}
				>{description}</p>
				{rightarrow}
			</div>
		</div>
	);
};

export default Card;
