import { yupResolver } from "@hookform/resolvers/yup";
import AuthRight from "comps/AuthRight";
import Button from "comps/Button";
import Input from "comps/Input";
import { useIsTokenValid,   } from "hooks/useValidateTokken";

import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { changeMyPassword,   } from "services";
import { resetPasswordSchema } from "validations";

const ResetPassword = (data: any) => {
	const navigate = useNavigate();
	
	useIsTokenValid()

	const location = useLocation()
	if(!location.state.email) navigate("/forgot-password");
	const { control, formState: { errors }, handleSubmit } = useForm({
		defaultValues: {
			otp: "",
			password: "",
			confirmPwd: "",
		},
		mode: "onTouched",
		resolver: yupResolver(resetPasswordSchema),
	});


	const handleResetPassword = async (data: any) => {
		const res = await changeMyPassword({
			email: location?.state?.email,
			code: data.otp,
			password: data.password,
		});
		if (res.status >= 200 && res.status <= 299) {
			toast.success(`Success: Password Reset Successfully` , { duration: 3000,});
			navigate("/login");
		} else {
			toast.error(`Error: ${res?.data?.error}`, { duration: 3000,});
		}
		 
	};
  return (
    <div className="text-primary w-full h-screen flex  flex-col md:flex-row">
      <div className="bg-white p-5 w-full md:w-6/12 h-full">
        <div className="w-full md:w-10/12 py-20 mx-auto h-full">
          <h1 className="text-3xl mb-4 font-bold font-openSans">
            Reset Password
          </h1>
          <h3 className="text-base font-openSans">
            You can reset your password by entering your
            <br /> details below
          </h3>

          <form
            onSubmit={handleSubmit(handleResetPassword)}
            className="w-full md:w-9/12 mt-5 md:mt-10"
          >
            <Controller
              control={control}
              name="otp"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  type="text"
                  label="OTP"
                  placeholder="Enter OTP Code"
                  className="mb-5"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors?.otp?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  type="password"
                  label="Password"
                  placeholder="Enter password"
                  className="mb-5"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors?.password?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPwd"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  type="password"
                  label="Re-Type Password"
                  placeholder="Re-Type Password"
                  className="mb-5"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors?.confirmPwd?.message}
                />
              )}
            />

            <div className="w-full mt-4 md:mt-8">
              <Button type="submit" btnText="Submit" />
            </div>
          </form>
        </div>
      </div>
      <AuthRight />
    </div>
  );
};

export default ResetPassword;
