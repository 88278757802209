import { safeproMiddleWare } from "axiosApi";
import { useUserAuthContext } from "context/UserAuthContext";
import {   useState } from "react";
import { handleRequestError } from "utils";
import { useGetAdmin } from "./useAuth";
import toast from "react-hot-toast";




export const useGetTransaction = (userId: string) => {
	const [loading, setLoading] = useState(false);
	const [transactions, setTransactions] = useState<any>({});
	const { userDetails: {   token, }} = useUserAuthContext();
	const { isAdmin } = useGetAdmin()


	const handleGetTransactions = async ( queryString: string) => {
		setLoading(true)
		try {
			const response = await safeproMiddleWare.get(
				isAdmin?
					`/admin/transactions${(queryString && queryString?.length > 0) ? `?${queryString}` : ""}`
					:  `/all/transactions?businessId=${userId}${(queryString && queryString?.length > 0) ? `&${queryString}` : ""}`,
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  "Authorization": `Bearer ${token}`
				},
			  }
			);
			setLoading(false)
			if(response.data){
				setTransactions(response?.data?.data)
				return response;
			}
			
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
 
	return { handleGetTransactions, loading , transactions}
}



export const useDownLoadTransactionReport = ( ) => {
	const [loading, setLoading] = useState(false);
	const { userDetails: {   token, }} = useUserAuthContext();

	
	const handleDownloadTransactionReport = async ({from, to}: {from: string, to: string} ) => {
		setLoading(true)
		try {
			const response = await safeproMiddleWare.get(
				 `/admin/export/transaction?from=${from}&to=${to}`,
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  "Authorization": `Bearer ${token}`
				},
			  }
			);
			setLoading(false)
			if(response.data){
				const url = window.URL.createObjectURL(new Blob([response?.data?.data]));
				const link = document.createElement("a");
				link.href = url;
				const fileName = `downloaded Report ${new Date().toLocaleString().split(", ").join("-")}.csv`;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
				return {data: response?.data?.data};
			}
			
		  } catch (error) {
			setLoading(false)
			toast.error("Error occured while downloading report")
			// return handleRequestError(error);
		  }
	}
 
	return { handleDownloadTransactionReport, loading ,  }
}