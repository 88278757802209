import * as yup from "yup";

const specialCharacter = /(?=.*[!@#$%^&*])/;
const regexNumber = /(?=.*[0-9])/;
const regexUppercase = /(?=.*[A-Z])/;
const regexLowercase = /(?=.*[a-z])/;

export const loginSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required("Password is mandatory").label("Password"),
  })
  .required();


export const TopUpSchema = yup
  .object({
    amount: yup.string().required(),
  })
  .required();

export const redirectUrlSchema = yup.lazy((values) =>
  yup.object({
    redirectUrl: yup.string().url().required().label("Redirect URL"),
    webhookUrl:
      values.enableKYC && yup.string().url().required().label("Webhook URL"),
	  senderID: values.activateSenderId && yup.string(),
  })
);

export const webhookUrlSchema = yup.object({
  webhookUrl: yup.string().url().required().label("Redirect URL"),

});

export const getOtpSchema = yup.lazy((values) =>
  yup.object({
    phoneNumber: yup.string().required("Phone Number"),
    firstName: values.enableKYC && yup.string().required("First Name"),
    lastName: values.enableKYC && yup.string().required("Last Name"),
  })
);

export const forgotPassWordSchema = yup.object({
  currentPassword: yup
    .string()
    .required("Password is mandatory")
    .min(8, "Password must be minimum of 8 characters"),
  newPassword: yup
    .string()
    .min(8, "Password must be minimum of 8 characters")
    .matches(regexLowercase, "one lowercase require")
    .matches(regexUppercase, "one uppercase required")
    .matches(regexNumber, "must contain a number")
    .matches(specialCharacter, "must contain one special character")
    .label("Password"),
  comfirmNewPassword: yup
    .string()
    .required("Password is mandatory")
    .min(8, "Password must be minimum of 8 characters")
    .matches(regexLowercase, "one lowercase require")
    .matches(regexUppercase, "one uppercase required")
    .matches(regexNumber, "must contain a number")
    .matches(specialCharacter, "must contain one special character")
    .oneOf([yup.ref("newPassword")], "Passwords does not match")
    .label("ReType Password"),
});
export const DownLoadCSVSchema = yup.object({
  from: yup
    .date()
    .required("Start date is mandatory"),
  to: yup
    .date()
    .required("Date end is mandatory"),
 
});
export const createSenderIdSchema = yup.object({
	senderId: yup
		.string()
		.required("senderId is mandatory"),
	gloIntentLetter: yup
		.string()
		.required("gloIntentLetter is mandatory"),
	mtnIntentLetter: yup
		.string()
		.required("mtnIntentLetter is mandatory"),
	"9mobileIntentLetter": yup
		.string()
		.required("9mobileIntentLetter is mandatory"),
	airtelIntentLetter: yup
		.string()
		.required("airtelIntentLetter is mandatory"),
});
export const acceoptSenderIdSchema = yup.object({
	senderId: yup
		.string(),
	gloIntentLetter: yup
		.string(),
	mtnIntentLetter: yup
		.string(),
	"9mobileIntentLetter": yup
		.string(),
	airtelIntentLetter: yup
		.string(),
});

export const verfiyOtpCodeSchema = yup.object({
  otp: yup.string().required().label("OTP"),
});

export const documentationSchema = yup.object({
	businessCert: yup.string().required().label("CAC: Certificate"),
	tinCert: yup.string().required().label("TIN: Certificate"),
	CAC7:  yup.string().required().label("CAC7: Certificate"),
	MEMART:  yup.string().required().label("MEMART: Certificate"),
	utilityBill: yup.string().required().label("utilityBill: Certificate"),
	COI: yup.string().required().label("COI: Certificate"),
	CAC2: yup.string().required().label("CAC2: Certificate")
});

export const signUpSchema = yup.object({
  email: yup.string().email().required("Email").label("Email"),
  phoneNumber: yup.string().required("Phone Number"),
  password: yup
    .string()
    .required("Password is mandatory")
    .min(8, "Password must be minimum of 8 characters")
    .matches(regexLowercase, "one lowercase require")
    .matches(regexUppercase, "one uppercase required")
    .matches(regexNumber, "must contain a number")
    .matches(specialCharacter, "must contain one special character")
    .label("Password"),
  confirmPwd: yup
    .string()
    .required("Password is mandatory")
    .min(8, "Password must be minimum of 8 characters")
    .matches(regexLowercase, "one lowercase require")
    .matches(regexUppercase, "one uppercase required")
    .matches(regexNumber, "must contain a number")
    .matches(specialCharacter, "must contain one special character")
    .oneOf([yup.ref("password")], "Passwords does not match")
    .label("ReType Password"),
});

export const onboardingSchema = yup.object({
  email: yup.string().email().required("Email").label("Email"),
  businessName: yup.string().required("Business Name"),
  businessMainColor: yup.string().required("Business Main Colour"),
  phoneNumber: yup.string().required("Phone Number"),
  businessSecondaryColor: yup.string().required("Business Secondary Colour"),
  businessLogo: yup.string().required("Business Logo").label("Business Logo"),
});

export const resetPasswordSchema = yup.object({
  otp: yup.string().required().label("OTP"),
  password: yup
    .string()
    .required("Password is mandatory")
    .min(8, "Password must be minimum of 8 characters")
    .matches(regexLowercase, "one lowercase require")
    .matches(regexUppercase, "one uppercase required")
    .matches(regexNumber, "must contain a number")
    .matches(specialCharacter, "must contain one special character")
    .label("Password"),
  confirmPwd: yup
    .string()
    .required("Confirm password is mandatory")
    .min(8, "Password must be minimum of 8 characters")
    .matches(regexLowercase, "one lowercase require")
    .matches(regexUppercase, "one uppercase required")
    .matches(regexNumber, "must contain a number")
    .matches(specialCharacter, "must contain one special character")
    .oneOf([yup.ref("password")], "Passwords does not match")
    .label("ReType Password"),
});

export const accountVerificationSchema = yup.object({
  otp: yup.string().required("required").label("OTP"),
  email: yup.string().required("required").label("Email"),
});
