

import {   leftarrow, rightarrow,   } from "assets/svg-code";
import { IntegrateCardDetailsType, StepType } from "utils/types";
import    "../../../App.css";


 

const KYC = (props: IntegrateCardDetailsType) => {
  const { title, description, goBack,   } = props;
 
	const cards = [
		{
			title: "SMS",
			description: "After initiating SMS, Your customers will be redirected to Bank Verification Number option and it will be activated.",
			type: "2-way-sms"
		},
		{
			title: "Telegram",
			description: "After initiating Telegram, Your customers will be redirected to Nigeria Identity Number option and it will be activated.",
			type: "2-way-telegram"
		},
		{
			title: "Whatsapp",
			description: "After initiating Whatsapp, Your customers will be redirected to Nigeria Identity Number option and it will be activated.",
			type: "2-way-Whatsapp"
		},
	]

	const comingSoon = (type: string) => type === "2-way-Whatsapp" 

	
	return (
		<div className="flex flex-col">
			<div className="flex items-start">
				<button className="appearance-none pt-2" onClick={() => goBack("inactive")}>
				{leftarrow}
				</button>
				<div className="flex flex-col ml-5 w-full">
					<div>
					<h1 className="text-2xl font-semibold font-sourceSans text-[#1A1B1C] mb-2">
						<span className="capitalize">{title}</span>
					</h1>
					<div className="text-[#404345] font-sourceSans text-base mr-24 mb-3">
						{description}
					</div>
					</div>
				</div>
			</div>


			{
				cards?.map(card => ( 
					<div 
						key={card.title} 
						className={`flex justify-between items-center rounded-2xl border p-5 ml-8 mb-7 
						${ comingSoon(card?.type )  ? "opacity-20 cursor-not-allowed" : "cursor-pointer"}`} 
						onClick={() => comingSoon(card?.type ) ? [] : goBack(card.type as StepType)}
					>

						<div>
							<h2 className=" font-medium text-[34px] general-sans-regular ">{card.title}</h2>
							<p  className="w-10/12  general-sans-light font-normal text-xl text-[#404345]">{card.description}</p>
						</div>
						<div  className="flex  items-center">{rightarrow}</div>
					</div>
				))
			}
		</div>
	);
};

export default KYC;
