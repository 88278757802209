

import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "antd";
import Input from "comps/Input";
import { useAcceptorDeclineSenderID,    } from "hooks/useSederId";
import React  from "react";
import { Controller, useForm } from "react-hook-form"; 
import * as yup from "yup";



 
type SenderIdType = { comment:  string, };
   
type PropType = {
	openModal:  {[e: string]: any, type: string},
	setModal: React.Dispatch<React.SetStateAction< {[e: string]: any, type: string}>>
	onDone: () => void
  };
   


const DeclineSenderIdModal: React.FC<PropType> = ({openModal, setModal, onDone}) => {
 

	const { handleAccceptOrDeclienSenderID, loading  } =  useAcceptorDeclineSenderID(openModal?._id, "declined")

 

	const { control, handleSubmit, formState: { errors }, } = useForm<SenderIdType>({
		defaultValues: { comment: "", },
		resolver: yupResolver( yup.object({
			comment: yup.string().required("senderId is mandatory"),
		})),
	});
 

	  const onSubmit = async (data: SenderIdType) => {
		const res = await handleAccceptOrDeclienSenderID(  {...data, status: "DECLINED"});
		if(res?.data) {
			onDone()
			setModal({type: ""})
		}
	  };



  return (
		<Modal
			open={openModal.type === "DECLINE_SENDERID"}
			title="Decline Sender ID"
			
			onCancel={() => setModal({type: ""})}
			cancelText={"Cancel"}
			cancelButtonProps={{style: {color: "red"}}}
			okText="Yes Continue"
			okButtonProps={{ htmlType: "submit" }}
			onOk={handleSubmit(onSubmit,  (e) => console.log(e))}
			confirmLoading={loading}
		>

			<span>Review this document and add a comment stating why is it declined.</span>
		 
		 <br />
		 <br />
			<form onSubmit={handleSubmit(onSubmit)} >
				<Controller
					control={control}
					name="comment"
					render={({ field }) => (
						<Input
							label="Comment"
							placeholder="Enter a comment"
							extraClass="mb-5"
							type="text"
							error={errors.comment?.message}
							{...field}
						/>
					)}
				/>
		 

			</form>
		</Modal>

  );
};

export default DeclineSenderIdModal;


 