// ts-ignore
import { leftarrow2 } from "assets/svg-code";
import AuthLayout from "comps/AuthLayout";
import { useGetKYC, useUpdateKYCStatus } from "hooks/useKYC";
import   { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";



 

 
const Customer = () => {
 

	const { userId} = useParams()
	const navigate = useNavigate()
	const [ready, setReady] = useState(false)


	
	const { handleGetKYC, KYC, loading } = useGetKYC(userId as string)
	const { handleUpdateKYCStatus, loading: updating} = useUpdateKYCStatus( )

	

 

	useEffect(() => {
		setReady(true)
		ready && handleGetKYC();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ready]);
 
	const data = [
		{ title: "business Cert", value: KYC?.businessCert, type: "img" },
		{ title: "tin Cert", value: KYC?.tinCert, type: "img" },
		{ title: "CAC7", value: KYC?.meta?.CAC7, type: "img" },
		{ title: "MEMART", value: KYC?.meta?.MEMART, type: "img" },
		{ title: "utility Bill", value: KYC?.meta?.utilityBill, type: "img" },
		{ title: "COI", value: KYC?.meta?.COI, type: "img" },
		{ title: "CAC2", value: KYC?.meta?.CAC2, type: "link" },
	]

	const status = {
		PENDING: "#1a29ac",
		APPROVED: "#2a760d",
		DECLINED: "#9d1515",
	}
	type StatusType = "PENDING" | "APPROVED" | "DECLINED"
	
return (
    <AuthLayout name="Customers KYC">
			<div className="mb-4">
				<button 
					onClick={() => navigate("/merchants")}
					className="flex text-gray-400 hover:text-gray-500 items-center" >
						{leftarrow2({color: "#9d9898", width: 15, height: 15})} <span className="pl-2">Go Back</span>
				</button>
			</div>

			

		{
			loading ?
			<LoaderIcon   />
			: 
			<>
				<div className="flex">

					<button 
						onClick={() => handleUpdateKYCStatus (userId as string, "APPROVED")} 
						className={`flex text-[#ededed] hover:text-[#a2fa90] px-9  mr-4 py-3 rounded items-center bg-[#095d1a] `}
						style={{backgroundColor: updating === "APPROVED" ? "#656565" : '#095d1a'}}
						disabled={updating === "APPROVED"}
					>
						<span className="pl-2">Approve</span>
					</button>

					<button 
						onClick={() => handleUpdateKYCStatus(userId as string,  "DECLINED")} 
						className="flex text-[#ededed] hover:text-[#fa9090] px-9  py-3 rounded items-center bg-[#5d0909] " 
						style={{backgroundColor: updating === "DECLINED" ? "#656565" : '#5d0909'}}
						disabled={updating === "DECLINED"}
					>
						<span className="pl-2">Decline</span>
					</button>
				</div>


				<div className=" mt-8">
					<span>STATUS:</span>
					<span  className={`ml-7  `} style={{color: status[KYC?.status as StatusType], fontWeight: "bold"}}>
						{KYC?.status || "PENDING"} 
					</span>
				</div>


 

				<div className="w-full my-5 grid items-center gap-x-10 gap-y-6 grid-cols-[repeat(auto-fit_,minmax(300px,_1fr))]" >
					{
						data?.map(_ => (
							<div className="flex items-center justify-between" key={_.title}>
								<span>{_.title?.toUpperCase()}</span>

								<div>
									{
										_.value ?
											_.type === "img" ?
												<div style={{width: "150px", height: "150px"}} className="bg-[#dadddf]">
													<img src={_.value}   alt=""  className="object-contain w-full h-full"/> 
												</div>
												:
												<a  style={{width: "150px", display: "bloack"}}  href={_.value} className="m-0 p-0 text-[#142597] w-9">
													Preview
												</a>
											: "N/A"
									}
								</div>
							</div>
						))
					}
				</div>
			</>
		}

    </AuthLayout>
  );
};

export default Customer;
