




import {   useParams } from "react-router-dom";
import Transaction from "../../comps/Transaction"






const CustomerTransaction = ( ) => {
	const { userId} = useParams()

	

	return (
		<div>

			<Transaction filterValue={userId} filterKey={"customerId"}/>
		</div>
	)
}

export default CustomerTransaction;