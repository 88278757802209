 

import { Chart, 
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	TimeScale,
	Title,
	Tooltip,
	Legend,
	Filler,
	LineController,
	BarController,
	BarElement,
	DoughnutController,
	ArcElement,
	PieController
} from "chart.js";
import React from "react";
import { useRef, useEffect } from "react";
import { ChartType } from "./types";



 
const Charts =  ({
	type,
	datasets,
	labels,
	gradient = false,
	dependency
}: {
	type: "bar" | "line" | "pie" | "doughnut",
	labels: string[],
	datasets: ChartType[],
	gradient?: boolean,
	dependency?: any
}) => {
	const ctx = useRef() as React.MutableRefObject<any>;
		
	
	Chart.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		TimeScale,
		Title,
		Tooltip,
		Legend,
		Filler,
		LineController,
		BarController,
		BarElement,
		DoughnutController,
		ArcElement,
		PieController
	);

	
	useEffect(() => {
		const Gradient = (color: string) => {
			const gradient = ctx.current.getContext("2d").createLinearGradient(0, 0, 0, 300);
			gradient.addColorStop(0,  color );   
			gradient.addColorStop(1, "#ffffff45");
			return gradient;
		};
  
		const myLineChart = new Chart(ctx.current, {
			type: type || "line",
			data: {
				labels: labels,
				datasets: datasets.map(dataset => {
					return ({
						...dataset,
						backgroundColor: typeof(dataset.backgroundColor) === "string" ?
							gradient ? 
								Gradient(dataset.backgroundColor)
								: dataset.backgroundColor
							: dataset.backgroundColor.map(((_color: string) => {
								return gradient ? 
									Gradient(_color)
									: 	_color;
							})),
						...(dataset.pointBackgroundColor && {
							pointBackgroundColor:  dataset.pointBackgroundColor
						}),
						...(dataset.borderColor && {
							borderColor: typeof(dataset.borderColor) === "string" ?
								dataset.borderColor
								: dataset?.borderColor?.map(((color: string) => {
									return color;
								}))
						}),
						...(dataset.pointBorderColor && {
							pointBorderColor:  dataset.pointBorderColor
						}),
					});
				})
			},
			options: {
				responsive: true,
				maintainAspectRatio: true,
				
				plugins: {
					legend: {
						display: false,
					}
				},
				scales: {
					x: {
						display: true ,
						beginAtZero: true,
						// labels: "ndjawq",
						// ticks: {
							
						// },
						stacked: true,
						grid: {
							display: false
						}
					},
					y: {
						display: type === "doughnut" ? false : true,
						beginAtZero: true,
						ticks: {

						},
						grid: {
							display: false,
						}
					}
				}
			}
		});
		return () => myLineChart.destroy();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, datasets?.length,labels?.length,dependency ] );

	return (
		<div className="relative  min-h-full  min-w-full">
			{
				![...datasets.map(dataset => (dataset.data))]?.flat()?.length ?
					<div className="flex absolute inset-0  p-3 w-fit h-fit m-auto bg-slate-100">
						<p className="text-center text-sm text-slate-600"> 
							No data available.<br />
							Chart will be calculated once activity is recorded.
						</p>
					</div>
					: null
			}
			<div className="canvas min-h-full  min-w-full">
				<canvas ref={ctx}></canvas>
			</div>
		</div>
	);
};


export default Charts;