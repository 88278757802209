import React from "react";

interface InputProps {
  label?: string;
  name?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string ;
  value?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  extraClass?: string;
  style?: {};
  disabled?: boolean;
  maxLength?: number;
  max?: string;
  min?: string;
  ref?: any;
}
const Input = (props: InputProps) => {
  const {
    label,
    name,
    onChange,
    onBlur,
    type,
    value,
    error,
    className,
    extraClass,
    placeholder = "Input Details",
    style,
    disabled,
    maxLength,
  } = props;
  return (
    <div className={`relative ${extraClass}`}>
      {label && (
        <label
          htmlFor={name}
          className=" bg-transparent capitalize font-display text-xs"
        >
          {label}
        </label>
      )}
      <input
        onChange={onChange}
        name={name}
        type={type}
        value={value}
        onBlur={onBlur}
        className={`input-tag disabled:cursor-not-allowed ${className}`}
        placeholder={placeholder}
        style={style}
        disabled={disabled}
        maxLength={maxLength}
      />
      {error && (
        <div className="text-red-500 text-xs font-display">{error}</div>
      )}
    </div>
  );
};

export default Input;
