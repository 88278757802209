import axios from "axios";
import safepro, { safeproMiddleWare,   } from "axiosApi";
import  { useUserAuthContext } from "context/UserAuthContext";
import { useGetAdmin } from "hooks/useAuth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
 

function handleRequestError(error:any) {
  if (error.toString() === "Error: Network Error") {
    return toast.error("Try again: Internet connectivity", {
      duration: 3000,
    });
  } else {
    return error.response;
  }
}

interface registerAccountType {
  email: string;
  phoneNumber: string;
  password: string;
  country: string;
}
export const registerAccount = async ({
  email,
  phoneNumber,
  password,
  country,
}:registerAccountType) => {
  try {
    const res = await safepro.post(
      `/users/v1/users`,
      {
        phoneNumber,
        email,
        password,
        countryCode: country,
        name: "safepro",
        options: {
          verificationType: "email",
          type: "welcome",
          sendCode: true,
        },
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const createBusiness = async ({businessId, businessName}:{businessId: string; businessName:string}) => {
  try {
    const res = await safeproMiddleWare.post("/business", {
      userId: businessId,
      businessName
    }, {
      headers: {
        "client-id": process.env.REACT_APP_CLIENT_ID,
      },
    })
    return res
  } catch (error) {
    return handleRequestError(error);
  }
}

export const fetchBusinessDetails = async (businessId:string) => {
  try {
    const res = await safeproMiddleWare.get(`/business?businessId=${businessId}`, {
      headers: {
        "client-id": process.env.REACT_APP_CLIENT_ID,
      },
    })
    return res
  } catch (error) {
    return handleRequestError(error);
  }
}

export const useGetCustoerDetails =   () => {
	const [loading, setLoading] = useState(false)
	const [details, setDetails] = useState({})

	const handlegetCustomerDetails =  async (businessId:string) => {
		try {
			const res = await safeproMiddleWare.get(`/business?businessId=${businessId}`, {
			  headers: {
				"client-id": process.env.REACT_APP_CLIENT_ID,
			  },
			})
			setLoading(false)
			if(res?.data) {
				setDetails(res?.data)
				return res
			}
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
	return  { handlegetCustomerDetails, loading, details}
}


 

export const useGetUserWallet =   () => {
	const [wallets, setWallet] = useState([]);
	const [loading, setLoading] = useState(false);
	const { userDetails: { user: { userId }, }} = useUserAuthContext();

	const [pageInfo, setPageInfo]: any = useState({
        number: 0,
        limit: 10
    });

	const handlegetWallet = async () => {
		setLoading(true)
		try {
		  const res = await safepro.get(`billings/wallet-transactions?size=${pageInfo?.limit}&page=${pageInfo?.number}&customerId=${userId || ''}`, 
		  {
			headers: { "client-id": process.env.REACT_APP_CLIENT_ID,},
		  })

		  setLoading(false)
		  if(res) {
			setWallet(res?.data?._embedded?.walletTransactions);
			setPageInfo((prev: any) => ({...prev, ...res?.data?.page}))
		  }
		  return res
		} catch (error) {
			setLoading(false)
		  return handleRequestError(error);
		}
	}
	useEffect(() => {
		handlegetWallet();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { handlegetWallet , loading, wallets, pageInfo, setPageInfo}
}
 



export const useGetDasboardKPI =   () => {
	const [KPI, setKPI] = useState<{[e: string]: any}>({});
	const [loading, setLoading] = useState(false);
	const { userDetails: { user: { userId }, }} = useUserAuthContext();
	const [ready, setReady] = useState(false)
	const { isAdmin } = useGetAdmin()


	const handleGetDashboardKPI = async () => {
		setLoading(true)
		try {
			const res = await axios.get(
				isAdmin ?
				`${process.env.REACT_APP_BASE_URL}/otp-middleware/api/v1/admin/dashboard`
				: `${process.env.REACT_APP_BASE_URL}/otp-middleware/api/v1/dashboard/report?businessId=${userId}`, 
			{
				headers: { "client-id": process.env.REACT_APP_CLIENT_ID,},
			})

			setLoading(false)
			if(res) {
				setKPI(res?.data?.data);
			}

			return {data: res?.data}
		} catch (error) {
			setLoading(false)
			return handleRequestError(error);
		}
	}
	useEffect(() => {
		setReady(true)
		ready &&	handleGetDashboardKPI();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready]);
	return { handleGetDashboardKPI , loading, KPI,  }
}

export const useGetWalletKPI =   () => {
	const [KPI, setKPI] = useState<{[e: string]: any}>({});
	const [loading, setLoading] = useState(false);
	const { userDetails: { user: { userId }, }} = useUserAuthContext();

	const handleGetWalletKPI = async () => {
		setLoading(true)
		try {
			const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/otp-middleware/api/v1/billing/report?businessId=${userId}`, 
			{
				headers: { "client-id": process.env.REACT_APP_CLIENT_ID,},
			})

			setLoading(false)
			if(res) {
				setKPI(res?.data?.data);
			}

			return {data: res?.data}
		} catch (error) {
			setLoading(false)
			return handleRequestError(error);
		}
	}
	useEffect(() => {
		handleGetWalletKPI();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { handleGetWalletKPI , loading, KPI,  }
}

 

 
export const useTopUpWallet =   () => {
	const [loading, setLoading] = useState(false);
	const { userDetails: { user  }} = useUserAuthContext();

	const handleTopUptWallet = async (payload: object) => {
		setLoading(true)
		try {
		  const res = await safepro.post(`payments/v1/smartpaycard/initialize`, 
			{
				"provider": "smartpaycard",
				"clientId": user?.clientId,
				currency: process.env.REACT_APP_CURRENCY,
				"userId": user?.userId,
				"phoneNumber": user?.phoneNumber,
				paymentTypeId: 2,
				"email": user?.email,
				"redirectUrl" :  `${process.env.REACT_APP_HOSTED_URL}/wallet`,
				...payload
			} ,
			{
				headers: { "client-id": process.env.REACT_APP_CLIENT_ID,},
			})
		  setLoading(false)
		  if(res?.data?.data){
			window.open(res?.data?.data?.data, "_black")
			return {data: res?.data }
		  }
		  return res
		} catch (error) {
			setLoading(false)
		  return handleRequestError(error);
		}
	}
 
	return { handleTopUptWallet , loading, }
}


 
export const useCheckKYC =   () => {
	const [loading, setLoading] = useState(true);
	const { userDetails, setKYC, KYC } = useUserAuthContext();
	const [ready, setReady] = useState(false);

	
	const handleCheckKYCStatus = async () => {
		setLoading(true)
		try {
			const res = await safepro.get(
				`otp-middleware/api/v1/kyc/${userDetails?.user?.userId}`, 
				{ headers: { "client-id": process.env.REACT_APP_CLIENT_ID,}}
			)
			setLoading(false)
			if(res?.data?.data){
				setKYC(res?.data?.data)
				return {data: res?.data }
			}
			return res
		} catch (error) {
			setLoading(false)
		  return handleRequestError(error);
		}
	}

	useEffect(() => {
		setReady(true)
		ready &&  handleCheckKYCStatus()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready])
 
	return { handleCheckKYCStatus , loading, KYC}
}




export const useVerifyEmail =   () => {
	const [loading, setLoading] = useState(false)
 
	 const handleVerifyEmail = async (DATA: object) => {
		 setLoading(true)
		 try {
			 const res = await safepro.post(
				`/users/v1/auths/verify`,
				 DATA,
				 {
					 headers: {
						 "Content-Type": "application/json",
						 "client-id": process.env.REACT_APP_CLIENT_ID,
					 },
				 }
			 );
			 setLoading(false)
			 if(res?.data)return { result: res?.data};
		 } catch (error) {
			 setLoading(false)
			 toast.error("Error " );
		 }
	 };
	 return {  loading, handleVerifyEmail }
 };


export const useUpdateKYC =   () => {
	const [loading, setLoading] = useState(false)
	const { userDetails: { user  }} = useUserAuthContext();
	
	 const handlUpdateKYC = async (DATA: object) => {
		 setLoading(true)
		 try {
			 const res = await safepro.post(
				`/otp-middleware/api/v1/kyc`,
				 {userId: user?.userId, ...DATA},
				 {
					 headers: {
						 "Content-Type": "application/json",
						 "client-id": process.env.REACT_APP_CLIENT_ID,
					 },
				 }
			 );
			 setLoading(false)
			 if(res?.data)return { result: res?.data};
		 } catch (error) {
			 setLoading(false)
			 toast.error("Error updateing KYC" );
		 }
	 };
	 return {  loading, handlUpdateKYC }
 };



export const useGetIntegrateAmounts =   () => {
	const [loading, setLoading] = useState(false)
	const [amount, setAmount] = useState<{providerName: string, amount: number}[]>([])
	const [ready, setReady] = useState(false)
	const { userDetails: { user  }} = useUserAuthContext();

	 const handleGetAmount = async () => {
		 setLoading(true)
		 try {
			 const res = await safepro.get(
				`/otp-middleware/api/v3/user-provider-cost/${user?.userId}`,
				 {
					 headers: {
						 "Content-Type": "application/json",
						 "client-id": process.env.REACT_APP_CLIENT_ID,
					 },
				 }
			 );
			 setLoading(false)
			 if(res?.data)  { 
				setAmount(res?.data?.data);
				return { result: res?.data }
			 };
		 } catch (error) {
			 setLoading(false)
			 toast.error("Error occured" );
		 }
	 };

	 useEffect(() => {
		setReady(true)
		ready && handleGetAmount()
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	 }, [ready]);
	 return {  loading, handleGetAmount , amount}
 };



export const fetchDashboardStats = async (businessId:string) => {
  try {
    const res = await safeproMiddleWare.get(`/dashboard/report?businessId=${businessId}`, {
      headers: {
        "client-id": process.env.REACT_APP_CLIENT_ID,
      },
    })
    return res
  } catch (error) {
    return handleRequestError(error);
  }
}



interface loginAccountType {
  email: string;
  password: string;
}
export const login = async ({ email, password }: loginAccountType) => {
	try {
		const res = await safepro.post(
			`/users/v1/auths/login`,
			{ email: email, password: password, },
			{ headers: { "client-id": process.env.REACT_APP_CLIENT_ID, }, }
		);
		return res;
	} catch (error) {
		console.log(error, "kk")
		return handleRequestError(error);
	}
};


interface forgotPasswordType {
  type: string;
  value: string;
  redirectURL?: any
}
export const forgotPassword = async ({
  type = "email",
  value,
  redirectURL = "https://www.google.com",
}:forgotPasswordType) => {
	try {
	const res = await safepro.get( `/users/v1/passwords/reset?type=${type}&value=${value}`,
		{ headers: { "client-id": process.env.REACT_APP_CLIENT_ID, }, }
	);
	return res;
	} catch (error: any) {
		toast.error(error?.response?.data?.error || "Try again: Internet connectivity", { duration: 3000,});
	}
};

interface resetPasswordType {
  email: string;
  code: string;
  password?: any
}
export const resetPassword = async ({ email, code, password }:resetPasswordType, token:string) => {
  try {
    const res = await safepro.post(
      `/users/v1/passwords/reset`,
      {
        type: "email",
        email: email,
        code: code,
        password: password,
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res;
  } catch (error) {
    handleRequestError(error);
  }
};


 

type onboardUserType = {
  userId: string;
  token: string;
  businessName: string;
  businessLogo: string;
  email: string;
  businessMainColor: string;
  phoneNumber: string;
  businessSecondaryColor: string;
}
export const onboardUser = async (data:onboardUserType) => {
  const {
    userId,
    token,
    businessName,
    businessLogo,
    email,
    businessMainColor,
    phoneNumber,
    businessSecondaryColor,
  } = data;

  
  try {
    const res = await safepro.put(
      `/users/v1/users/${userId}`,
      {
        businessName,
        businessLogo,
        email,
        phoneNumber,
		businessMainColor,
		businessSecondaryColor
      },
    {
      headers: {
        "client-id": process.env.REACT_APP_CLIENT_ID,
        Authorization: `Bearer ${token}`,
      }
    }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};


export const verfiyOtp = async (data:{type?: string; value: string; withToken?: string; code: string | number, email?: string, sendWelcomeEmail?: boolean}) => {
  const  { value, code , sendWelcomeEmail } = data
  try {
    const res = await safepro.post(
      `/users/v1/auths/verify/code`,
      {
        type: "email",
        value: value,
        withToken: "true",
        code: code,
		...(sendWelcomeEmail && {sendWelcomeEmail})
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
}

export const changeMyPassword = async (data: any) => {
	const { email, code, password,   } = data
	try {
		const res = await safepro.post(
			`/users/v1/passwords/reset`,
			{
				type: "email",
				email: email,
				code: code,
				password: password,
			},
			{
				headers: {
					"client-id": process.env.REACT_APP_CLIENT_ID,
				},
			}
		);
		return res;
	} catch (error) {
		return handleRequestError(error);
	}
};


export const useUpdateUser = () => {	
	const { userDetails: { user ,   token, }} = useUserAuthContext();
	const [loading, setLoading] = useState(false)
	const handleUpdateUser = async (data: any) => {
		setLoading(true)
		try {
			const res = await safepro.put(
				`/users/v1/users/${user?.userId}`,
				{...data},
				{
					headers: {
						"client-id": process.env.REACT_APP_CLIENT_ID,
						Authorization: `Bearer ${token}`,
					}
				}
			);
			setLoading(false)
			if(res?.data) toast.success(`${ "Update successfully"}`)
			return res;
		} catch (error) {
			return console.log(error);
		}
	}
	return { handleUpdateUser, loading }
};



export const verifyAccount = async (email:string) => {
  try {
    const res = await safepro.post(
      "/users/v1/auths/verify",
      { type: "email", value: email },
      {
        headers: {
          "Content-Type": "application/json",
          "client-id": process.env.REACT_APP_CLIENT_ID,
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const fetchCustomers = async () => {
  try {
    const response = await safepro.get(
      `/users/v1/users`,
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          // "Authorization": `Bearer ${token}`
        },
      }
    );
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const fetchCustomersMiddleware = async (userId: string, queryString?: string) => {
  try {
    const response = await safeproMiddleWare.get(
    //   `/otp/customers?${(queryString && queryString?.length > 0) ? `&${queryString}` : ""}`,
      `/otp/customers?businessId=${userId}${(queryString && queryString?.length > 0) ? `&${queryString}` : ""}`,
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          // "Authorization": `Bearer ${token}`
        },
      }
    );
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};



 

export const updateNotifyUrl = async (userId:string, url:string, token:string) => {
  try {
    const response = await safepro.put(
      `/users/v1/users/${userId}`,
      {
        notifyUrl: url,
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const updateRedirectUrl = async (
	{userId, url,  token, webhookUrl, enableKYC = false, phoneNumber, firstName,lastName, senderID}: {
		userId:string, url:string,  token:string, webhookUrl?: string, enableKYC: boolean, phoneNumber?: number, firstName?: string,lastName?:string, senderID?: string
	}
) => {
  try {
    if(webhookUrl){
      const response = await safepro.put(
        `/users/v1/users/${userId}`,
        {
          redirectUrl: url,
          enableKYC: enableKYC,
          webhookUrl: webhookUrl,
		  phoneNumber,
		  firstName,
		  lastName,
		  senderID
        },
        {
          headers: {
            "client-id": process.env.REACT_APP_CLIENT_ID,
            Authorization: `Bearer ${token}`,
          },
        }
        );
        return response;
    }else{
      const response = await safepro.put(
        `/users/v1/users/${userId}`,
        {
          redirectUrl: url,
          enableKYC: enableKYC,
        },
        {
          headers: {
            "client-id": process.env.REACT_APP_CLIENT_ID,
            Authorization: `Bearer ${token}`,
          },
        }
        );
        return response;
      }
  } catch (error) {
    return handleRequestError(error);
  }
};

export const updateWebookUrl = async (userId:string, url:string, token:string) => {
  try {
    const response = await safepro.put(
      `/users/v1/users/${userId}`,
      {
        webhookUrl: url,
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};



export const middlewareSendCode = async (body:{reference?:string, businessId?:string, phoneNumber:string | number},  ) => {
  try {
    const res = await axios.post(
    //   `${process.env.REACT_APP_BASE_URL}/otp-middleware/api/v3/otp-direct/otp-direct-ng/initiate`,
      `${process.env.REACT_APP_OTP_MIDDLEWARE}/otp-middleware/api/v1/otp/initiate/code`,
      body,
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
		//   Authorization: `Bearer ${token}`,
		//   "x-api-key": publicKey
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const getUser = async (userId:string) => {
  try {
    const res = axios.get(
    //   `${process.env.REACT_APP_BASE_URL}/users/v1/auths/me`,
      `${process.env.REACT_APP_BASE_URL}/users/v1/users/${userId}`,
      {
        headers: {
        //   "client-secret": process.env.REACT_APP_STAGING_SECRET,
          "client-id": process.env.REACT_APP_CLIENT_ID,
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};
 
export const useGetUser =   () => {
	const {user, setUser, userDetails } = useUserAuthContext();
	const [loading, setLoading] = useState(false)
	const [ ready, setReady] = useState(false)

	const hanldleGetUser = async () => {
		setLoading(true)
		try {
			const res =  await axios.get(
				`${process.env.REACT_APP_BASE_URL}/users/v1/auths/me`,
				{
				headers: {
					// "client-secret": process.env.REACT_APP_STAGING_SECRET,
					"client-id": process.env.REACT_APP_CLIENT_ID,
					Authorization: `Bearer ${userDetails?.token}`,
				},
			  }
			);
			setLoading(false)
			if(res?.data) {
				setUser(res?.data?.data)
				return res;
			}

		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
	useEffect(() => {
		setReady(true)
		ready && hanldleGetUser()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready, ]);

	return { hanldleGetUser, loading, user}
};
 




export const middlewareVerify = async (body:any) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_OTP_MIDDLEWARE}/otp-middleware/api/v1/otp/verify`,
      body,
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          // "Authorization": `Bearer ${token}`
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const updateIntegrationType = async (userId:string, type:string, token:string) => {
  try {
    const response = await safepro.put(
      `/users/v1/users/${userId}`,
      {
        integrationType: type,
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error:any) {
    return error?.response
  }
};


 