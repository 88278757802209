import { yupResolver } from "@hookform/resolvers/yup";
import Button from "comps/Button";
import Input from "comps/Input";
import { useUserAuthContext } from "context/UserAuthContext";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { resetPassword } from "services";
import { logOut } from "utils/utilis";
import { forgotPassWordSchema } from "validations";

type Password = {
  currentPassword: string;
  newPassword: string;
  comfirmNewPassword: string;
};
type Props = {};

const ChangePassword: React.FC<Props> = (props) => {
  const {
    userDetails: {
      token,

	},
  } = useUserAuthContext();
  const {
    control,
    handleSubmit,
    formState: { errors }, reset
  } = useForm<Password>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      comfirmNewPassword: "",
    },
    resolver: yupResolver(forgotPassWordSchema),
  });
  const onSubmit = async (data: Password) => {
    const res = await resetPassword(token, data.newPassword);
    if (res.status >= 200 && res.status <= 299) {
      toast.success(
        `Success: ${res?.data?.data || "Password reset successfully"}`
      );
      toast.success("Login with new account details", { duration: 5000 });
      logOut();
	  reset()
    } else {
      toast.error(
        `Error: ${
          res?.data?.error || res.data.message || "Unable to reset password!"
        }`
      );
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" w-full md:w-6/12 mt-10 drop-shadow-sm mx-auto border border-[#D5D7D8] rounded-lg p-4 md:p-16 min-h-[440px]"
      >
        <Controller
          control={control}
          name="currentPassword"
          render={({ field }) => (
            <Input
              label="Current Password"
              placeholder="Enter your current password"
              extraClass="mb-5"
              type="password"
              error={errors.currentPassword?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <Input
              label="New Password"
              placeholder="Enter your new password"
              extraClass="mb-5"
              type="password"
              error={errors.newPassword?.message}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="comfirmNewPassword"
          render={({ field }) => (
            <Input
              label="Confirm New Password"
              placeholder="Enter to confirm new password"
              extraClass="mb-5"
              type="password"
              error={errors.comfirmNewPassword?.message}
              {...field}
            />
          )}
        />

        <Button
          btnText="Save Details"
          variant="primary"
          type="submit"
          // disabled={imageData?.loading || loading}
          className="w-40 mx-auto flex justify-center mt-16 drop-shadow-md"
        >
          {/* {loading ? "..." : ""} */}
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
