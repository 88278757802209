
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "comps/Input";
import { useParams, useNavigate,   } from "react-router-dom";
import toast from "react-hot-toast";
import uuid from "react-uuid";
import { middlewareSendCode, getUser } from "services";
import { yupResolver } from "@hookform/resolvers/yup";
import { getOtpSchema } from "validations";
import    "../../App.css";



	type ClientDataTypes = {
		profile?: {
			redirectUrl?: string;
			name?: string;
			businessLogo?: string;
			businessName?: string;
			businessMainColor?: string;
			businessSecondaryColor?: string;
			publicKey: string
		};
		phoneNumber?: string | number;
	};


const GetCode = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const phoneNumber = queryParams.get("phoneNumber") || "";
	const { safeproClientId ,} = useParams();

	const navigate = useNavigate();
	const [clientData, setClientData] = useState<ClientDataTypes>({});

	const params =  new URLSearchParams(window.location.search);
	const  isWatchEnableKYC = params.get('watchEnableKYC') === "true"
	

	const [formLoading, setFormLoading] = useState(false);
	const {  control, handleSubmit, setValue,   formState: { errors },} = useForm<{
		 phoneNumber: string; firstName: string; lastName: string; enableKYC: boolean;
	}>({
		defaultValues: {
			phoneNumber: "",
			firstName: "",
			lastName: "",
			enableKYC: false,
		},
		resolver: yupResolver(getOtpSchema),
	});


	
	const sendCodeIfPhoneNumberExists = async () => {
		setFormLoading(true);
		const reference = uuid();
		const res = await middlewareSendCode({
			reference: reference,
			businessId: safeproClientId,
			phoneNumber: phoneNumber,
		});
		setFormLoading(false);
		toast.success(`OTP sent to ${phoneNumber}`);
		if (res.status >= 200 && res.status <= 299) {
			return navigate(`/${safeproClientId}/redirect-otp`, {
				state: {
					phone: phoneNumber || clientData?.phoneNumber,
					redirectUrl: clientData?.profile?.redirectUrl,
					name: clientData?.profile?.name,
					reference: reference,
				},
			});
		}
	};

	useEffect(() => {
		const fetch = async () => {
			const res = await getUser(safeproClientId!);
			setClientData(res.data.data);
			setValue("enableKYC", res.data.data.profile.enableKYC);
		};
		if (!phoneNumber) {
			fetch();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (phoneNumber) {
			sendCodeIfPhoneNumberExists();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  const sendCodeIfFormIsSubmitted = async (data: any) => {
    setFormLoading(true);
    const reference = uuid();
    const name = data?.phoneNumber;
    const lastFour = name.substring(name.length - 10);
		const res = await middlewareSendCode({
			reference: reference,
			businessId: safeproClientId,
			phoneNumber: `234${lastFour}`,
			...(isWatchEnableKYC && {firstName: data.firstName}),
			...(isWatchEnableKYC && {lastName: data.lastName})
		} );
		setFormLoading(false);
		if(res?.data?.error) {
			toast(`  ${res?.data?.error?.error?.responseMessage}`);
		}
		if (res.status >= 200 && res.status <= 299) {
			toast(`OTP sent to ${data?.phoneNumber}`);
			return navigate(`/${safeproClientId}/redirect-otp`, {
				state: {
					phone: data?.phoneNumber,
					redirectUrl: clientData?.profile?.redirectUrl,
					name: clientData?.profile?.name,
					reference: reference,
				},
			});
		}
	};


	

  return (
    <div className="bg-white w-full min-h-screen">
		<div className="w-11/12 h-36"></div>
		<form
			onSubmit={handleSubmit(sendCodeIfFormIsSubmitted)}
			className="bg-white mb-40 flex flex-col py-4 justify-between items-center get-code-body rounded-lg shadow-lg border border-[#E6E7E8] w-11/12 md:w-5/12 p-4 mx-auto min-h-[317px]"
		>
			<div className="flex flex-col">
				<div className="flex justify-center items-center gap-x-2 mb-4 flex-col">
					<div className="w-20 h-20">
						{clientData?.profile?.businessLogo && (
							<img
								className="w-full h-full object-cover"
								src={clientData?.profile?.businessLogo}
								alt="company a logo"
							/>
						)}
					</div>
					<div className="mt-8">
						<span className="text-xl general-sans-regular">Welcome</span>
					</div>
				</div>
				<p className="text-center text-base general-sans-light">
					Enter the phone number to receive the OTP Code{" "}
				</p>
			</div>

			<Controller
				name="phoneNumber"
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<Input
						label="Phone Number"
						type="number"
						name="phoneNumber"
						id="phoneNumber"
						placeholder="Enter phone number"
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						className="w-full"
						extraClass="w-11/12 md:w-8/12 mb-2"
						disabled={formLoading}
						style={{borderColor:  clientData?.profile?.businessMainColor}}
						error={errors.phoneNumber?.message}
					/>
				)}
			/>

		

			{isWatchEnableKYC && (
				<>
					<Controller
						name="firstName"
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<Input
								label="First Name"
								type="text"
								name="firstName"
								id="firstName"
								placeholder="Enter first name"
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								className="w-full"
								extraClass="w-11/12 md:w-8/12 mb-2"
								disabled={formLoading}
								error={errors.firstName?.message}
								style={{borderColor:  clientData?.profile?.businessMainColor}}
							/>
						)}
					/>

					<Controller
						name="lastName"
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<Input
								label="Last Name"
								type="text"
								name="lastName"
								id="lastName"
								placeholder="Enter last name"
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								className="w-full"
								extraClass="w-11/12 md:w-8/12"
								disabled={formLoading}
								error={errors.lastName?.message}
								style={{borderColor:  clientData?.profile?.businessMainColor}}
							/>
						)}
					/>
				</>
			)}

			<button
				disabled={formLoading}
				type="submit"
				className={`font-bold w-[120px] p-4 rounded mt-9 `}
				style={{backgroundColor: clientData?.profile?.businessMainColor, color: clientData?.profile?.businessSecondaryColor}}
			> 
				{formLoading ? "Submit ...." : "Submit"}
			</button>

			<span className="font-bold text-[#1A1B1C] general-sans-light md:text-[20px] text-center  text-xs  mt-8" style={{fontSize: "10px", lineHeight: "14px"}}>
				Powered by SAFEPro @ 2022
			</span>

      </form>
    
    </div>
  );
};

export default GetCode;
