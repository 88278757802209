// ts-ignore



import { CURRENCY_STRING } from "../../constants";

import { LoaderIcon } from "react-hot-toast";
import { useGetIntegrateAmounts } from "services/auths";



const ItegrationType = [
	{
	  title: "OTP Redirect",
	  type: "redirect-ng",
	},
	{
	  title: "OTP Direct",
	  type: "otp-direct-ng",
	},
	{
	  title: "2-Way Messaging",
	  type: "2-way-sms-ng",
	},
	{
	  title: "KYC Verification",
	  type: "kyc-nin",
	},
	{
	  title: "DeviceAuth",
	  type: "device-auth",
	},
]



 
const Pricing = () => {
 
 
	const { amount, loading } = useGetIntegrateAmounts();
 

 
 

	const data = ItegrationType?.map((item: any ) => {
		return {
			title: item?.title , 
			value: CURRENCY_STRING +  amount?.find((_item: any) => _item?.providerName === item?.type)?.amount 
		}
	})
	
return (
    <div>

		

		{
			loading ? 
				<div className="flex w-full justify-center mt-6"><LoaderIcon /></div>
				:
				<div>
					{
						data?.map((item, idx) => (
							<div key={idx} className="flex justify-between max-w-xl mt-6">
								<b>{item.title}</b>
								<span>{item.value || "N/A"}</span>
							</div>
						))
					}
				</div>
		}

	 

    </div>
  );
};

export default Pricing;
