

import { Modal } from "antd";
import React  from "react";



 
 
type PropType = {
	openModal:  {iconUrl?:  string, type: string},
	setModal: React.Dispatch<React.SetStateAction< {[e: string]: any, type: string}>>
  };
   


const ZoomSenderIdModal: React.FC<PropType> = ({openModal, setModal,   }) => {
 

 
 


  return (
		<div className="zoom-modal-container">
			<Modal
				open={openModal.type === "ZOOM_SENDERID"}
				onCancel={() => setModal({type: ""})}
				cancelText={"Close"}
				cancelButtonProps={{style: {color: "red"}}}
				okText=""
				okButtonProps={{ htmlType: "submit" }}
				onOk={(  (e) => console.log(e))}
			>
				<img src={openModal?.iconUrl} alt="airtel logo" className="object-cover w-full h-full"/>
			</Modal>
		</div>

  );
};

export default ZoomSenderIdModal;


 