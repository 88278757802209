import {  safeproMiddleWare, safeproMiddleWare2 } from "axiosApi";
import { useUserAuthContext } from "context/UserAuthContext";
import { useState } from "react";
import toast from "react-hot-toast";
import { handleRequestError } from "utils";







export const useGetMerchants =   () => {
	const [loading, setLoading] = useState(false)
	const [customers, setCustomers] = useState({docs: [], total: 0})
	const { userDetails: { token, },} = useUserAuthContext();

	

	const handleGetCustomers = async (  queryString?: string) => {
		setLoading(true)
		try {
			
			const response =  await safeproMiddleWare2.get( 
				`/business${(queryString && queryString?.length > 0) ? `&${queryString}` : ""}`,
				{
					headers: { "client-id": process.env.REACT_APP_CLIENT_ID, "Authorization": `Bearer ${token}` },
				}
			)
			 
			setLoading(false)
			if(response) {
				setCustomers(response?.data?.data)
			}
			return response;
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
	 return { handleGetCustomers, loading, customers }
};




export const useGetMerchant =   (userId: string) => {
	const [loading, setLoading] = useState(false)
	const [merchant, setMerchant] = useState<any>()
	const { userDetails: { token, },} = useUserAuthContext();

	

	const handleGetMerchant = async ( ) => {
		setLoading(true)
		try {
			
			const response =  await safeproMiddleWare2.get( 
				`/business?userId=${userId}`,
				{
					headers: { "client-id": process.env.REACT_APP_CLIENT_ID, "Authorization": `Bearer ${token}` },
				}
			)
			 
			setLoading(false)
			if(response) {
				setMerchant(response?.data?.data?.docs?.[0])
			}
			return response;
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
	 return { handleGetMerchant, loading, merchant }
};






export const useDownLoadMerchantReport = ( ) => {
	const [loading, setLoading] = useState(false);
	const { userDetails: {   token, }} = useUserAuthContext();


	const handleDownloadMerchantReport = async ({from, to}: {from: string, to: string} ) => {
		setLoading(true)
		try {
			const response = await safeproMiddleWare.get(
				 `/business/export?from=${from}&to=${to}`,
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  "Authorization": `Bearer ${token}`
				},
			  }
			);
			setLoading(false)
			if(response.data){
				const url = window.URL.createObjectURL(new Blob([response?.data?.data]));
				const link = document.createElement("a");
				link.href = url;
				const fileName = `downloaded Report ${new Date().toLocaleString().split(", ").join("-")}.csv`;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
				toast.success("BuDownload successful");
				return {data:  response?.data?.data } ;
			}
			
		  } catch (error : any) {
			setLoading(false)
			toast.error( error?.response?.data?.error?.message || "Error occured!");
		  }
	}
 
	return { handleDownloadMerchantReport, loading ,  }
}