// ts-ignore


import { useGetMerchant,   } from "hooks/useMerchants";

import   { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import {  useParams } from "react-router-dom";





 
const Merchant = () => {
 
	const { userId} = useParams()
	const { handleGetMerchant, merchant, loading } = useGetMerchant(userId as string)
	const [ready, setReady] = useState(false)

	
 
 

	useEffect(() => {
		setReady(true)
		ready && handleGetMerchant();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ready]);
 

	const data = [
		{ title: "Bussiness Name", value: merchant?.businessName },
		{ title: "Public Key  ", value: merchant?.publicKey },
		{ title: "Secret Key  ", value: merchant?.secret },
		{ title: "User Id    ", value: merchant?.userId },
		{ title: "Created At", value:new Date( merchant?.createdAt).toLocaleString() },
	]
	
	
return (
    <div>
		{
			loading ? 
				<div className="flex w-full justify-center mt-6"><LoaderIcon /></div>
				:
				<div>
					{
						data?.map((item, idx) => (
							<div key={idx} className="flex justify-between max-w-xl mt-6">
								<b>{item.title}</b>
								<span>{item.value || "N/A"}</span>
							</div>
						))
					}
				</div>
		}

	 

    </div>
  );
};

export default Merchant;
