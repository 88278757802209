
 
import { useUserAuthContext } from "context/UserAuthContext";
import JWT_DECODE from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const timeDiffInSecs = (time1: string | number | Date, time2: string| number | Date) => {
	const t1 = new Date(time1);
	const t2 = new Date(time2);
	return t1.getTime() - t2.getTime();
};




export const useValidateToken = ( ) => {
	const navigate = useNavigate();
	const { userDetails: { token, },} = useUserAuthContext();



	const validateToken = () => {
		if(!token) return;

		try{
			const decoded = JWT_DECODE(token) as any;
			const secs = timeDiffInSecs(+decoded?.exp * 1000, new Date());//the time the token takes before it expires
		
		
			if(secs > 0) {
				if(secs < 5000) {
					// handle get new token here. Refreash token api
				} else {
					return;
				}
			} else {
				navigate(`/login`);

				return false;
			}
		} catch (err: any) {
			if( err?.message === "Invalid token specified: undefined") {
				navigate(`/login`);
				return false;
			}
		}
	};

	const tokenIsValid = (_token: string | null) => {
		if(!_token) return false;
		const decoded = JWT_DECODE(_token) as any;
		const secs = timeDiffInSecs(+decoded?.exp * 1000, new Date());
		return secs > 0;
	};
	
	useEffect(() => {
		validateToken()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ token])

 
	 
	return {   validateToken, tokenIsValid };
};


export const useIsTokenValid = ( ) => {
	const navigate = useNavigate();
	const { userDetails } = useUserAuthContext();
	useEffect(() => {
		const tokenIsValid = (_token: string | null) => {
			if(!_token) return false;
			const decoded = JWT_DECODE(_token) as any;
			const secs = timeDiffInSecs(+decoded?.exp * 1000, new Date());
			return secs > 0;
		};
		if(userDetails?.token ) {
			tokenIsValid(userDetails?.token) && navigate(`/dashboard`);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ userDetails?.token])
 
	 
};