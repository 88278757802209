
import dashboardlight from "assets/svg/dashboardlight.svg"
import integrationlight from "assets/svg/dashboardlight.svg"
import transactionlight from "assets/svg/dashboardlight.svg"
import walletlight from "assets/svg/dashboardlight.svg"
import settingslight from "assets/svg/dashboardlight.svg"
import customerslight from "assets/svg/dashboardlight.svg"



import dashboarddark from "assets/svg/dashboardicon.svg"
import integrationdark from "assets/svg/integrationdark.svg"
import transactiondark from "assets/svg/transactiondark.svg"
import walletdark from "assets/svg/walletdark.svg"
import settingsdark from "assets/svg/settingsdark.svg"
import customersdark from "assets/svg/customersdark.svg"

interface RoutesType {
    id: any,
    name: any,
    path: string,
    logolight?: string;
    logodark?: string;
}

const routesArray: RoutesType[] =
[
    {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        logolight: dashboardlight,
        logodark: dashboarddark
    },
    {
        id: 2,
        name: "Integration",
        path: "/integration",
        logolight: integrationlight,
        logodark: integrationdark
    },
    {
        id: 3,
        name: "Transaction",
        path: "/transactions",
        logolight: transactionlight,
        logodark: transactiondark
    },
    {
        id: 4,
        name: "Customers",
        path: "/customers",
        logolight: customerslight,
        logodark: customersdark
    },
    {
        id: 5,
        name: "Wallet",
        path: "/wallet",
        logolight: walletlight,
        logodark: walletdark
    },
    {
        id: 6,
        name: "Settings",
        path: "/settings",
        logolight: settingslight,
        logodark: settingsdark
    }
 ]


export default routesArray



export const AdminRoutesArray: RoutesType[] =
[
    {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        logolight: dashboardlight,
        logodark: dashboarddark
    },
    {
        id: 2,
        name: "Merchants",
        path: "/merchants",
        logolight: transactionlight,
        logodark: transactiondark
    },
    {
        id: 3,
        name: "Transaction",
        path: "/transactions",
        logolight: transactionlight,
        logodark: transactiondark
    },
    {
        id: 4,
        name: "KYC",
        path: "/kyc",
        logolight: transactionlight,
        logodark: transactiondark
    },
    {
        id: 5,
        name: "Sender ID",
        path: "/senderId",
        logolight: transactionlight,
        logodark: transactiondark
    },
 
    {
        id: 6,
        name: "Settings",
        path: "/settings",
        logolight: settingslight,
        logodark: settingsdark
    }
 ]