import { useEffect, useState } from "react";
import { getUser } from "services";
import { useUserAuthContext } from "context/UserAuthContext";
import toast from "react-hot-toast";
import { onBoardingDataTypes } from "utils/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { onboardingSchema } from "validations";
import { Controller, useForm,   } from "react-hook-form";
// import { onboardUser } from "services";
import Upload from "kodobe-react-upload";
import FormError from "comps/FormError";
import Input from "comps/Input";
import Button from "comps/Button";
import safepro from "axiosApi";

 

 

const Personalization = () => {
	const { userDetails: { user: { userId }, token,},} = useUserAuthContext();
	const [userBusinessDetails, setUserBusinessDetails] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);

 

  const { handleSubmit, control, setValue, formState: { errors },
  } = useForm<onBoardingDataTypes>({
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
      businessName: "",
      businessMainColor: "",
      phoneNumber: "",
      businessSecondaryColor: "",
      businessLogo: "",
    },
    resolver: yupResolver(onboardingSchema),
  });


    const onboardUser = async (data: any) => {	
		try {
		const res = await safepro.put(
			`/users/v1/users/${userId}`,
			{...data},
		{
			headers: {
			"client-id": process.env.REACT_APP_CLIENT_ID,
			Authorization: `Bearer ${token}`,
			}
		}
		);
		return res;
		} catch (error) {
			return console.log(error);
		}
	};
  

	

	const fetchUserDetails = async () => {
		const res = await getUser(userId);
		if (res.status >= 200 && res.status <= 299) {
			setUserBusinessDetails(res.data?.data);
			setValue("phoneNumber", `+${res.data?.data?.phoneNumber}`);
			setValue("email", res.data?.data?.email);
			setValue("businessName", res.data?.data?.profile?.businessName);
			setValue("businessLogo", res.data?.data?.profile?.businessLogo);
			setValue("businessMainColor", res.data?.data?.profile?.businessMainColor);
			setValue("businessSecondaryColor", res.data?.data?.profile?.businessSecondaryColor);
		} else {
			toast.error("Dashboard Stats Not found!!!");
		}
	};

	useEffect(() => {
		fetchUserDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
 
  

	const handleOboard = async (data: any) => {
		setLoading(true);
		const res: any = await onboardUser({
			userId: userId,
			token: token,
			...data
		});
		setLoading(false);
		if (res.status >= 200 && res.status < 299) {
			toast.success(`Success: User Onboaded`, { duration: 3000,});
			fetchUserDetails();
			return;
		} else {
			return toast.error(`Error: ${res?.data?.error}`, { duration: 3000,});
		}
	};

  return (
    <div>
      <div className="w-11/12 flex items-end justify-end mb-10">
        <img
          src={userBusinessDetails?.profile?.businessLogo}
          alt="business logo"
          className="w-[80px] h-[80px] md:w-[160px] md:h-[160px] rounded-full"
        />
      </div>

      <form onSubmit={handleSubmit(handleOboard, (e) => console.log(e))}>
        <div className="w-11/12 md:w-[98%] mx-auto mt-5 md:mt-14 grid grid-cols-1 md:grid-cols-2 md:gap-x-32">
          <Controller
            name="businessName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) =>  (
              <Input
                type="text"
                label="Business Name"
                placeholder="Enter business name"
                className="mb-5 capitalize"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors?.businessName?.message}
              />
            )}
          />
          <div className="flex justify-between">
			<Controller
				name="businessLogo"
				control={control}
				render={({ field: {onChange,  value } }) =>  {
					return (
						<>
							<Upload
								validImageTypesSrc={["jpg", "jpeg", "png"]}
								uploadUrl={process.env.REACT_APP_UPLOAD_URL}
								fileName="file"
								title={"Upload a logo"}
								onChange={(e) => {
									if (!e.completed) {
									} else if (e.completed) {
										onChange(  e?.responseFromServer?.data?.url );
									}
								}}
								headers={{ token: token, "client-id": process.env.REACT_APP_CLIENT_ID}}
							/>
							<FormError error={errors.businessLogo?.message} />
							<div className="spacer-10"></div>
								{
									value ? 
										<div style={{width: "30px", height: "30px"}}>
											<img src={value} width="30px" alt="" height={"30px"} className="object-contain"/> 
										</div>
										: null
								}
						</>
					)
				}}
             />
			
          </div>

          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type="text"
                label="Email"
                placeholder="Enter email address"
                className="mb-5"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors?.email?.message}
                disabled={userBusinessDetails?.email}
              />
            )}
          />

          <Controller
            name="businessMainColor"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="flex items-center gap-x-2">
                <div className="flex flex-1">
                  <Input
                    type="color"
                    label="Business Main Colour"
                    placeholder="Enter  main colour code #ff0 or yellow"
                    className="mb-5"
                    extraClass="w-full"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={errors?.businessMainColor?.message}
                  />
                </div>
              </div>
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type="text"
                label="Phone Number"
                placeholder="Enter phone number"
                className="mb-5"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors?.phoneNumber?.message}
                disabled={userBusinessDetails?.phoneNumber}
              />
            )}
          />

          <Controller
            name="businessSecondaryColor"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
				return (
					(
						<div className="flex items-center gap-x-2">
						  <div className="flex flex-1">
							<Input
							  type="color"
							  label="Business Secondary Colour"
							  placeholder="Enter secondary colour code #ff0 or yellow"
							  className="mb-5"
							  onBlur={onBlur}
							  extraClass="w-full"
							  onChange={onChange}
							  value={value}
							  error={errors?.businessSecondaryColor?.message}
							/>
						  </div>
						</div>
					  )
				)
			}}
          />
        </div>
        <Button
          variant="primary"
          type="submit"
          className="w-40 mx-auto flex justify-center mt-5 drop-shadow-md"
        >
          {loading ? "..." : "Save Details"}
        </Button>
      </form>
    </div>
  );
};

export default Personalization;
