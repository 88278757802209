import Button from "comps/Button";
import Input from "comps/Input";
import { NavLink, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpSchema } from "validations";
import FormError from "comps/FormError";
import AuthRight from "comps/AuthRight";
import { SignUpFormType, submitDataTypes } from "utils/types";
import { getCountryCallingCode } from "react-phone-number-input/input";
import { useUserAuthContext } from "context/UserAuthContext";
import { registerAccount, createBusiness } from "services";
import toast from "react-hot-toast";
import {   useState } from "react";
import { useIsTokenValid,   } from "hooks/useValidateTokken";


const SignUp = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [TAC, setTAC] = useState(false)
	const navigate = useNavigate();
	useIsTokenValid()
	const {   setUserDetails} = useUserAuthContext();
	 


	const { handleSubmit, control, setValue, formState: { errors }, } = useForm<SignUpFormType>({
		reValidateMode: "onBlur",
		defaultValues: {
			email: "",
			phoneNumber: "090",
			password: "",
			confirmPwd: "",
			country: "NG",
		},
		resolver: yupResolver(signUpSchema),
	});

	const registerOnsubmit = async (data: submitDataTypes) => {
		const { email, phoneNumber, password, country } = data;
		const phone = phoneNumber
			?.substring(
				getCountryCallingCode(country)?.length === 3
					? 4
					: getCountryCallingCode(country)?.length === 2
					? 3
					: 2
			)
			?.replace(/\s/g, "");
		setLoading(true);
		const res = await registerAccount({
			email,
			phoneNumber: phone,
			password,
			country,
		});

		if (res.status >= 200 && res.status < 299) {
			toast.success("Registration successfull");
		} else {
			toast.error(`Error: ${res?.data?.error}`, {
				duration: 3000,
			});
			setLoading(false);
			return;
		}

		await createBusiness({
			businessId: res?.data?.data?.user?.userId,
			businessName: email,
		});
		setLoading(false);

		if (res.status >= 200 && res.status < 299) {
		 
			setUserDetails(res?.data?.data);
			localStorage.setItem("authDetails", JSON.stringify(res?.data?.data));
			navigate("/verify-otp", { state: { email: email, },});
			return;
		} else {
			return toast.error(`Error: ${res?.data?.error}`, {
				duration: 3000,
			});
		}
	};

	return (
		<div className="text-primary w-full min-h-screen flex flex-col md:flex-row">
			<div className="bg-white p-5 w-full md:w-6/12 h-full">
				<div className="w-full md:w-10/12 py-5 md:py-20 mx-auto h-full">
				<h1 className="text-3xl mb-4 font-bold font-openSans">
					Welcome to SAFEPro
				</h1>
				<h3 className="text-base font-openSans">
					Fill in your details to join SAFEPro
				</h3>

				<form
					onSubmit={handleSubmit(registerOnsubmit)}
					className="w-full md:w-9/12 mt-5 md:mt-10"
				>
					<Controller
						name="email"
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<Input
								type="email"
								label="Email"
								placeholder="Enter email address"
								className="mb-5"
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								error={errors?.email?.message}
							/>
						)}
					/>

					<label
						htmlFor="phoneNumber"
						className=" bg-transparent capitalize font-display text-xs"
					>
						Phone Number
					</label>
					<PhoneInput
						placeholder="Enter phone number"
						label="Enter phone number"
						name="phoneNumber"
						id="phoneNumber"
						onCountryChange={(v) => setValue("country", v, { shouldTouch: true })}
						onChange={(v) =>setValue("phoneNumber", v, { shouldTouch: true })}
						defaultCountry="NG"
						className="h-12 rounded-md w-full outline-none border pl-3"
					/>

					{errors.phoneNumber && (
						<FormError
							error={errors?.phoneNumber?.message}
							className="mb-4 mt-1"
						/>
					)}
					<Controller
						name="password"
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<Input
								type="password"
								label="Password"
								placeholder="Enter password"
								className="mb-5"
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								error={errors?.password?.message}
							/>
						)}
					/>



					<Controller
						name="confirmPwd"
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<Input
								type="password"
								label="Confirm Password"
								placeholder="Enter to confirm password"
								className="mb-5"
								onBlur={onBlur}
								onChange={onChange}
								value={value}
								error={errors?.confirmPwd?.message}
							/>
						)}
					/>
				


					<div className="mt-6">
						<input
							type="checkbox"
							className="mb-5"
							onChange={(e) => setTAC(e.target.checked)}
							checked={TAC}
						/>
						<NavLink to="https://safepro.ng/terms" target="_blank" className={"text-gray-700 ml-2"}>I accept the Terms and Condition</NavLink>
					</div>


					<div className="w-full mt-8">
						<Button type="submit" disabled={!TAC} className={ TAC ?  "" : "TAC-grey" }>
							{loading  ? "loading ..." : "Create account"}
						</Button>
						<p className="text-center mt-2 text-safeblack">
							Already have an account?{" "}
							<NavLink to="/login" className="text-primary500">
							Log in
							</NavLink>
						</p>
					</div>
				</form>
				</div>
			</div>
			<AuthRight />
		</div>
	);
};

export default SignUp;
