




import AuthLayout from "comps/AuthLayout";
import {    useParams } from "react-router-dom";
import Transaction from "../../comps/Transaction"
import { GoBack } from "comps/goBack";





const CustomerTransaction = ( ) => {
	const { phoneNumber} = useParams()

	
 

	return (
		<AuthLayout name="Customers - transactions">
			<GoBack backLink={"/customers"}/>
		 
			<Transaction filterValue={phoneNumber} filterKey={"phoneNumber"}/>
		</AuthLayout>
	)
}

export default CustomerTransaction;