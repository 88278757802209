import Button from "comps/Button";
import Input from "comps/Input";
import React, { useState } from "react";
import { Controller, useForm,   } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { onBoardingDataTypes } from "utils/types";
import { onboardingSchema } from "validations";
import { onboardUser } from "services";
import { useUserAuthContext } from "context/UserAuthContext";
import toast from "react-hot-toast";
import Upload  from "kodobe-react-upload"
import FormError from "comps/FormError";
 

 

const Onboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    setUserDetails,
    userDetails: {
      token,
      user: { userId, phoneNumber, email },
    },
  } = useUserAuthContext();
 

  

	const { handleSubmit, control, formState: { errors },} = useForm<onBoardingDataTypes>({
		reValidateMode: "onBlur",
		defaultValues: {
		email: email || location?.state?.email || "",
		businessName: "",
		businessMainColor: "",
		phoneNumber: phoneNumber || "",
		businessSecondaryColor: "",
		businessLogo: "",
		},
		resolver: yupResolver(onboardingSchema),
	});

	const handleOboard = async (data: any) => {
		if (loading) return;
		setLoading(true);
			const res = await onboardUser({
			userId: userId,
			token: token,
			businessName: data.businessName,
			businessLogo:  data.businessLogo,
			email: data.email,
			businessMainColor: data.businessMainColor,
			phoneNumber: data.phoneNumber,
			businessSecondaryColor: data.businessSecondaryColor,
			...data
		});
		setLoading(false);
		if (res.status >= 200 && res.status < 299) {
			toast.success(`Success: User Onboaded`, { duration: 3000, });
			setUserDetails(res?.data?.data);
			localStorage.setItem("authDetails", JSON.stringify(res?.data?.data));
			navigate("/integration", { replace: true });
			return;
		} else {
		return toast.error(`Error: ${res?.data?.error}`, {
			duration: 3000,
		});
		}
	};

 

  return (
    <div className="bg-white min-h-screen">
      <h1 className="m-5 text-primary500 w-full font-semibold font-openSans text-xl">
        SAFEPro
      </h1>

      <h1 className="text-safeproblack mt-5 md:mt-10 text-center text-lg md:text-3xl font-semibold">
		Required Information
      </h1>
      <p className="text-[#404345] text-center text-sm md:text-base mt-5 px-3 md:px-0">
        Fill in the following details about your business to get started with
        SAFEPro
      </p>
      <form onSubmit={handleSubmit(handleOboard)}>
        <div className="w-11/12 md:w-9/12 mx-auto mt-5 md:mt-14 grid grid-cols-1 md:grid-cols-2 md:gap-x-32">
          <Controller
            name="businessName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type="text"
                label="Business Name"
                placeholder="Enter business name"
                className="mb-5"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors?.businessName?.message}
              />
            )}
          />
     
		<div className="flex justify-between flex-col">
			<label   className=" bg-transparent capitalize font-display text-xs">Business logo</label>
			<div className="flex justify-between flex-1">
				<Controller
					name="businessLogo"
					control={control}
					render={({ field: {onChange,  value } }) =>  {
						return (
							<>
								<Upload
									validImageTypesSrc={["jpg", "jpeg", "png"]}
									uploadUrl={process.env.REACT_APP_UPLOAD_URL}
									fileName="file"
									title={"Upload a logo"}
									onChange={(e) => {
										if (!e.completed) {
										} else if (e.completed) {
											onChange(  e?.responseFromServer?.data?.url );
										}
									}}
									headers={{ token: token, "client-id": process.env.REACT_APP_CLIENT_ID}}
								/>
								<FormError error={errors.businessLogo?.message} />
								<div className="spacer-10"></div>
									{
										value ? 
											<div style={{width: "30px", height: "30px"}}>
												<img src={value} width="30px" alt="" height={"30px"} className="object-contain"/> 
											</div>
											: null
									}
							</>
						)
					}}
					/>
				
				</div>
          </div>
 
     

          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type="text"
                label="Email"
                placeholder="Enter email address"
                className="mb-5"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors?.email?.message}
                disabled={email || location?.state?.email}
              />
            )}
          />

          <Controller
            name="businessMainColor"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="flex items-center gap-x-2">
                <div className="flex flex-1">
                  <Input
                    type="color"
                    label="Business Main Colour"
                    placeholder="Enter  main colour code #ff0 or yellow"
                    className="mb-5"
                    extraClass="w-full"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={errors?.businessMainColor?.message}
                  />
                </div>
                {/* <MainColorBox control={control} /> */}
              </div>
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type="number"
                label="Phone Number"
                placeholder="Enter phone number"
                className="mb-5"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={errors?.phoneNumber?.message}
                disabled={phoneNumber}
              />
            )}
          />

          <Controller
            name="businessSecondaryColor"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="flex items-center gap-x-2">
                <div className="flex flex-1">
                  <Input
                    type="color"
                    label="Business Secondary Colour"
                    placeholder="Enter secondary colour code #ff0 or yellow"
                    className="mb-5"
                    onBlur={onBlur}
                    extraClass="w-full"
                    onChange={onChange}
                    value={value}
                    error={errors?.businessSecondaryColor?.message}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Button
          btnText="Save Details"
          type="submit"
          className="w-40 mx-auto flex justify-center mt-5 drop-shadow-md"
        >
          {loading ? "..." : ""}
        </Button>
      </form>
    </div>
  );
};

export default Onboard;
