import React, { createContext, useContext, useState } from "react";

type UserAuthContextProviderProps = {
  children: React.ReactNode;
};
const authDetails: any = {
	data: "yes",
	userDetails: {},
	setUserDetails: () => {},
};
const UserAuthContext = createContext(authDetails);

export const useUserAuthContext = () => {
	return useContext(UserAuthContext);
};

const UserAuthContextProvider = ({children,}: UserAuthContextProviderProps) => {
	const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("authDetails")!));
	const [KYC, setKYC] = useState({})
	const [user, setUser] = useState({})

	return (
		<UserAuthContext.Provider value={{ userDetails, setUserDetails , setKYC, KYC, user, setUser }}>
		{children}
		</UserAuthContext.Provider>
	);
};

export default UserAuthContextProvider;
