import {   CopyIcon, leftarrow, PreviewIcon,   } from "assets/svg-code";
import Button from "comps/Button";
import Input from "comps/Input";
import React, { useEffect,   } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { redirectUrlSchema,   } from "validations";
import    "../../../App.css";
import { updateRedirectUrl,   } from "services";
import { useUserAuthContext } from "context/UserAuthContext";
import toast from "react-hot-toast";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { handleCopy } from "utils/utilis";
import { useGetSenderIds } from "hooks/useSederId";
 



const Redirect = (props: any) => {
	const { title,   goBack } = props;
	const { userDetails: { token, user: { userId }, }, KYC, user} = useUserAuthContext();

	const {  senderIds } = useGetSenderIds("", "APPROVED")

	const { register, control, watch, setValue, formState: { errors }, handleSubmit, } = useForm<{ 
		redirectUrl: string; enableKYC: boolean; webhookUrl: string , activateSenderId: boolean , senderID: string
	}>({
		reValidateMode: "onBlur",
		defaultValues: {
			redirectUrl: "",
			enableKYC: false,
			webhookUrl: "",
			senderID: "",
		},
		resolver: yupResolver(redirectUrlSchema),
	});
	useEffect(() => {
	  const fetchUserDetails = async () => {
		  setValue("redirectUrl", user?.profile?.redirectUrl);
		  setValue("webhookUrl", user?.profile?.webhookUrl);
		  if (user?.profile?.enableKYC) {
			  setValue("enableKYC", true);
		  }
	  };
	  fetchUserDetails();
	}, [user?.profile?.enableKYC, user?.profile?.webhookUrl, user?.profile?.redirectUrl, setValue ]);
  const watchEnableKYC = watch("enableKYC");
  const activateSenderId = watch("activateSenderId");

 

	const submitRedirectUrl = async (data: any) => {
		const res = await updateRedirectUrl({
			userId,
			url: data?.redirectUrl,
			token,
			webhookUrl: data?.webhookUrl,
			enableKYC: data.enableKYC,
			...(data?.senderID && {senderID: data?.senderID})
		});
		if (res.status >= 200 && res.status <= 299) {
			toast.success(`RedirectURL update success`);
		} else {
			toast.error(`Error: ${res.data.error}`);
		}
	};


	
	

  return (
    <div className="flex flex-col">
		<div className="flex items-start">
				<button className="appearance-none pt-2" onClick={() => goBack("inactive")} >{leftarrow}</button>
				<div className="flex flex-col ml-5 w-auto">

				<h1 className="text-2xl font-semibold   text-[#1A1B1C] mb-2 general-sans">
					{title}
				</h1>

			
				<span className="text-base general-sans-light" style={{color: "#1A1B1C"}}>
					After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.
				</span>
				<div >
					<span className="text-base font-normal general-sans-light" style={{color: "#1A1B1C"}}>
						This is your unique URL
					</span>
				</div>
				<div className="w-10/12 flex  items-center gap-x-3">
					<Input
						placeholder={`${window.location.origin}/${userId}/get-code`}
						extraClass="w-10/12"
						disabled={true}
					/>
					<button onClick={() =>handleCopy(`${window.location.origin}/${userId}/get-code${watchEnableKYC ? "?watchEnableKYC=true" : ""}`)}
						className="border border-[#184E77] bg-white rounded-md p-2 cursor-pointer"
					>
						<CopyIcon />
					</button>

					<div className="bg-primary border border-transparent rounded-md p-2 cursor-pointer"
						onClick={() => window.open(`${window.location.origin}/${userId}/get-code${watchEnableKYC ? "?watchEnableKYC=true" : ""}`, "_black")}
					>
						{<PreviewIcon width={25} height="25" color="white" />}
					</div>
				</div>
				

 
				<div className="flex my-3 gap-x-3">
					<label htmlFor="enableKYC">Activate Sender ID</label>{" "}
					<input  
						{...register("activateSenderId")}  
						id="activateSenderId"  name="activateSenderId"  
						type="checkbox" disabled={ KYC?.status !== "APPROVED"} 
						className={`${ KYC?.status !== "APPROVED" ? "cursor-not-allowed" : ""}`}
					/>
				</div>
			
				{
					activateSenderId ?
					<>
						<span>Specify SenderID</span>
						<select className="px-5 py-2 mx-4  rounded bg-transparent border border-slate-300 border-solid"  onChange={(e) => setValue("senderID", e.target.value)}>
							<option value={""}>Select</option>
							{
								senderIds?.length > 0 ?
									senderIds?.map((senderId: {senderId: string, _id: string}) => (
										<option key={senderId._id || senderId?.senderId} value={senderId?._id}>{senderId?.senderId}</option>
									))
									:
									<option  value={""}>No sender ID found</option>
							}
						</select>
					</>
					: null
				}


				<form onSubmit={handleSubmit(submitRedirectUrl, e => console.log(e))} className="flex flex-col mt-8" >
					<div className="flex gap-x-3 items-center mb-2">
						<span className="text-xl general-sans" style={{color: "#1A1B1C"}}>
							Provide your Redirect URL
						</span>
						<Tooltip title="This is where your customers will be redirected to after
						verification is completed">
							<InfoCircleOutlined />
						</Tooltip>
					</div>
					<span className="text-base general-sans-light" style={{color: "#1A1B1C"}}>
						This is where your customers will be redirected to after verification is completed
					</span>
					<Controller name="redirectUrl" control={control} render={({ field: { onChange, onBlur, value } }) => (
						<Input
							name="redirectUrl"
							className="w-10/12"
							placeholder="https://sanimara.com/url"
							onChange={onChange}
							onBlur={onBlur}
							value={value}
							type="url"
							error={errors?.redirectUrl?.message!}
						/>
						)}
					/>

					<div className="flex my-3 gap-x-3">
						<label htmlFor="enableKYC">Enable Kyc</label>{" "}
						{
								<input  
									{...register("enableKYC")}  
									id="enableKYC"  name="enableKYC"  
									type="checkbox" disabled={ KYC?.status !== "APPROVED"} 
									className={`${ KYC?.status !== "APPROVED" ? "cursor-not-allowed" : ""}`}
								/>
						}
					</div>
					{watchEnableKYC && (
						<div >
							<div className=" mb-2 my-3">
								<div className="flex items-center gap-x-3">
									Provide your Webhook URL
									<Tooltip title="Supply url to receive KYC data">
										<InfoCircleOutlined />
									</Tooltip>
								</div>
								<Controller
									name="webhookUrl"
									control={control}
									render={({ field: { onChange, onBlur, value } }) => (
									<Input
										name="webhookUrl"
										className="w-10/12"
										placeholder="https://sanimara.com/url"
										onChange={onChange}
										onBlur={onBlur}
										value={value}
										type="url"
										error={errors?.webhookUrl?.message!}
									/>
									)}
								/>
							</div>
						</div>
					)}
						
					<Button type="submit" variant="hover" className="w-[100px] mt-5">
						Submit
					</Button>
				</form>
			
			</div>
		</div>
    </div>
  );
};

export default Redirect;
