import { Empty, Table } from "antd";

const TableFooterExtra = ({ children }: any) => {
  return (
    <span className="capitalize text-gray-400 font-display font-bold">
      {children}
    </span>
  );
};

const CustomTable = (props: any) => {
  const {
    dataSource,
    loading,
    columns,
    pagination = {},
    title,
  } = props;
  return (
    <Table
      title={title}
      locale={{
        emptyText: <Empty description="No Data" style={{ margin: "5rem 0" }} />,
      }}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      footer={(currentPageData: any) => (
        <TableFooterExtra>
          <span>
            {currentPageData.length > 1
              ? `${currentPageData.length} customer's`
              : `${currentPageData.length} customer`}
          </span>
        </TableFooterExtra>
      )}
      scroll={{ x: "max-content" }}
      pagination={pagination}
    />
  );
};
export default CustomTable;
