

// import AuthLayout from "comps/AuthLayout";
import AuthLayout from "comps/AuthLayout";
import { useGetAdmin } from "hooks/useAuth";
import React from "react";


export const AdminRoute = ({children}: any ) => {
	const { isAdmin,  } = useGetAdmin()

	
	return (
		<React.Fragment>{
			isAdmin ? children :

				<AuthLayout name="">
					<div className="flex justify-center  pt-20 items-center ">
						<span>You are not allowed to access this page</span>
					</div>
				</AuthLayout>
		}</React.Fragment>
	)
}
