import { yupResolver } from "@hookform/resolvers/yup";
import AuthRight from "comps/AuthRight";
import Button from "comps/Button";
import Input from "comps/Input";
import { useIsTokenValid,   } from "hooks/useValidateTokken";
import { useForm , Controller} from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "services";
import * as yup from "yup";



const ForgotPassword = (data: any) => {
	const navigate = useNavigate();
	useIsTokenValid()

	



	const { handleSubmit, control, formState: { errors },} = useForm<{email: string}>({
		reValidateMode: "onBlur",
		defaultValues: {
			email: "",
		},
		resolver: yupResolver(yup.object({email: yup.string().email().required("Email is required").label("Email")})),
	});




  const handleForfotPassword = async (data: any) => {
    const res = await forgotPassword({ value: data.email, type: "email" });
	// console.log(res, "pas")
    if (res.status >= 200 && res.status <= 299) {
		toast.success( `${res?.data?.data} || 'An otp has been sent to your registered email'`, { duration: 3000, });
		navigate(`/reset-password`, { state: { email: data.email, },});
    } else {
      toast.error(`${res?.data?.message} || 'Error connecting ....'`, {
        duration: 3000,
      });
    }
  };
  return (
    <div className="text-primary w-full h-screen flex  flex-col md:flex-row">
      <div className="bg-white p-5 w-full md:w-6/12 h-full">
        <div className="w-full md:w-10/12 py-20 mx-auto h-full">
          <h1 className="text-3xl mb-4 font-bold font-openSans">
            Forgot Password
          </h1>
          <h3 className="text-base font-openSans">
            You can reset your password by entering your
            <br /> details below
          </h3>

          <form
            onSubmit={handleSubmit(handleForfotPassword, (e) => console.log(e))}
            className="w-full md:w-9/12 mt-5 md:mt-10"
          >
			 <Controller
				name="email"
				control={control}
				render={({ field  }) => (
					<Input
						{...field}
						type="email"
						label="Email"
						placeholder="Enter email address"
						className="mb-5"
						error={errors?.email?.message}
					/>
				)}
			/>


            

            <div className="w-full mt-4 md:mt-8">
              <Button type="submit" btnText="Submit" />
            </div>
          </form>
        </div>
      </div>
      <AuthRight />
    </div>
  );
};

export default ForgotPassword;
