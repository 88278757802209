import { Next, TopUpArrow } from "assets/svg-code";
import AuthLayout from "comps/AuthLayout";
import CustomTable from "comps/CustomTable";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {  useSearchParams} from "react-router-dom";
import "../../App.css";



import {   useGetUserWallet, useGetWalletKPI } from "services/auths";
import TopUpModal  from "./topUp";
import { CURRENCY_STRING } from "constants/index";
import { useUserAuthContext } from "context/UserAuthContext";
 
 

const Wallet = () => {

	const [loginModal, setLoginModal] = useState<boolean>(false);
	const {   KYC } = useUserAuthContext();
  
	const { loading, wallets, pageInfo, setPageInfo , handlegetWallet} = useGetUserWallet()
	const {    KPI} = useGetWalletKPI()


	const [searchParams] = useSearchParams();
 

	useEffect(() => {
		if(searchParams.get("status") === "SUCCESS" && searchParams.get("transactionRef"))   {
			toast.success(`Tup up successful`, {duration: 3000, });
		}
	}, [searchParams])

	const sampleCustomers: any = {
		total: wallets?.length,
		data:   wallets?.map((wallet: any) => (
			{
				key: wallet?.id,
				sn: "S/N",
				date: moment(wallet?.createdAt).format("DD/MM/YYYY"),
				balance: wallet?.creditAmount >  wallet?.debitAmount  ? wallet?.creditAmount : wallet?.debitAmount,
				type: wallet?.creditAmount >  wallet?.debitAmount ? "Credit" : "Debit",
			}
		 ))
	 
	  };


	const cards = [
		{title: "Wallet Balance", value: typeof( KPI?.balance ) === "number"  ?  KPI?.balance?.toString()  : KPI?.balance?.balance?.toString() || 0 },
		{title: "Amount Funded", value: KPI?.amountFund?.toString() || 0},
		{title: "Amount Spent", value: KPI?.amountSpent?.[0]?.total?.toString() || 0},
	]

  const columns = [
    {
      title: "S.N",
      index: "sn",
      dataIndex: "s/n",
      render: (text: string, record: {}, index: number) => <div>{index + 1}</div>,
    },
    {
      title: <div>Date</div>,
      render: (record: any) => <div>{record?.date}</div>,
    },
    {
      title: <div>Amount ( {CURRENCY_STRING})</div>,
      render: (record: any) =>  <div>{record?.balance}</div>,
    },
    {
      title: "Transaction Type",
      render: (record: any) => {
        return <div >
			<span  className={"px-5 py-2 text-xs"}  style={{backgroundColor: record?.type === "Credit" ?  "#c5f4c5"   : "#ffd6d6", color: record?.type === "Credit" ?  "#0a990a"   : "#ab0909"  }}>
			{record?.type}
			</span>
		</div>;
      },
    },
 
 
  ];


 
 

  return (
    <AuthLayout name="Wallet">


		<div className="grid gap-8" style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}>
			{
				cards.map(card => (
					<div className="w-full bg-[#FAFDFF] rounded-lg p-5 border border-[#D5D7D8]" key={card.title}>
						<div className="flex justify-between items-center mb-5">
							<h3 className="text-[20px] inter">{card.title}</h3>
						</div>
						<h1 className="text-4xl   text-[56px] inter">{CURRENCY_STRING}{card.value}</h1>
						{
							card.title === "Wallet Balance" ?
								<button 
									className={`
										${KYC?.status !== "APPROVED" ?  "bg-[#ccdce9] " : "bg-[#184E77] "}  
										${KYC?.status !== "APPROVED" ?  "cursor-not-allowed" : "cursor-pointer"}  
										p-2 px-3 rounded-lg text-white w-fit flex mr-0 ml-auto`}
									onClick={() => setLoginModal(true)}
									disabled={KYC?.status !== "APPROVED" }
								>
									Top Up <TopUpArrow />
								</button>
								: null
						}
					</div>
				))
			}
		</div>


		<div className="flex justify-center p-3 items-center gap-x-3 text-[20px]">
			This month Summary
			<Next />
		</div>
		<div className="mt-10 w-full">
			<CustomTable
				tableName="Customers"
				dataSource={sampleCustomers.data}
				loading={loading}
				columns={columns}
				pagination={{
					pageSize: pageInfo.limit,
					total: sampleCustomers.total,
					onChange: (page: any) => {
						setPageInfo({...pageInfo, page});
					},
				}}
			/>
		</div>

		<TopUpModal setLoginModal={setLoginModal} loginModal={loginModal} handlegetWallet={handlegetWallet}/>
    </AuthLayout>
  );
};

export default Wallet;
