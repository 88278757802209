// ts-ignore



import AuthLayout from "comps/AuthLayout";

import Transaction from "comps/Transaction";
 
 
const Transactiond = () => {
 

 
  return (
	<AuthLayout name="Transactions">
		
		<Transaction />
	</AuthLayout>
 
  );
};

export default Transactiond;
