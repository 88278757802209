// ts-ignore
import { SearchIcon } from "assets/svg-code";
import AuthLayout from "comps/AuthLayout";
import Button from "comps/Button";
import CustomTable from "comps/CustomTable";
import { AdminRoute } from "hoc/adminRoute";
import {   useGetMerchants } from "hooks/useMerchants";


import moment from "moment";
import   { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as _ from "underscore";
import DownloadReport from "./downloadReport";



 
const Customer = () => {
 
	const [modal, setModal]  = useState({type: ""})
	const { handleGetCustomers, customers, loading } = useGetMerchants()
	const [ready, setReady] = useState(false)
	const [filter, setFIlter] = useState("")
	const optimisedTriggerHandler = _.throttle((e: any) => handleGetCustomers(  e ? `${filter}=${e}` : ""), 2000, { leading: false });
	const navigate = useNavigate();


	const [ , setPage] = useState<number>(1);

	
	const columns = [
		{
			title: "S.N",
			index: "sn",
			dataIndex: "s/n",
			render: (text: string, record: {}, index: number) => <div>{index + 1}</div>
		},
		{
			key:  "createdAt",
			title: <div>Date Registered</div>,
			render: (record: any) => <> {moment(record?.createdAt).format("DD/MM/YYYY HH:MM a")}</>
		},
		{
			key:  "customerId",
			title: <div>Status </div>,
			render: (record: any) =>   <span className={"px-5 py-2 text-xs"}
				style={{
					color:  record?.status?.toUpperCase() === "ACTIVE" ? "#1aa11a": record?.status?.toUpperCase() === "INACTIVE" ?  "#ab0909"  : "#ab0909"  
				}}
			>{record?.status?.toUpperCase()}</span>
		},
		{
			key:  "businessName",
			title: <div>Business Name</div>,
			render: (record: any) => <div className="">{record?.businessName}</div>,
		},
		{
			key:  "transactions",
			title: <div>Actions</div>,
			render: (phone: any) => (
				<div  className="py-3 rounded-sm text-sm px-1 flex justify-start cursor-pointer text-white  ">  
					<div 
						className=" rounded-sm text-sm mx-1 flex justify-center cursor-pointer text-[#184E77] hover:text-[#87c2f0]"
						onClick={() => navigate(`/merchants/merchant/${phone?.userId}` )}
					>  
						View  Merchant
					</div>
				</div>
			),
		},
	];

 

	useEffect(() => {
		setReady(true)
		ready && handleGetCustomers(  "");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ready]);
 


	
	
return (
	<AdminRoute>
		<AuthLayout name="Merchants">

			<div   className="py-3  lg:gap-6 xl:gap-6 flex grid-cols-1 flex-wrap md:grid-cols-2">
				<div>
					<span className="">Filter by</span>
				</div>
				<select className="px-5 py-2 mx-4  rounded bg-transparent border border-slate-300 border-solid"  onChange={(e) => setFIlter(e.target.value)}>
					<option value={""}>Select</option>
					<option value={"phoneNumber"}>Phone number</option>
					<option value={"customerId"}>Customer ID</option>
				</select>
				

				<div  className="flex-grow-0   w-auto  grow max-w-xs flex items-center border  border-[#D5D7D8] bg-[#FEFDFD]  rounded-lg  gap-x-2 px-2">
					<SearchIcon /> 
					<input
						className="border h-12 text-sm focus:outline-none block w-full   border-transparent p-2  "
						style={{cursor: !filter ? "not-allowed": "auto"}}
						type="search"
						name="search"
						disabled={!filter}
						onChange={(e) => optimisedTriggerHandler(e.target.value)}
						placeholder={!filter ? "Select a filter option" :`Search by ${filter}`}
					/>
				</div>
				
				<Button
					btnText={ "Download Report"}
					variant="primary"
					type="button"
					className="w-40 ml-auto lg:mt-0 mt-3 ml-3 flex justify-center  drop-shadow-md ml-3"
					onClick={() => setModal({type: "DOWLOAD_REPORT"})}
				/>
			</div>


			<div className="mt-10 w-full ">
				<CustomTable
					tableName="Merchants"
					dataSource={customers?.docs?.map((data: {[id: string]: string}) => ({...data, key: data?.id}))}
					loading={loading}
					columns={columns}
					pagination={{
						pageSize: 10,
						total: customers?.total,
						onChange: (page: any) => {
							setPage(page);
						},
					}}
				/>
			</div> 
			
			 <DownloadReport openModal={modal}  setModal={setModal}   />

		</AuthLayout>
	</AdminRoute>
  );
};

export default Customer;
