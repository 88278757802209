// ts-ignore
import { Tabs } from "antd";
import AuthLayout from "comps/AuthLayout";


import   {   useState } from "react";


import CustomerTransaction from "./transactions";
import MerchantKYC from "./kyc";
import Merchant from "./merchant";
import { GoBack } from "comps/goBack";
import Customers from "./customers";
import Pricing from "./pricing";
import { AdminRoute } from "hoc/adminRoute";



 
const Customer = () => {
	const [activeKey, setActiveKey] = useState<any>("item-0");

	const settingsTabs = [
		{ label: "Merchant Detail", key: "item-0", children: <Merchant /> },
		{ label: "Customers", key: "item-1", children: <Customers /> },
		{ label: "Transactions", key: "item-2", children: <CustomerTransaction /> },
		{ label: "KYC", key: "item-4", children: <MerchantKYC /> },
		{ label: "Pricing", key: "item-6", children: <Pricing /> },
	];


	
	
return (
	<AdminRoute>

		<AuthLayout name="Merchants">
			<GoBack backLink="/merchants"/>


			<Tabs
				items={settingsTabs}
				activeKey={activeKey}
				onChange={(activeKey) => {
					setActiveKey(activeKey);
				}}
			/>
		</AuthLayout>
	</AdminRoute>
  );
};

export default Customer;
