
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import routesArray, { AdminRoutesArray } from "utils/routes";
import { logOut, objIsEmpty } from "utils/utilis";
 
import { LogOutIcon, MenuIcon } from "assets/svg-code";
import { useUserAuthContext } from "context/UserAuthContext";
import { useState } from "react";
import Logo from "../assets/images/SafePro logo.png"
import { useValidateToken } from "hooks/useValidateTokken";
import { useGetAdmin } from "hooks/useAuth";



const AuthLayout = (props: { children: React.ReactNode; name: string }) => {
	const { children, name } = props;
	const { pathname } = useLocation();
	const navigate = useNavigate();
	useValidateToken()
	const { KYC } = useUserAuthContext()
	const [miniDrawer, setMiniDrawer] = useState(false)
	const { isAdmin} = useGetAdmin()
	

 
  return (
	<>

		<div className="relative w-full bg-white min-h-screen m-0 p-0">
			{
				!objIsEmpty(KYC) || isAdmin ? null :
					KYC?.status === "APPROVED" ? null
					:<div className={` relative mr-0 ml-auto  top-0 right-0 p-3 l-[200px] w-[100%] py-5 px-8 md:w-[85%]`} 
						style={{backgroundColor:  KYC?.status === "PENDING" ? "rgb(204, 180, 5)" : "#FA4339"}}
					>
						<span className="Inter text-white font-normal text-base">
							{
								KYC?.status === "PENDING" ?
									" Your approval is pending. You will get an email notification upon validation" 
									:KYC?.status === "APPROVED" ? 
										null
										:"You will not be able to fund your wallet until you provide required documentation. "
							}
						</span>
						{
							KYC?.status === "PENDING"  || KYC?.status === "APPROVED"  ? null :
							<b className="Inter text-white font-semibold text-base p-2 cursor-pointer"  onClick={() => navigate("/settings",  { state: { activeKey: "item-3", },} )}>Click here to upload</b>
						}
					</div>
			}

			{/* mobile drawer */}
			{
				miniDrawer ?
					<div className=" block md:hidden   fixed bottom-0  top-0 w-[250px]  fixed bottom-0  top-0 w-[250px] bg-[#F0F7FC] z-20" >
						<Drawer pathname={pathname} />
					</div> 
					: null
			}
		
			{/* large scrren drawer */}
			<div className="hidden md:block fixed bottom-0  top-0 w-[15%] bg-[#F0F7FC]" >
				<Drawer pathname={pathname} />
			</div>
		

	

			<div className="m-5  flex justify-end cursor-pointer block md:hidden   ">
				<button onClick={() => setMiniDrawer(!miniDrawer)}><MenuIcon width={30} height={30} color="black"/></button>
			</div>


			<div className="relative w-full md:w-[85%] h-24 ml-auto text-black flex items-center md:items-end justify-between pr-8 border border-[#E6E7E8]">
				<h1 className="text-base md:text-3xl p-0 m-0 pb-3 pl-5">{name}</h1>
			</div>

			<div className="relative w-full md:w-[85%] p-5 ml-auto text-black layout">
				{children}
			</div>
		</div>
	</>
  );
};

export default AuthLayout;



const Drawer = ({pathname}: any) => {
	const isActive = (path: string) => path?.split("/")[1] ===  pathname?.split("/")[1];
	const {isAdmin } = useGetAdmin()
	return (
		<>
			<div className="w-full h-20"><img src={Logo} alt="logo" className="w-full h-full object-contain"/></div>
			<div className="h-1 bg-[#E6E7E8] w-full my-6" />
			<ul className="p-0 m-0 flex flex-col w-full box-border ">
				{
					(isAdmin ? AdminRoutesArray : routesArray).map((route) => {
						return (
							<li
								key={route.id}
								className={`flex box-border font-openSans mb-2 p-3 text-lightText  ${
								isActive(route.path)
									? " bg-primary border-l-[10px] border-black text-white"
									: "text-[#55595C] bg-transparent border-l-[10px] border-transparent"
								}`}
							>
								<NavLink
									to={route.path}
									className={`flex gap-x-2 w-full ${
										isActive(route.path) ? "text-white" : "text-[#55595C]"
									}`}
								>
									{isActive(route.path) ? (
										<img src={route.logolight} alt={route.name} />
									) : (
										<img src={route.logodark} alt={route.name} />
									)}

									{route.name}
								</NavLink>
							</li>
						);
					}
				)}
			</ul>

			<div className="absolute flex w-full justify-start pl-5 py-2 bottom-20">
				<button
					onClick={logOut}
					type="button"
					className="flex items-center gap-x-1 text-[#EA042E]"
				>
					<LogOutIcon />
					Log Out
				</button>
			</div>
		</>
	)
}