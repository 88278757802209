import React, { createContext, useContext, useState } from "react";

type ApiKeysEnvironmentContextProviderProps = {
	children: React.ReactNode;
};
type apikeysTypes = "live" | "test";
const authDetails: any = {
	data: "yes",
	userDetails: {},
	setUserDetails: () => {},
};
const ApiKeysEnvironmentContext = createContext(authDetails);

export const useApiKeysEnvironmentContext = () => {
	return useContext(ApiKeysEnvironmentContext);
};

const ApiKeysEnvironmentContextProvider = ({ children,}: ApiKeysEnvironmentContextProviderProps) => {
	const [apikeysEnvironment, setApiKeysEnvironment] = useState<apikeysTypes>("test");

 

	return (
		<ApiKeysEnvironmentContext.Provider value={{ apikeysEnvironment, setApiKeysEnvironment, }}>
		{children}
		</ApiKeysEnvironmentContext.Provider>
	);
};

export default ApiKeysEnvironmentContextProvider;
