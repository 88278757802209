import { yupResolver } from "@hookform/resolvers/yup";
import AuthRight from "comps/AuthRight";
import Button from "comps/Button";
import Input from "comps/Input";
import { useUserAuthContext } from "context/UserAuthContext";
import { useIsTokenValid,   } from "hooks/useValidateTokken";
import React, {   useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { login } from "services";
import { loginFormTypes } from "utils/types";
import { loginSchema } from "validations";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
	
			useIsTokenValid()
	const {  setUserDetails} = useUserAuthContext();

	

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<loginFormTypes>({
		defaultValues: {
		email: "",
		password: "",
		},
		mode: "onTouched",
		resolver: yupResolver(loginSchema),
	});
	const signInOnSubmit = async (data: loginFormTypes) => {
		const { email, password } = data;
		setLoading(true);

		const res = await login({ email, password });
		 
		setLoading(false);
		if (res?.status >= 200 && res?.status < 299) {
			setUserDetails(res?.data?.data);
			localStorage.setItem("authDetails", JSON.stringify(res?.data?.data));

			
			navigate( res?.data?.data?.user?.userId === process.env.REACT_APP_ADMIN_USER_ID   ? "/dashboard" :"/integration");
			return;
		} else {
			return toast.error(`Error: ${res?.data?.error}`, {
				duration: 3000,
			});
		}
	};
  return (
    <div className="text-primary w-full min-h-screen flex flex-col md:flex-row">
      <div className="bg-white p-5 w-full md:w-6/12 h-full">
        <div className="w-full md:w-10/12 py-20 mx-auto h-full">
          <h1 className="text-3xl mb-4 font-bold font-openSans">
            Welcome to SAFEPro
          </h1>
          <h3 className="text-base font-openSans">Log in to your account</h3>

          <form
            onSubmit={handleSubmit(signInOnSubmit)}
            className="w-full md:w-9/12 mt-10"
          >
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  type="email"
                  label="Email"
                  placeholder="Enter email address"
                  className="mb-5"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors?.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  type="password"
                  label="Password"
                  placeholder="Enter password"
                  className="mb-5"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors?.email?.message}
                />
              )}
            />

            <div className="flex justify-end text-primary text-sm">
              <NavLink to="/forgot-password">Forgot Password?</NavLink>
            </div>

            <div className="w-full mt-8">
              <Button type="submit">
                {loading ? "loading... " : "Log in"}
              </Button>
              <p className="text-center mt-2 text-safeblack">
                No account yet?{" "}
                <NavLink to="/signup" className="text-primary500">
                  Sign up
                </NavLink>
              </p>
            </div>

		
          </form>
        </div>
      </div>
      <AuthRight />
    </div>
  );
};

export default Login;
