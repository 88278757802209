

import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "antd";
import { EyeIcon, UploadIcon } from "assets/svg-code";
import Input from "comps/Input";
import { useUserAuthContext } from "context/UserAuthContext";
import Upload from "kodobe-react-upload";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"; 
import { acceoptSenderIdSchema,   } from "validations";
import toast, { LoaderIcon } from "react-hot-toast";
import ZoomSenderIdModal from "./zoomImg";


 
type SenderIdType = {
	senderId:  string,
	gloIntentLetter:  string,
	mtnIntentLetter:  string,
	"9mobileIntentLetter": string,
	"airtelIntentLetter": string,
  };
   
type PropType = {
	openModal:  {[e: string]: any, type: string},
	setModal: React.Dispatch<React.SetStateAction< {[e: string]: any, type: string}>>
	onDone: () => void
  };
   


const SenderIdModal: React.FC<PropType> = ({openModal, setModal, }) => {
 
 

	  const { control,   formState: { errors }, setValue ,   } = useForm<SenderIdType>({
		defaultValues: {
			senderId: "",
			gloIntentLetter: "",
			mtnIntentLetter: "",
			"9mobileIntentLetter": "",
			"airtelIntentLetter": "",
		},
		resolver: yupResolver(acceoptSenderIdSchema),
	  });

	  useEffect(() => {
		if(openModal) {
				setValue("senderId" ,  openModal["senderId"])
				setValue("gloIntentLetter" ,  openModal["gloIntentLetter"])
				setValue("mtnIntentLetter" ,  openModal["mtnIntentLetter"])
				setValue("9mobileIntentLetter",  openModal["9mobileIntentLetter"])
				setValue("airtelIntentLetter",  openModal["airtelIntentLetter"])
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [ openModal?.senderId, openModal?.gloIntentLetter , openModal?.mtnIntentLetter , openModal["9mobileIntentLetter"], openModal?.airtelIntentLetter  , setValue,  ])

	const handleFormSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		setModal({...openModal, type: "DECLINE_SENDERID"})
	}
 
	  

	return (
		<Modal
			open={openModal.type === "VIEW_DETAILS"}
			title="Create Sender ID"
			onCancel={() => setModal({type: ""})}
			cancelText={"Close"}
			cancelButtonProps={{style: {color: "red"}}}
			okText={''}
			okButtonProps={{ htmlType: "submit" }}
		>

			<span>The following are the details prodvided by the customer for sender ID..</span>
		 
		 <br />
		 <br />
			<form  onSubmit={handleFormSubmit}>
				<Controller
					control={control}
					name="senderId"
					render={({ field }) => (
						<Input
							label="Sender ID"
							placeholder="Enter your senderId"
							extraClass="mb-5"
							type="text"
							error={errors.senderId?.message}
							{...field}
						/>
					)}
				/>
				<Controller
					control={control}
					name="gloIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: true, iconUrl: "https://static-00.iconduck.com/assets.00/globacom-limited-icon-1024x1024-upx9lebq.png", name: "gloIntentLetter"}}/>}
				/>

				<div className="h-5"></div>
				<Controller
					control={control}
					name="mtnIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: true, iconUrl: "https://static-00.iconduck.com/assets.00/mtn-icon-2048x2048-b2l264nq.png", name: "mtnIntentLetter"}}/>}
				/>

				<div className="h-5"></div>
				<Controller
					control={control}
					name="9mobileIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: true, iconUrl: "https://static-00.iconduck.com/assets.00/9mobile-icon-295x512-n7s4ssgv.png", name: "9mobileIntentLetter"}}/>}
				/>

				<div className="h-5"></div>

				<Controller
					control={control}
					name="airtelIntentLetter"
					render={({ field }) => <InputComponent {...{errors, field, viewOnluy: true, iconUrl: "https://seeklogo.com/images/A/airtel-logo-2F959F4207-seeklogo.com.png", name: "airtelIntentLetter"}}/>}
				/>


				<div className="flex justify-end">
					<button
						type="submit"
						className={`  p-2 rounded mt-3 appearance-none  mr-0`}
						style={{backgroundColor: "red", color:"white"}}
					>
						Decline Sender ID
					</button>
					<button
						type="button"
						className={`  p-2 rounded mt-3 appearance-none  ml-4`}
						style={{backgroundColor: "#1890ff", color:"white"}}
						onClick={() => setModal({...openModal, type: "ACCEOPT_SENDERID"})}
					>
						Approve Sender ID
					</button>
				</div>
			</form>
		</Modal>

  );
};

export default SenderIdModal;


export const InputComponent =  ({errors, field, iconUrl, name,  viewOnluy}: any) => {
	const { userDetails: {  token,},} = useUserAuthContext();
	const [loading, setLoading] = useState(false)
	const [modal, setModal] = useState<{iconUrl?: string, type: string}>({type: ""})


	return (
		<div>
			<label className="text-[#564e4e] text-xs">IntentLetter for { name && name?.split("IntentLetter")?.[0]}</label>
			<div className="flex border border-#e5e7eb border-solid p-2 rounded-lg justify-between  mt-1">
				<div className="flex items-center">
					{
						viewOnluy ? 
							<button className="p-2  rounded-full" style={{backgroundColor: "#184e77"}} 
								onClick={() => setModal({type: "ZOOM_SENDERID", iconUrl: field.value})}
								type="button"
							>
								<EyeIcon />
							</button>
							:
							<div className="relative rounded-md flex items-center justify-center overflow-hidden  h-9 w-9 inputImgType" style={{backgroundColor: "#184e77"}} >
								{ loading ? <LoaderIcon color="white"   /> : UploadIcon}
								<Upload
									validImageTypesSrc={["jpg", "jpeg", "png", "pdf"]}
									uploadUrl={process.env.REACT_APP_UPLOAD_URL}
									fileName="file"
									title={"Upload a logo"}
									onError={(e) =>  e && toast.error( e )}
									onChange={(e) => {
										setLoading(true)
										if (!e.completed) {
										} else if (e.completed) {
											setLoading(false)
											field.onChange(  e?.responseFromServer?.data?.url );
										}
									}}
									headers={{ token: token, "client-id": process.env.REACT_APP_CLIENT_ID}}
								/>
							</div>
					}
					<div className="flex pl-2 items-center">
						{field.value ? 
							<div className=" h-9 w-9 rounded-md overflow-hidden mr-2 ">
								<img src={field.value} alt="Upload" className="object-cover w-full h-full"/>
							</div>
							: null}
						<span className="f-12 text-slate-500 text-xs">{field.value ? field.value?.split("/")[field?.value.split("/")?.length-1] : "Select file to upload"}</span>
					</div>
				</div>
					

				<div className=" h-9 w-9 rounded-md overflow-hidden">
					<img src={iconUrl} alt="airtel logo" className="object-cover w-full h-full"/>
				</div>
			</div>
			{
				errors[name]?.message ? <span>{errors[name]?.message}</span> : null
			}

			<ZoomSenderIdModal openModal={modal}  setModal={setModal} />
		</div>
	)
}