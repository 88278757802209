import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "pages/Login";
import SignUp from "pages/SignUp";
import Onboard from "pages/Onboard";
import ForgotPassword from "pages/FogotPassword";
import ResetPassword from "pages/ResetPassword";
import Otp from "pages/Otp";
import Integration from "pages/Integration";
import Dashboard from "pages/Dashboard";
import Transaction from "pages/Transaction";
import CustomerTransaction from "pages/Customer/transactions";
import CustomerKYC from "pages/Merchants/kyc";
import Customer from "pages/Customer";
import KYC from "pages/KYC";
import MerchantsCustomer from "pages/Customer";
import Merchants from "pages/Merchants";
import SingleMerchant from "pages/Merchant";
import Settings from "pages/Settings";
import Wallet from "pages/Wallet";
import UserAuthContextProvider from "context/UserAuthContext";
import ApiKeysEnvironmentContextProvider from "context/ApiKeysEnvironment";
import "./App.css";
import GetCode from "pages/GetCode";
import InputCode from "pages/InputCode";
import ViewMore from "pages/ViewMore";
import ProtectedRoutes from "comps/ProtectedRoutes";
import SenderId from "pages/SenderId";

function App() {
  return (
    <UserAuthContextProvider>
      <ApiKeysEnvironmentContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SignUp />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/onboarding" element={<Onboard />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-otp" element={<Otp />} />
            <Route path="/:safeproClientId/get-code" element={<GetCode />} />
            <Route
              path="/:safeproClientId/redirect-otp"
              element={<InputCode />}
            />

            <Route element={<ProtectedRoutes />}>
              <Route path="/integration" element={<Integration />} />
              <Route path="/view-more" element={<ViewMore />} />
              <Route path="/dashboard" element={<Dashboard />} />
              {/* <Route path="/transaction" element={<Transaction />} /> */}
              <Route path="/customers/:phoneNumber/transactions" element={<CustomerTransaction />} />
              <Route path="/customers" element={<Customer />} />
              <Route path="/kyc" element={<KYC />} />
              <Route path="/merchants/:userId" element={<MerchantsCustomer />} />

              <Route path="/transactions" element={<Transaction />} />

              <Route path="/merchants" element={<Merchants />} />
              <Route path="/merchants/merchant/:userId" element={<SingleMerchant />} />
              <Route path="/kyc/:userId" element={<CustomerKYC />} />

              <Route path="/settings" element={<Settings />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/senderId" element={<SenderId />} />
            </Route>
          </Routes>
        </Router>
        <Toaster />
      </ApiKeysEnvironmentContextProvider>
    </UserAuthContextProvider>
  );
}

export default App;
