import React from "react";
import safeprogif from "assets/images/safegif.gif";

const AuthGif = () => {
  return (
    <img
      src={safeprogif}
      alt="safe pro gif"
      style={{ width: "600px", height: "500px", marginTop: "100px" }}
    />
  );
};

export default AuthGif;
