import React from "react";

const FormError = (props: any) => {
  const { error, className } = props;
  return (
    <div className={`text-red-500 text-xs font-display ${className}`}>
      {error}
    </div>
  );
};

export default FormError;
