import { yupResolver } from "@hookform/resolvers/yup";
import AuthRight from "comps/AuthRight";
import Button from "comps/Button";
import OTPInput from "comps/OTP";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { verfiyOtp, verifyAccount } from "services";
import { accountVerificationSchema } from "validations";

type verifyInputProps = {
  otp: "";
  email: "";
  sendWelcomeEmail: boolean;
};
const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [resendloading, setResendLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
  } = useForm<verifyInputProps>({
    reValidateMode: "onBlur",
    defaultValues: {
      otp: "",
      email: location?.state?.email || "",
    },
    mode: "onTouched",
    resolver: yupResolver(accountVerificationSchema),
  });

	const handleAccountVerification = async (data: verifyInputProps) => {
		if(data?.otp?.length !== 4) return;
		setLoading(true);
		const res = await verfiyOtp({
			value: location?.state?.email,
			code: `${data.otp}`,
			sendWelcomeEmail: true
		});
		setLoading(false);
		if (res.status >= 200 && res.status <= 299) {
		toast.success(`Success: Account Verified!!`, {
			duration: 3000,
		});
		navigate("/onboarding", {
			replace: true,
			state: {
			email: location?.state?.email,
			},
		});
		} else {
			toast.error(`Error: ${res?.data?.error}`, { duration: 3000,});
		}
	};

	const resendOTPCode = async () => {
		if (resendloading) return;
		setResendLoading(true);
		const res = await verifyAccount(location?.state?.email);
		setResendLoading(false);
		if (!res.error) {
			toast.success("verification code as been sent to your email", {
				duration: 3000,
			});
		} else {
			toast.error("error", { duration: 3000,});
		}
	};

  return (
    <div className="text-primary w-full min-h-screen flex  flex-col md:flex-row">
      <div className="bg-white p-5 w-full md:w-6/12 h-full">
        <div className="w-full md:w-10/12 py-20 mx-auto h-full">
          <h1 className="text-3xl mb-4 font-bold font-openSans">
            OTP Verification
          </h1>
          <h3 className="text-base font-openSans">
            To continue, please enter the code received on <br />{" "}
            {location?.state?.email}
          </h3>

          <form
            onSubmit={handleSubmit(handleAccountVerification )}
            className="w-11/12 md:w-9/12 mt-10"
          >
            <div>
				<Controller
					control={control}
					name="otp"
					render={({ field: { onChange } }) => (
						<OTPInput
							autoFocus
							length={4}
							inputClassName="otpInput"
							onChangeOTP={onChange}
						/>
					)}
				/>
            </div>

            <div className="w-full mt-8 ">
              Didn't receive code?{" "}
              <button
                onClick={() => resendOTPCode()}
                className="appearance-none cursor-pointer font-semibold"
              >
                Resend
              </button>
            </div>

            <div className="w-full mt-8">
              <Button type="submit" className="w-[120px]">
                {loading ? "Verify ..." : "Verify"}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <AuthRight />
    </div>
  );
};

export default Otp;
