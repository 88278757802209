



import CustomTable from "comps/CustomTable";
import {   useGetSenderIds } from "hooks/useSederId";
import moment from "moment";
import React, { useState } from "react";
import AuthLayout from "comps/AuthLayout";
import { SearchIcon } from "assets/svg-code";
import SenderIdModal from "./senderIdModal";
import ApproveSenderIdModal from "./approveSenderId";
import DeclineSenderIdModal from "./declineSender";
import { AdminRoute } from "hoc/adminRoute";


 


const SenderId: React.FC = (props) => {
	const [filterBySenderId, setFilterBySenderId]   = useState("")
	const [openModal, setModal]  = useState({type: ""})

  const { loading, senderIds, pageInfo, setPageInfo , handleGetSenderIds  } = useGetSenderIds(filterBySenderId)


  

	const sampleCustomers: any = {
		total: senderIds?.length,
		data:   senderIds?.map((senderId: any) => (
			{
				sn: "S/N",
				key: senderId?.id,
				date: moment(senderId?.createdAt).format("DD/MM/YYYY  h:mma"),
				requester:  senderId?.businessName,
				senderId:  senderId?.senderId,
				status: senderId?.status,
				action: senderId
			}
		 ))
	 
	  };


	  const columns = [
		{
		  title: "S.N",
		  index: "sn",
		  dataIndex: "s/n",
		  render: (text: string, record: {}, index: number) => <div>{index + 1}</div>,
		},
		{
		  title: <div>Date</div>,
		  render: (record: any) => <div>{record?.date}</div>,
		},
		{
		  title: <div> Requester</div>,
		  render: (record: any) =>  <div>{record?.requester || "-"}</div>,
		},
		{
		  title: <div>Sender ID  Requested</div>,
		  render: (record: any) =>  <div>{record?.senderId}</div>,
		},
		{
		  title: "Status",
		  render: (record: any) => {
			return <div >
				<span  
					className={"px-5 py-2 text-xs"}  
					style={{
						backgroundColor: record?.status?.toUpperCase() === "PENDING" ?  "#c2dbf3"  : record?.status === "DECLINED" ?  "#f2a5a5" :   record?.status?.toUpperCase() === "APPROVED" ?  "#c5f4c5" : "#ffd6d6", 
						color: record?.status === "PENDING" ?  "#0a998f" : record?.status === "APPROVED" ? "#1aa11a": record?.status === "DECLINED" ?  "#ab0909"  : "#ab0909"  
					}}
					>
					{record?.status}
				</span>
			</div>;
		  },
		},
		{
		  title: "Action",
		  render: (record: any) => {
			return <div >
				<span  className={"px-5 py-2 text-xs"}  style={{border:   "1px solid #424242", color:   "#666565", cursor: "pointer"  }}
					onClick={() => setModal({...record?.action, type: "VIEW_DETAILS"})}
				>
					View Details
				</span>
			</div>;
		  },
		},
	 
	 
	  ];
 

  return (
	<AdminRoute>

		<AuthLayout  name="Sender ID">

			<div className="py-5 lg:gap-6 xl:gap-6 flex  grid-cols-1 flex-wrap md:grid-cols-2">
				<div  className="flex-grow-0   w-auto  grow max-w-xs flex items-center border  border-[#D5D7D8] bg-[#FEFDFD]  rounded-lg  gap-x-2 px-2">
						<SearchIcon /> 
						<input
							className="border h-12 text-sm focus:outline-none block w-full   border-transparent p-2  "
							style={{cursor: "auto"}}
							type="search"
							name="search"
							onChange={(e) => setFilterBySenderId(e.target.value)}
							placeholder={  "Search by sender ID"  }
						/>
				</div>
				
			</div>

	 

			<div className="mt-10 w-full">
				<CustomTable
					tableName="Customers"
					dataSource={sampleCustomers.data}
					loading={loading}
					columns={columns}
					pagination={{
						pageSize: pageInfo.limit,
						total: sampleCustomers.total,
						onChange: (page: any) => {
							setPageInfo({...pageInfo, page});
						},
					}}
				/>
			</div>
			<SenderIdModal openModal={openModal}  setModal={setModal} onDone={()=> console.log("done")}/>
			<ApproveSenderIdModal openModal={openModal}  setModal={setModal} onDone={()=> handleGetSenderIds()}/>
			<DeclineSenderIdModal openModal={openModal}  setModal={setModal} onDone={()=>handleGetSenderIds()}/>
    </AuthLayout>
	</AdminRoute>
  );
};

export default SenderId;
