// ts-ignore
import { SearchIcon,   } from "assets/svg-code";
import CustomTable from "comps/CustomTable";
import { useUserAuthContext } from "context/UserAuthContext";
import { useGetCustomers } from "hooks/useCustomers";
import moment from "moment";
import  { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


import * as _ from "underscore";

 
const Customers = () => {
 
	const { userDetails: {  user: { userId }, }, } = useUserAuthContext();
	const { handleGetCustomers, customers, loading } = useGetCustomers()
	const [ready, setReady] = useState(false)
	const [filter, setFIlter] = useState("")
	const { userId: merchantUserId} = useParams()
	const optimisedTriggerHandler = _.throttle((e: any) => handleGetCustomers( merchantUserId || userId, e ? `${filter}=${e}` : ""), 2000, { leading: false });
	const navigate = useNavigate();



	
	const [ , setPage] = useState<number>(1);

	
	const columns = [
		{
			title: "S.N",
			index: "sn",
			dataIndex: "s/n",
			// key:  "sn",
			render: (text: string, record: {}, index: number) => <div>{index + 1}</div>
		},
		{
			key:  "createdAt",
			title: <div>Date Registered</div>,
			render: (record: any) => <> {moment(record?.createdAt).format("DD/MM/YYYY")}</>
		},
		{
			key:  "phoneNumber",
			title: <div>Phone Number</div>,
			render: (record: any) => <div className="">{record?.phoneNumber}</div>,
		},
		{
			key:  "transactionHistory",
			title: <div>History</div>,
			render: (record: any) => <div>{typeof(record?.count) === "number" ? record?.count : "-" }</div>,
		},
		merchantUserId ? {} :
		{
			key:  "transactions",
			title: <div>Action</div>,
			render: (phone: any) => <div 
				className="py-3 rounded-sm text-sm px-1 flex justify-center cursor-pointer text-white bg-[#184E77]"
				onClick={() => navigate(`/customers/${phone?.phoneNumber}/transactions` )}
				>  Transactions  </div>,
		},
	];

 

	useEffect(() => {
		setReady(true)
		ready && handleGetCustomers (  merchantUserId ||userId, "");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ready]);
 


	
	
return (
    <div>

		<div className="py-3 lg:gap-6 xl:gap-6 flex grid-cols-1 flex-wrap md:grid-cols-2">
			<div>
				<span className="">Filter by</span>
			</div>
			<select className="px-5 py-2 mx-4  rounded bg-transparent border border-slate-300 border-solid"  onChange={(e) => setFIlter(e.target.value)}>
				<option value={""}>Select</option>
				<option value={"phoneNumber"}>Phone number</option>
				<option value={"customerId"}>Customer ID</option>
			</select>
               

			<div  className="flex-grow-0   w-auto  grow max-w-xs flex items-center border  border-[#D5D7D8] bg-[#FEFDFD]  rounded-lg  gap-x-2 px-2">
					<SearchIcon /> 
					<input
						className="border h-12 text-sm focus:outline-none block w-full   border-transparent p-2  "
						style={{cursor: !filter ? "not-allowed": "auto"}}
						type="search"
						name="search"
						disabled={!filter}
						onChange={(e) => optimisedTriggerHandler(e.target.value)}
						placeholder={!filter ? "Select a filter option" :`Search by ${filter}`}
					/>
            </div>
		</div>


		<div className="mt-10 w-full ">
			<CustomTable
				tableName="Customers"
				dataSource={customers?.docs?.map((data: {[id: string]: string}) => ({...data, key: data?.id}))}
				loading={loading}
				columns={columns}
				pagination={{
					pageSize: 10,
					total: customers?.total,
					onChange: (page: any) => {
						setPage(page);
					},
				}}
			/>
		</div>

    </div>
  );
};

export default Customers;
