 
import AuthLayout from "comps/AuthLayout";
import Card from "comps/Card";
import { useState,   } from "react";
import {  integrationTypes2, integrationTypesData } from "utils/utilis";
import { StepType } from "utils/types";
import Layout from "./comps/Layout";
import    "../../App.css";
import Redirect from "./comps/redirect"
import Direct from "./comps/direct";
import KYC from "./comps/kyc";
import TwoWay from "./comps/2way";
import KYC_BVN from "./comps/kyc-bvn";
import KYC_NIN from "./comps/kyc_nin";
import { useGetIntegrateAmounts } from "services/auths";
import DeviceAuth from "./comps/deviceAuth";
import TwoWaySMS from "./comps/2way-sms";


const Integration = () => {
	const [step, setStep] = useState<StepType>("inactive");

	const { amount } = useGetIntegrateAmounts();
 
	 

	const handleStep = async (args: StepType) => {
		setStep(args);
	};

	
	const goBack = (args: StepType) =>  setStep(args);
 
  const generateStep = {
    "inactive": <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 w-full">
          {integrationTypes2(amount).map((integration, idx) =>   <Card {...integration} key={idx} handleStep={handleStep}  /> )}
        </div>,
	"redirect": <Layout type="redirect"
		sidebar={ <Redirect type="redirect" title={integrationTypesData(step)?.title!} description={integrationTypesData(step)?.description!} goBack={goBack}/>}
		/>,
	"direct": <Layout type="direct"
			sidebar={
				<Direct type="direct" title={integrationTypesData(step)?.title!} description={integrationTypesData(step)?.description!} goBack={goBack}/>
			}/>,
	"kyc": <Layout type="kyc"
		sidebar={<KYC  type="kyc"  title={integrationTypesData(step)?.title!}  description={integrationTypesData(step)?.description!}  goBack={goBack}/>}
		/>,
	"kyc_bvn": <Layout type="kyc" sidebar={<KYC_BVN    title={"BVN"}  goBack={goBack}/>}/>,
	"kyc_nin": <Layout type="kyc" sidebar={<KYC_NIN   title={"NIN"} goBack={goBack}/>}/>,
	"2-way": <Layout type="2-way"
				sidebar={<TwoWay  type="2-way"  title={integrationTypesData("2-way")?.title!}  description={integrationTypesData("2-way")?.description!}  goBack={goBack}/>}
			/>,
	"2-way-sms": <Layout type="2-way-sms"
				sidebar={<TwoWaySMS  type="2-way-sms"  title={integrationTypesData("2-way-sms")?.title!}  description={integrationTypesData("2-way-sms")?.description!}  goBack={goBack}/>}
			/>,
	"2-way-telegram": <Layout type="2-way-telegram"
				sidebar={<TwoWaySMS  type="2-way-sms"  title={integrationTypesData("2-way-telegram")?.title!}  description={integrationTypesData("2-way-telegram")?.description!}  goBack={goBack}/>}
			/>,
	"DeviceAuth": <Layout type="DeviceAuth"
				sidebar={<DeviceAuth  type="DeviceAuth"  title={integrationTypesData("DeviceAuth")?.title!}  description={integrationTypesData("DeviceAuth")?.description!}  goBack={goBack}/>}
			/>
	};

	return (
		<AuthLayout name="Integration">
			{step === "inactive" && (
				<div className="w-full flex flex-col md:flex-row justify-between font-karla mb-6">
					<span className="general-sans-light font-normal text-xl" style={{color: " #2B2D2E"}}>
						You can use any of these 4 methods to go through your OTP process 
					</span>
				</div>
			)}
			<section>
				{generateStep[step]}
			</section>

		</AuthLayout>
	);
};

export default Integration;
