import toast from "react-hot-toast";

import { login } from "services";
import {

	onBoardingDataTypes,
  loginFormTypes,
  StepType,
} from "./types";

export const logOut = () => {
  localStorage.clear();
  window.location.href = "/login";
};

export const handleCopy = (payload: any) => {
  navigator.clipboard.writeText(
    process.env.NODE_ENV !== "production" ? `${payload}` : `${payload}`
  );
  toast.success(`Unique Url Copied!`);
};

export const handleOnboardingSubmit = async (data: onBoardingDataTypes) => {
 
};

export const signInOnSubmit = async (data: loginFormTypes) => {
  const { email, password } = data;
	await login({ email, password });
};

interface deals {
  title: string;
  description: string;
  moreDescription?: string;
  type: StepType;
}
export const integrationTypes2 : (e: any) =>deals[]  =  (amount) => (
	[
		{
		  title: "OTP Redirect",
		  description:
			"After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.",
		  type: "redirect",
		  amount: amount?.find((item: any) => item.providerName === "redirect-ng")?.amount || 0
		},
		{
		  title: "OTP Direct",
		  description:
			"After initiating OTP Direct, Your customers receives an SMS with an OTP and your system also receives the same OTP for validation at your end.",
		  type: "direct",
		  amount: amount?.find((item: any) => item.providerName === "otp-direct-ng")?.amount || 0
		},
		{
		  title: "2-Way Messaging",
		  description:
			"After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.",
		  moreDescription: "(SMS,Whatsapp,Telegram)",
		  type: "2-way",
		  amount:  amount?.find((item: any) => item.providerName === "2-way-sms-ng")?.amount || 0
		},
		{
		  title: "KYC Verification",
		  description:
			"After initiating KYC Verification, you will be required to provide some details to get started",
		  type: "kyc",
		  amount:  amount?.find((item: any) => item.providerName === "kyc-nin")?.amount || 0
		},
		{
		  title: "DeviceAuth",
		  description:
			"After initiating DeviceAuth Verification, you will be required to provide some details to get started",
		  type: "DeviceAuth",
		  amount:  amount?.find((item: any) => item.providerName === "DeviceAuth")?.amount || 0
		},
	]
);
export const integrationTypes: deals[] = [
  {
    title: "OTP Redirect",
    description:
      "After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.",
    type: "redirect",
  },
  {
    title: "OTP Direct",
    description:
      "After initiating OTP Direct, Your customers receives an SMS with an OTP and your system also receives the same OTP for validation at your end.",
    type: "direct",
  },
  {
    title: "2-Way Messaging",
    description:
      "After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.",
    moreDescription: "(SMS,Whatsapp,Telegram)",
    type: "2-way",
  },
  {
    title: "2-Way SMS",
    description:
      "After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.",
    moreDescription: "(SMS,Whatsapp,Telegram)",
    type: "2-way-sms",
  },
  {
    title: "2-Way Telegram",
    description:
      "After initiating OTP Redirect, Your customers will be redirected to a URL generated to you.",
    moreDescription: "(SMS,Whatsapp,Telegram)",
    type: "2-way-telegram",
  },
  {
    title: "KYC Verification",
    description:
      "After initiating KYC Verification, you will be required to provide some details to get started",
    type: "kyc",
  },
  {
    title: "DeviceAuth",
    description:
      "After initiating DeviceAuth Verification, you will be required to provide some details to get started",
    type: "DeviceAuth",
  },
];

export const integrationTypesData = (args: StepType) => {
  return integrationTypes.find((item) => item.type === args);
};

export const sampleCodes = (type: string) => {
  switch (type) {
    case "kyc":
      return {
        request: {
          answer: `curl --https://padf-staging-direct.dilaac.com --request POST 'https://padf-staging.dilaac.com/otp-middleware/api/v1/kyc' \
          --header 'clientId: staging_otp' \
          --data-raw '{
              "userId": "631a00e9e4870dc6790da890",
              "businessId": "631a00e9e4870hfh773da890",
              "businessType": "sole Propietorship",
              "businessDesc": "business description",
              "regNumber": "000000233",
              "address": "13 Mason Str",
              "landmark": "Obawole",
              "city": "ikeja",
              "country": "Nigeria",
              "phoneNumber": "90920938829",
              "email": "admind@testing.com",
              "website": "https://google.com",
              "ubo": "Ultimate business Owner",
              "coi": "https://cloudinary/image",
              "memArt": "https://cloudinary/image",
              "dp":"https://cloudinary/image",
              "poba": "https://cloudinary/image"
          }'`,
        },
        response: {
          answer: `{
            "status": 200,
            "message": "Kyc Registered successfully",
            "data": {
              "userId": "631a00e9e4870dc6790da890",
              "__v": 0,
              "address": "13 Mason Str",
              "businessDesc": "business description",
              "businessId": "631a00e9e4870hfh773da890",
              "businessType": "sole Propietorship",
              "city": "ikeja",
              "coi": "https://cloudinary/image",
              "country": "Nigeria",
              "createdAt": "2022-10-12T20:26:44.885Z",
              "dp": "https://cloudinary/image",
              "email": "admind@testing.com",
              "landmark": "Obawole",
              "memArt": "https://cloudinary/image",
              "meta": {
                "requestBody": {
                  "userId": "631a00e9e4870dc6790da890",
                  "businessId": "631a00e9e4870hfh773da890",
                  "businessType": "sole Propietorship",
                  "businessDesc": "business description",
                  "regNumber": "000000233",
                  "address": "13 Mason Str",
                  "landmark": "Obawole",
                  "city": "ikeja",
                  "country": "Nigeria",
                  "phoneNumber": "90920938829",
                  "email": "admind@testing.com",
                  "website": "https://google.com",
                  "ubo": "Ultimate business Owner",
                  "coi": "https://cloudinary/image",
                  "memArt": "https://cloudinary/image",
                  "dp": "https://cloudinary/image",
                  "poba": "https://cloudinary/image"
                }
              },
              "phoneNumber": "90920938829",
              "poba": "https://cloudinary/image",
              "regNumber": "000000233",
              "status": "PENDING",
              "ubo": "Ultimate business Owner",
              "updatedAt": "2022-10-12T20:26:44.885Z",
              "website": "https://google.com",
              "id": "634723048820cacdfc82c93b"
            }
          }`,
        },
      };

	  
    default:
      break;
  }
};


export const objIsEmpty = (obj: object) =>{
	if(typeof(obj) !== "object") return true;
	return  Object.keys(obj).length === 0;
};