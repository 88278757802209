
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "comps/Input";
import { useLocation,  useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { middlewareVerify, getUser } from "services";
import { yupResolver } from "@hookform/resolvers/yup";
import { verfiyOtpCodeSchema } from "validations";

const InputCode = () => {

	
  const { safeproClientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState<any>({});

  const location = useLocation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ otp: string }>({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(verfiyOtpCodeSchema),
  });

  const verifyOtpCode = async (data: any) => {
    if (!data.otp) return toast.error("Otp is not provided");

    const body = {
      reference: location?.state?.reference,
      phoneNumber: location?.state?.phone,
      code: data?.otp,
    };
    setLoading(true);
    const res = await middlewareVerify(body);
    setLoading(false);
    if (res.status >= 200 && res.status <= 299) {
      toast.success("token verified");
      window.location.href = `${location?.state?.redirectUrl}?phone=${location?.state?.phone}&reference=${location?.state?.reference}`;
    } else {
      toast.error(`${res.data.message || "OTP Code incorrect"}`);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      const res = await getUser(safeproClientId!);
      setClientData(res.data.data);
    };
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  return (
    <div className="bg-white w-full min-h-screen">

      <div className="w-11/12 h-36"></div>

      <form
        onSubmit={handleSubmit(verifyOtpCode)}
        className="bg-white mb-40 flex flex-col py-4 justify-between items-center get-code-body rounded-lg shadow-lg border border-[#E6E7E8]  w-11/12 md:w-5/12 p-4 mx-auto min-h-[317px]"
      >
        <div className="flex flex-col">
			<div className="flex justify-center items-center gap-x-2 mb-4 flex-col">
				<div className="w-20 h-20">
					{clientData?.profile?.businessLogo && (
						<img
							className="w-full h-full object-cover"
							src={clientData?.profile?.businessLogo}
							alt="company a logo"
						/>
					)}
				</div>
			</div>
			<p className="text-center">
				Enter the OTP Code sent to{" "}
				{location?.state?.phone || "+234 708 983 3412"}
			</p>
        </div>

        <Controller
          name="otp"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label="OTP Code"
              type="text"
              name="otp"
              id="otp"
              placeholder="Enter OTP Code"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              className="w-full"
			  style={{borderColor:  clientData?.profile?.businessMainColor}}
              extraClass="w-11/12 md:w-8/12"
              error={errors.otp?.message}
            />
          )}
        />

        <button
          type="submit"
         
		  className={`font-bold w-[120px] p-4 rounded mt-9 `}
          disabled={loading}
		  style={{backgroundColor: clientData?.profile?.businessMainColor, color: clientData?.profile?.businessSecondaryColor}}

        >
          {loading ? "Submit ..." : "Submit"}
        </button>

		<span className="font-bold text-[#1A1B1C] general-sans-light md:text-[20px] text-center  text-xs  mt-8" style={{fontSize: "10px", lineHeight: "14px"}}>
				Powered by SAFEPro @ 2022
			</span>

      </form>
 
    </div>
  );
};

export default InputCode;
