import   { safeproMiddleWare2 } from "axiosApi";
import { useUserAuthContext } from "context/UserAuthContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { handleRequestError } from "utils";





export const useGetSenderIds =   (filterBySenderId: string, status?: string) => {
	const [senderIds, setSenderIds] = useState([]);
	const [loading, setLoading] = useState(false);

	
	const [pageInfo, setPageInfo]: any = useState({
        number: 1,
        limit: 10
    });

	const handleGetSenderIds = async () => {
		setLoading(true)
		try {
		  const res = await safeproMiddleWare2.get(`/senderId?limit=${pageInfo?.limit}&page=${pageInfo?.number}${filterBySenderId ?` &senderId=${filterBySenderId}` : ""}${status ? "&status="+status : ""}`, 
		  {
			headers: { "client-id": process.env.REACT_APP_CLIENT_ID,},
		  })

		  setLoading(false)
		  if(res) {
			setSenderIds(res?.data?.data?.docs);
			setPageInfo((prev: any) => ({...prev, ...res?.data?.page}))
		  }
		  return res
		} catch (error) {
			setLoading(false)
		  return handleRequestError(error);
		}
	}
	useEffect(() => {
		handleGetSenderIds();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterBySenderId]);
	return { handleGetSenderIds , loading, senderIds, pageInfo, setPageInfo}
}
 


export const useCreateSenderId = ( ) => {
	const [loading, setLoading] = useState(false);
	const { userDetails: {    token, user: { userId }, }} = useUserAuthContext();


	const handleCreateSenderId = async  (DATA: object) => {
		setLoading(true)
		try {
			const res = await  safeproMiddleWare2.post(
			  `/senderId`,
			  { userId, ...DATA },
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  Authorization: "Bearer " + token,
				},
			  }
			);
			toast.success("Sender ID create successfully", { duration: 5000 });
			setLoading(false)
			return {data: res};
		  } catch (error) {
			setLoading(false)
			toast.error( `Error: Something went wrong ` );
			return handleRequestError(error);
		  }
	}

	return { handleCreateSenderId, loading }
  };
  


export const useAcceptorDeclineSenderID = (senderId: string, type?: string ) => {
	const [loading, setLoading] = useState(false);
	const { userDetails: {    token,  }} = useUserAuthContext();


	const handleAccceptOrDeclienSenderID = async  (DATA: object) => {
		setLoading(true)
		try {
			const res = await  safeproMiddleWare2.put(
			  `/senderId/${senderId}`,
			  {   ...DATA },
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  Authorization: "Bearer " + token,
				},
			  }
			);
			toast.success(`Sender ID ${type || "updated"} successfully`, { duration: 5000 });
			setLoading(false)
			return {data: res};
		  } catch (error) {
			setLoading(false)
			toast.error( `Error: Something went wrong ` );
			// return handleRequestError(error);
		  }
	}

	return { handleAccceptOrDeclienSenderID, loading }
  };
  