import React from "react";

type BtnProps = {
  type: "button" | "submit" | "reset";
  className?: string;
  btnText?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  variant?: string;
  disabled?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};
const Button = (props: BtnProps) => {
  const {
    type,
    children,
    btnText,
    className,
    onClick,
    variant,
    disabled,
    ...rest
  } = props;
  const myClassName = variant === "primary" ? `primary` : `hover`;
  return (
    <button
      type={type}
      onClick={onClick}
      className={`rounded-md p-2 disabled:cursor-not-allowed ${myClassName} ${className}`}
      disabled={disabled}
      {...rest}
    >
      {btnText} {children}
    </button>
  );
};

export default Button;
