import { yupResolver } from "@hookform/resolvers/yup";
import {   Modal } from "antd"
import { Controller, useForm } from "react-hook-form";
import { TopUpSchema } from "validations";
import Input from "comps/Input";
import { useTopUpWallet } from "services/auths";





  const TopUpModal = ({loginModal, setLoginModal, handlegetWallet }: any) => {

	const { handleTopUptWallet, loading } = useTopUpWallet()
	const { control, handleSubmit, setValue, formState: { errors } } = useForm({
		defaultValues: { amount: "", },
		mode: "onTouched",
		resolver: yupResolver(TopUpSchema),
	  });

	const onSubmit = async (e: any) => {
		const result = await handleTopUptWallet(e)
		if(result?.data) {
			setValue("amount",  "");
			setLoginModal(false);
			handlegetWallet()
		}
	}
	
	return (
		<Modal
			open={loginModal}
			title="Fund Wallet"
			onCancel={() => setLoginModal(!loginModal)}
			okText="Pay Now"
			okButtonProps={{ htmlType: "submit" }}
			onOk={handleSubmit(onSubmit,  (e) => console.log(e))}
			confirmLoading={loading}
		>
			<form onSubmit={handleSubmit(onSubmit, (e) => console.log(e))}>
				<div className="flex flex-col gap-y-5">
					<Controller
						name="amount"
						control={control}
						render={({ field }) => (
							<Input
								{...field}
								label="Amount"
								type="number"
								placeholder="Enter amount"
								extraClass="w-full"
								error={errors.amount?.message}
							/>
						)}
					/>

				</div>
			</form>
		</Modal>
	)
}

export default TopUpModal