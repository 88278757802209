import { toast } from "react-hot-toast";



export const extractQueryString = (objt: object) => {
	let result = '';
	const names = ["from", "to"]
	  const keys = Object.keys(objt)
	  Object.values(objt).map((item, i) => {
		  if(typeof (item) === "string") {
			result += `${keys[i]}=${item}${i + 1 === Object.values(objt).length ? "" : "&"}`;
		  } else {
			item.map((i: string, idx: number) => result += `&${names[idx]}=${i}`)
		  }
		  return result
	  })
	  return result
  }

  export const  removeEmptyValuesFromObj = (obj: any) => {
	for (const key in obj) {
		if (obj[key] === "" || obj[key].length === 0) {
			delete obj[key];
		}
	}
	return obj;
};



export function handleRequestError(error:any) {
	if (error.toString() === "Error: Network Error") {
	  return toast.error("Try again: Internet connectivity", {
		duration: 3000,
	  });
	} else {
	  return error.response;
	}
  }


  export const  positionSuffix = (i: number) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return   "st";
    }
    if (j === 2 && k !== 12) {
        return   "nd";
    }
    if (j === 3 && k !== 13) {
        return  "rd";
    }
    return  "th";
}
