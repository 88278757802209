


import { Modal } from "antd";
import { useAcceptorDeclineSenderID,     } from "hooks/useSederId";
import React  from "react";
 



 


type PropType = {
	openModal:  {[e: string]: any, type: string},
	setModal: React.Dispatch<React.SetStateAction< {[e: string]: any, type: string}>>
	onDone: () => void
  };
   


const ApproveSenderIdModal: React.FC<PropType> = ({openModal, setModal, onDone}) => {
 

  const { handleAccceptOrDeclienSenderID, loading  } =  useAcceptorDeclineSenderID(openModal?._id, "approved")

 
 
 

	  const onSubmit = async () => {
		const res = await handleAccceptOrDeclienSenderID( {status: "APPROVED"});
		if(res?.data) {
			onDone()
			setModal({type: ""})
		}
	  };



  return (
		<Modal
			open={openModal.type === "ACCEOPT_SENDERID"}
			title="Approve Sender ID"
			
			onCancel={() => setModal({type: ""})}
			cancelText={"Cancel"}
			cancelButtonProps={{style: {color: "red"}}}
			okText="Yes Continue"
			okButtonProps={{ htmlType: "submit" }}
			onOk={onSubmit}
			confirmLoading={loading}
		>

			<div className="flex">
				<span className="text-center">You are about to approve sender ID. Are you sure you want to approve this sender ID?</span>
			</div>
		 
	
		</Modal>

  );
};

export default ApproveSenderIdModal;


 