// ts-ignore




import { DownloadIcon } from "assets/svg-code";
import CustomTable from "comps/CustomTable";
import { AdminRoute } from "hoc/adminRoute";
import { useGetKYC, useUpdateKYCStatus } from "hooks/useKYC";
import moment from "moment";
import   { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import {   useParams } from "react-router-dom";


const getName=  (link: string ) => {
	const item = link?.split(".")?.join(" ")?.split("/");
	return  item?.[item?.length - 1 ]
}
 

 
const MerchantKYC = () => {
 

	const { userId} = useParams()

	const [ready, setReady] = useState(false)


	
	const { handleGetKYC, KYC, loading } = useGetKYC(userId as string)
	const { handleUpdateKYCStatus, loading: updating} = useUpdateKYCStatus( )

	
	const handleDownloadIg = (img: string) => {
		var link = document.createElement('a');
		link.href = img;
		link.download = 'Download.jpg';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
 

	useEffect(() => {
		setReady(true)
		ready && handleGetKYC();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ready]);

	const columns = [
		{
			title: "S.N",
			index: "sn",
			dataIndex: "s/n",
			render: (text: string, record: {}, index: number) => <div>{index + 1}</div>
		},
		{
			key:  "businessName",
			title: <div>Business Name</div>,
			render: (record: any) => (
				<div className="">
					<div style={{width: "50px", height: "50px"}} className="bg-[#dadddf]">
						<img src={record.value}   alt=""  className="object-contain w-full h-full"/> 
					</div>
					{getName(record?.value)}
				</div>
			),
		},
		{
			key:  "Document Name",
			title: <div>Document Name</div>,
			render: (record: any) => (
				<div  className="py-3 rounded-sm text-sm px-1 flex justify-start cursor-pointer text-grey  ">  
					{ (record?.title ) }
				</div>
			),
		},
		{
			key:  "action",
			title: <div>Actions</div>,
			render: (RECORD: any) => (
				<button  className="py-3 rounded-sm text-sm px-1 flex justify-start cursor-pointer text-white  "
				onClick={() => handleDownloadIg(RECORD?.value)}
				>  
					<DownloadIcon iconColor="grey" width={20} height={20} />
				</button>
			),
		},
	];

	
	const data = KYC ?  [
		{ title: "business Cert", value: KYC?.meta?.requestBody?.businessCert, type: "img" },
		{ title: "tin Cert", value: KYC?.meta?.requestBody?.tinCert, type: "img" },
		{ title: "CAC7", value: KYC?.meta?.CAC7, type: "img" },
		{ title: "MEMART", value: KYC?.meta?.MEMART, type: "img" },
		{ title: "Utility Bill", value: KYC?.meta?.utilityBill, type: "img" },
		{ title: "COI", value: KYC?.meta?.COI, type: "img" },
		{ title: "CAC2", value: KYC?.meta?.CAC2, type: "link" },
	]: [];

	const status = {
		PENDING: "#1a29ac",
		APPROVED: "#2a760d",
		DECLINED: "#9d1515",
	}
	type StatusType = "PENDING" | "APPROVED" | "DECLINED"
	
return (
	<AdminRoute>
		<div >
			

				

			{
				loading ?
				<LoaderIcon   />
				: 
				<>
						{
							KYC ?
								<div className="flex">
									<button 
										onClick={() => handleUpdateKYCStatus (userId as string, "APPROVED")} 
										className={`flex text-[#ededed] hover:text-[#a2fa90] px-9  mr-4 py-3 rounded items-center bg-[#095d1a] `}
										style={{backgroundColor: updating === "APPROVED" ? "#656565" : '#095d1a'}}
										disabled={updating === "APPROVED"}
									>
										<span className="pl-2">Approve</span>
									</button>

									<button 
										onClick={() => handleUpdateKYCStatus(userId as string,  "DECLINED")} 
										className="flex text-[#ededed] hover:text-[#fa9090] px-9  py-3 rounded items-center bg-[#5d0909] " 
										style={{backgroundColor: updating === "DECLINED" ? "#656565" : '#5d0909'}}
										disabled={updating === "DECLINED"}
									>
										<span className="pl-2">Decline</span>
									</button>
								</div>
								: null
						}

					{
						KYC ?
							<>
								<div className=" mt-8">
									<span>STATUS:</span>
									<span  className={`ml-7  `} style={{color: status[KYC?.status as StatusType], fontWeight: "bold"}}>
										{KYC ? KYC?.status || "PENDING" : "N/A"} 
									</span>
								</div>
								<div className=" mt-8">
									<span>Date:</span>
									<span  className={`ml-7 text:#747070 `} style={{  fontWeight: "bold"}}>
										{KYC?.createdAt  ?  moment(KYC?.createdAt ).format("DD/MM/YYYY HH:MM a") : "N/A"} 
									</span>
								</div>
							</>
							:null
					}


	


					<div className="mt-10 w-full ">
						<CustomTable
							tableName="Merchants"
							dataSource={ data?.map((data: {[id: string]: string}) => ({...data, key: data?.title}))}
							loading={loading}
							columns={columns}
							pagination={{
								pageSize: 10,
								total: 1,
							}}
						/>
					</div>
				</>
			}

		</div>
	</AdminRoute>
  );
};

export default MerchantKYC;
