

import {   leftarrow,     } from "assets/svg-code";
import {   StepType,   } from "utils/types";
import    "../../../App.css";


 

const KYC_NIN =  (props: {title: string, goBack: (e: StepType) => void})  => {
  const { title,  goBack,   } = props;
 

	
	return (
		<div className="flex flex-col">
			<div className="flex items-start">
				<button className="appearance-none pt-2" onClick={() => goBack("kyc")}>
				{leftarrow}
				</button>
				<div className="flex flex-col ml-5 w-full">
					<div>
						<h1 className="text-2xl font-semibold font-sourceSans text-[#1A1B1C] mb-2">
							<span className="capitalize">{title}</span>
						</h1>
					</div>
				<p  className="w-10/12  general-sans-light font-normal text-xl text-[#404345]">
				The Nigerian Identity Number (NIN) is an 11-digit identifier issued by the National Identity Management Commission (NIMC) to Nigerian citizens and legal residents. It serves as a primary means of identification and authentication for various government and private sector services. By capturing an individual's demographic and biometric data in the National Identity Database, the NIN system establishes a single, reliable source of identification, enhances security, and improves the efficiency of service delivery for Nigerian citizens and residents.	
				</p>
				</div>
			</div>


		</div>
	);
};

export default KYC_NIN;
