import { useEffect, useState } from "react";
import { getUser } from "services";
import { useUserAuthContext } from "context/UserAuthContext";
import toast from "react-hot-toast";
import { ClockIcon, MailIcon, PhoneIcon } from "assets/svg-code";
import moment from "moment";

const Overview = () => {
	const { userDetails: { user: { userId }, },} = useUserAuthContext();
	const [userBusinessDetails, setUserBusinessDetails] = useState<any>(null);

	const fetchUserDetails = async () => {
		const res = await getUser(userId);
		if (res.status >= 200 && res.status <= 299) {
			setUserBusinessDetails(res.data?.data);
		} else {
			toast.error("Dashboard Stats Not found!!!");
		}
	};

	useEffect(() => {
		fetchUserDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

 

  return (
    <div>
      <div className="w-full my-5 flex items-center gap-x-3">
        <img
          src={userBusinessDetails?.profile?.businessLogo}
          alt="business logo"
          className="w-[80px] h-[80px] md:w-[160px] md:h-[160px] rounded-full"
        />
        <div className="flex flex-col text-[#1A1B1C] font-sourceSans">
          <h1 className="text-2xl capitalize">
            {userBusinessDetails?.profile?.businessName}
          </h1>
          <h5 className="text-base">
            {userBusinessDetails?.profile?.businessAddress || "Lagos, Nigeria"}
          </h5>
        </div>
      </div>

	  <div className="my-10 flex items-center justify-between w-10/12">
        <div className="flex items-center gap-x-4">
            <h3 className="m-0 p-0 text-[#1A1B1C]">Business Id</h3>
            <b className="m-0 p-0 text-[#096bf4]"> {userId} </b>
        </div>
      </div>


      <div className="my-10 flex items-center justify-between w-10/12">
        <div className="flex items-center gap-x-4">
          <MailIcon />
          <div>
            <h3 className="m-0 p-0 text-[#1A1B1C]">Email Address</h3>
            <h4 className="m-0 p-0 text-[#55595C]">
              {userBusinessDetails?.profile?.email}
            </h4>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <PhoneIcon />
          <div>
            <h3 className="m-0 p-0 text-[#1A1B1C]">Phone Number</h3>
            <h4 className="m-0 p-0 text-[#55595C]">
              +{userBusinessDetails?.phoneNumber}
            </h4>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <ClockIcon />
          <div>
            <h3 className="m-0 p-0 text-[#1A1B1C]">Date Registered</h3>
            <h4 className="m-0 p-0 text-[#55595C]">
              {moment(userBusinessDetails?.createdAt * 1000).format("DD/MM/YYYY")}
            </h4>
          </div>
        </div>
      </div>
    
     
      <div className="my-10 flex items-start justify-between w-6/12">
        <div>
          <h3 className="mb-2 text-base">Company Logo</h3>
          <img
            src={userBusinessDetails?.profile?.businessLogo}
            alt="business logo"
            className="w-[80px] h-[80px]"
          />
        </div>
        <div>
          <h3 className="mb-2 text-base">Company Colours </h3>
          <div className="flex items-center gap-x-4">
            <div>
              <div style={{ backgroundColor: userBusinessDetails?.profile?.businessMainColor || "lightgray",}} className="h-[64px] w-[80px]" ></div>
              <h4>Main Color</h4>
              <h5>{userBusinessDetails?.profile?.businessMainColor}</h5>
            </div>
            <div>
              <div style={{ backgroundColor: userBusinessDetails?.profile?.businessSecondaryColor || "lightgray", }} className="h-[64px] w-[80px]" ></div>
              <h4>Secondary Colour</h4>
              <h5>{userBusinessDetails?.profile?.businessSecondaryColor}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
