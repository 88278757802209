// ts-ignore

import AuthLayout from "comps/AuthLayout";
import CustomTable from "comps/CustomTable";
import { AdminRoute } from "hoc/adminRoute";
 
import { useGetAllKYC, useUpdateKYCStatus } from "hooks/useKYC";
import moment from "moment";
import   { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

 

 
const Customer = () => {
 
	const { handleGetKYC, KYC, loading } = useGetAllKYC()
	const [ready, setReady] = useState(false)
	const navigate = useNavigate();

	const { handleUpdateKYCStatus, loading: updating} = useUpdateKYCStatus( )


	const [ , setPage] = useState<number>(1);


	const handleUpdateStatus = async (userId: string, status: string, loadingStr: string ) => {
		await handleUpdateKYCStatus( userId, "APPROVED",  loadingStr)
		handleGetKYC()
	}
 
	const columns = [
		{
			title: "S.N",
			index: "sn",
			dataIndex: "s/n",
			render: (text: string, record: {}, index: number) => <div>{index + 1}</div>
		},
		{
			title: "Status",
			key: "status",
			render: (record : any) => <div>
				{
					record?.status?.toUpperCase() === "APPROVED" ?
						<div className=" rounded-sm text-sm mx-1 flex justify-start cursor-pointer text-green-500 hover:text-[#20a33a] text-green-600">{record?.status}</div>
						: record?.status === "DECLINED" ?
							<div className=" rounded-sm text-sm mx-1 flex justify-start cursor-pointer text-red-500 hover:text-[#a32020] text-green-600">{record?.status}</div>

							: record?.status === "PENDING" ?
								<div className=" rounded-sm text-sm mx-1 flex justify-start cursor-pointer text-amber-400 hover:text-[#9fa320] text-green-600">{record?.status}</div>
								: record?.status
				}
				
			</div>
		},
		{
			title: "Email",
			key: "email",
			render: (record : any) => <div>{record?.email}</div>
		},
		{
			key:  "Created At",
			title: <div>Create dAt  </div>,
			render: (record: any) => <> {moment(record?.createdAt).format("DD/MM/YYYY")}</>
		},
		{
			key:  "Actions",
			title: <div>Actions</div>,
			render: (phone: any) => (
				<div  className="py-3 rounded-sm text-sm px-1 flex justify-start cursor-pointer text-white  ">  
					{
						phone?.status === "APPROVED" ? null :
						<>
							<div 
								className=" rounded-sm text-sm mx-1 flex justify-center cursor-pointer text-[#184E77] hover:text-[#20a33a] text-green-600"
								onClick={() => handleUpdateStatus(phone?.userId, "APPROVED", phone?.userId + "APPROVED")}
							>  
								{updating === phone?.userId +  "APPROVED"? <LoaderIcon /> : "Accept"}
							</div>
							<div  className=" rounded-sm text-sm mx-1 flex justify-center cursor-pointer  border-r-2 border-solid border-[#c1c1c1]">  |  </div>
						</>
					}
					{
						phone?.status === "DECLINED" ? null :
						<div 
							className=" rounded-sm text-sm mx-1 flex justify-center cursor-pointer  text-[#cd1a1a] hover:text-[#87c2f0]"
							onClick={() =>  handleUpdateStatus(phone?.userId, "DECLINED", phone?.userId + "DECLINED" )}
						>  
							{
								updating === phone?.userId+  "DECLINED" ? <LoaderIcon /> : "Decline"
							}
							  
						</div>
					}
					<div  className=" rounded-sm text-sm mx-1 flex justify-center cursor-pointer  border-r-2 border-solid border-[#c1c1c1]">  |  </div>
					<div 
						className=" rounded-sm text-sm mx-1 flex justify-center cursor-pointer  text-[#184E77] hover:text-[#87c2f0]"
						onClick={() => navigate(`/kyc/${phone?.userId}` )}
					>  
						View  
					</div>
				</div>
			),
		},
	];

 

	useEffect(() => {
		setReady(true)
		ready && handleGetKYC ();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ready]);
 


	
	
return (
	<AdminRoute>

		<AuthLayout name="KYC">

			<div className="mt-10 w-full ">
				<CustomTable
					tableName="KYC"
					dataSource={KYC?.map((data: {[id: string]: string}) => ({...data, key: data?.id}))}
					loading={loading}
					columns={columns}
					pagination={{
						pageSize: 10,
						total: KYC?.total,
						onChange: (page: any) => {
							setPage(page);
						},
					}}
				/>
			</div>

		</AuthLayout>
	</AdminRoute>
  );
};

export default Customer;
