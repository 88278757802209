import { BackIcon, LockIcon } from "assets/svg-code";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ViewMore = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location.state.snippet) {
      navigate("/integration");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
    <div className="bg-white w-full min-h-screen">
      <div className="flex justify-center items-center mt-5 w-11/12 md:w-10/12 h-fit mx-auto gap-x-1 md:gap-x-4 mb-10">
        <button
          type="button"
          className="appearance-none"
          onClick={() => navigate(-1)}
        >
          <BackIcon />
        </button>

        <div className="flex items-center border border-[#D5D7D8] bg-white rounded-2xl w-11/12 md:w-9/12 px-2 py-1">
          <LockIcon />
          <input
            type="text"
            className="w-full p-2 h-11  outline-none appearance-none"
            placeholder="https://www.google.com/search?q=Sanimara+solutions+url&sxsrf=ALiCzsbgeTWEnn22W3s0s0hoy386dKyHMg%3A166415176300&ei=0vAwY-HvPJmDxc8PxOtfs2Ag&=..."
          />
        </div>
      </div>

      <div className="bg-slate-50 w-11/12 md:w-8/12 mx-auto min-h-screen">
        <pre>
          <code lang="curlang">{location?.state?.snippet}</code>
        </pre>
      </div>
    </div>
  );
};

export default ViewMore;
