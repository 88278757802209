import safepro, { safeproMiddleWare } from "axiosApi";

import toast from "react-hot-toast";

export {
  registerAccount,
  login,
  fetchCustomers,
  forgotPassword,
  middlewareSendCode,
  verfiyOtp,
  changeMyPassword,
  verifyAccount,
  getUser,
  middlewareVerify,
  updateWebookUrl,
//   transactionMiddleware,
  fetchCustomersMiddleware,
  fetchDashboardStats,
  updateIntegrationType,
  updateRedirectUrl,
  createBusiness,
  fetchBusinessDetails,
  onboardUser,
} from "./auths";

function handleRequestError(error: any) {
  if (error.toString() === "Error: Network Error") {
    return toast.error("Try again: Internet connectivity", {
      duration: 3000,
    });
  } else {
    return error.response;
  }
}

export const createWebhook = async ({
  userId,
  webhookUrl,
}: {
  userId: string;
  webhookUrl: string;
}) => {
  try {
    const res = await safeproMiddleWare.post("/webhook", {
      userId,
      webhook: webhookUrl,
    });
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const fetchWebhookByUserId = async (userId: string) => {
  try {
    const res = await safeproMiddleWare.get(`/webhook/${userId}`);
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const getApiKeys = async (userId: string) => {
  try {
    const res = await safeproMiddleWare.get(`/business/key/${userId}`, 
	{
		headers: {
			"client-id": process.env.REACT_APP_CLIENT_ID,
		  },
	}
	);
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const fetchBusiness = async (userId: string,  ) => {

	
  try {
    const res = await safeproMiddleWare.get(`/business?userId=${userId}`,
	{
		headers: {
			"client-id": process.env.REACT_APP_CLIENT_ID,
		  },
	}
	);
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const resetPassword = (token: string, password: string) => {
  try {
    const res = safepro.post(
      `/users/v1/passwords/change`,
      {
        password: password,
      },
      {
        headers: {
          "client-id": process.env.REACT_APP_CLIENT_ID,
          Authorization: "Bearer " + token,
        },
      }
    );
    return res;
  } catch (error) {
    return handleRequestError(error);
  }
};
