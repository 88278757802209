import { leftarrow2 } from "assets/svg-code"
import {  useNavigate } from "react-router-dom"







export const GoBack = ({backLink}: {backLink: string}) => {

	const navigate = useNavigate()



	return (
		<div>
			<button 
				onClick={() => navigate(backLink)}
				className="flex text-gray-400 hover:text-gray-500 items-center" >
					{leftarrow2({color: "#9d9898", width: 15, height: 15})} <span className="pl-2">Go Back</span>
			</button>
		</div>
	)
}