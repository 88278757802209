




 

import { safeproMiddleWare } from "axiosApi";
import { useUserAuthContext } from "context/UserAuthContext";
import {   useState } from "react";
import { handleRequestError } from "utils";




export const useGetKYC = (userId: string) => {
	const [loading, setLoading] = useState(false);
	const [KYC, setKYC] = useState<any>({});
	const { userDetails: {   token, }} = useUserAuthContext();



	const  handleGetKYC = async ( ) => {
		setLoading(true)
		try {
			const response = await safeproMiddleWare.get(
			  `/kyc/${userId}`,
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  "Authorization": `Bearer ${token}`
				},
			  }
			);
			setLoading(false)
			if(response.data){
				setKYC(response?.data?.data)
				return response;
			}
			
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
 
	return { handleGetKYC, loading , KYC}
}

export const useGetAllKYC = () => {
	const [loading, setLoading] = useState(false);
	const [KYC, setKYC] = useState<any>([]);
	const { userDetails: {   token, }} = useUserAuthContext();
	const [pageInfo, setPage] = useState({page: 1, limit: 10})


	const  handleGetKYC = async ( ) => {
		setLoading(true)
		try {
			const response = await safeproMiddleWare.get(
			  `/kycs?page=${pageInfo.page}&limit=${pageInfo.limit}`,
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  "Authorization": `Bearer ${token}`
				},
			  }
			);
			setLoading(false)
			if(response.data){
				setKYC(response?.data?.data)
				return response;
			}
			
		  } catch (error) {
			setLoading(false)
			return handleRequestError(error);
		  }
	}
 
	return { handleGetKYC, loading , KYC, setPage}
}


export const useUpdateKYCStatus = () => {
	const [loading, setLoading] = useState("");
	const [KYC, setKYC] = useState<any>({});
	const { userDetails: {   token, }} = useUserAuthContext();


	const  handleUpdateKYCStatus = async (userId: string,  status: string, loadingStr?: string) => {
		setLoading( loadingStr  || status)
		try {
			const response = await safeproMiddleWare.put(
			  `/kyc/change-status/${userId}`,
			  {status},
			  {
				headers: {
				  "client-id": process.env.REACT_APP_CLIENT_ID,
				  "Authorization": `Bearer ${token}`
				},
			  }
			);
			setLoading("")
			if(response.data){
				setKYC(response?.data?.data)
				return response;
			}
			
		  } catch (error) {
			setLoading("")
			return handleRequestError(error);
		  }
	}
 
	return { handleUpdateKYCStatus, loading , KYC}
}