import { useUserAuthContext } from "context/UserAuthContext";

export const useAuth = () => {
  const { userDetails } = useUserAuthContext();
  return userDetails?.user;
};



export const useGetAdmin = () => {
  const { userDetails: {  user: { userId }, }, } = useUserAuthContext();
 


    return{isAdmin :  userId === process.env.REACT_APP_ADMIN_USER_ID, adminID: process.env.REACT_APP_ADMIN_USER_ID};
};
