import { useAuth } from "hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useCheckKYC, useGetUser } from "services/auths";

const ProtectedRoutes = ({ children }: any) => {
	const location = useLocation();
	const user = useAuth();

	useGetUser();
	useCheckKYC()


	return user ? (
		<Outlet />
	) : (
		<Navigate to="/login" replace state={{ from: location }} />
	);
};
export default ProtectedRoutes;
