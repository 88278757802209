
import { ArrorIcon, ArrorTrendUpIcon,    WalletIcon } from "assets/svg-code";
import AuthLayout from "comps/AuthLayout";
import Charts from "comps/Charts";
import { CURRENCY_ICON, CURRENCY_STRING } from "constants/index";
import { useUserAuthContext } from "context/UserAuthContext";
import { useGetAdmin } from "hooks/useAuth";
import React, { useState } from "react";
import  { LoaderIcon } from "react-hot-toast";
import { useGetDasboardKPI } from "services/auths";
import { positionSuffix } from "utils";


const Dashboard = () => {
	const { userDetails: { user}, } = useUserAuthContext();
	const [tab, setTab] = useState("TOATL_CUSTOMERS")
	
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sepr", "Oct", "Nov", "Dec"];
	const {KPI, loading} =   useGetDasboardKPI()

	const {isAdmin } = useGetAdmin()


	const total = [
		{
			title: "Total Customers", 
			value: KPI?.totalCustomer || 0, 
			bgCol: "#fafffc",
			txtCol: "#012512",
			icon: <ArrorIcon width={20} height={20}/>
		},
		{
			title: "Total Transactions", 
			value:  KPI?.totalTransactions || 0,
			bgCol: "#fbfaf5",
			txtCol: "#8b6719",
			icon: <ArrorTrendUpIcon  width={20} height={20}/>
		},
		{
			title: "Total Merchants", 
			value:  KPI?.totalMerchants || 0,
			bgCol: "#fbfaf5",
			txtCol: "#8b6719",
			icon: <ArrorTrendUpIcon  width={20} height={20}/>
		},
		
		isAdmin ? null :
		{
			title: "Total Spent", 
			value: `${CURRENCY_STRING}${KPI?.totalSpent || 0}`,
			bgCol: "#fffaff",
			txtCol: "#260123",
			icon: <WalletIcon  width={20} height={20}/>
		},
		isAdmin ? null :
		{
			title: "Balance", 
			value: `${CURRENCY_STRING}${KPI?.balance || 0}`,
			bgCol: "#fbf8fe",
			txtCol: "#1C033C",
			icon: CURRENCY_ICON
		},
	]
 
	KPI?.customerGraph?.sort((a: any, b: any) => {
		if (a?._id?.year > b?._id?.year) return 1;
		if (a?._id?.year < b?._id?.year) return -1;
		return 0;
	});
	KPI?.customerGraph?.sort((a: any, b: any) => {
		if (a?._id?.year ===  b?._id?.year) {
			if (a?._id?.month > b?._id?.month) return 1;
			if (a?._id?.month < b?._id?.month) return -1;
			return 0;
		 }
		 return 0;
	});
	KPI?.customerGraph?.sort((a: any, b: any) => {
		if (a?._id?.year ===  b?._id?.year && a?._id?.month  === b?._id?.month ) {
			if (a?._id?.day > b?._id?.day) return 1;
			if (a?._id?.day < b?._id?.day) return -1;
			return 0;
		 }
		 return 0;
	});

	KPI?.transactionGraph?.sort((a: any, b: any) => {
		if (a?._id?.year > b?._id?.year) return 1;
		if (a?._id?.year < b?._id?.year) return -1;
		return 0;
	});
	KPI?.transactionGraph?.sort((a: any, b: any) => {
		if (a?._id?.year ===  b?._id?.year) {
			if (a?._id?.month > b?._id?.month) return 1;
			if (a?._id?.month < b?._id?.month) return -1;
			return 0;
		 }
		 return 0;
	});
	KPI?.transactionGraph?.sort((a: any, b: any) => {
		if (a?._id?.year ===  b?._id?.year && a?._id?.month  === b?._id?.month ) {
			if (a?._id?.day > b?._id?.day) return 1;
			if (a?._id?.day < b?._id?.day) return -1;
			return 0;
		 }
		 return 0;
	});

 

	return (
		<AuthLayout name="Dashboard">
			<h2 className="text-[20px] text-[#2B2D2E] font-sourceSans font-normal">
				Good day {" "}
				<span className="capitalize">
					{user?.profile?.businessName || ""}
				</span>,
			</h2>


			<div className="  mx-auto  grid gap-8  mb-8"  style={{gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"}}>
				{
					total.map((item, idx) => {
						if(!item) return <React.Fragment key={idx}></React.Fragment>;
						return (
							<div 
								key={item.title + idx}
								className="p-7 rounded-lg grid  gap-6 items-center" 
								style={{backgroundColor: item.bgCol}}
							>
								<div className="flex justify-start">
									{item.icon}
									<span className="text-start ml-1 text-[20px] font-normal general-sans" style={{color: item.txtCol}}>{item.title}</span>
								</div>
								<div className="m-0 p-0 flex gap-x-5">
						
									<b style={{color: item.txtCol}} className="font-normal m-0 p-0 text-5xl font-light inter">
										{item.value || 0}
									</b>
								</div>
							</div>
						)
					})
				}


			</div>

			{
				loading ?
					<LoaderIcon style={{width: 30, height: 30, margin: "50px auto"}}/>
					:
					<div >
						<div className={`p-2 bg-[#f1f7fd] flex m-auto w-max my-4`}>
							<button 
								className={` ${tab === "TOATL_CUSTOMERS" ? 'bg-[#184e77]' : "bg-[#f1f7fd]" } 
									px-5 py-2 rounded 
									${tab === "TOATL_CUSTOMERS" ? 'text-white' : "text-[#a3a5a7]"}`} 
								onClick={() => setTab("TOATL_CUSTOMERS")}
							>
								Total Customers
							</button>
							<button 
								className={`${tab === "TOATL_TRANSACTIONS" ? 'bg-[#184e77]' : "bg-[#f1f7fd]" } px-5 py-2 rounded 
								${tab === "TOATL_TRANSACTIONS" ? 'text-white' : "text-[#a3a5a7]"}`} 
								onClick={() => setTab("TOATL_TRANSACTIONS")}
							>
								Total Transactions
							</button>
						</div>


						{
							tab === "TOATL_CUSTOMERS" ?
								<div className="w-full"> 
									<label className="general-sans">Number of Customers</label>
									<Charts
										type="line"
										labels= {KPI?.customerGraph?.map((item: any) =>item?._id?.day + positionSuffix(item?._id?.day) + " "  +  monthNames[item?._id?.month - 1]  + ", " + item?._id?.year  || "")}
										datasets= {[
											{
												borderWidth: 2,
												pointRadius: 1,
												label: "Transactions",
												data: KPI?.customerGraph?.map((item: any) => item?.count) || [0],
												backgroundColor: "#77b59b44",
												borderColor: "#77b59b",
												fill: true,
												spacing: 4,
												offset: 5,
											},
										]}
										dependency={tab}
									/>
									<span className="text-center block">Date</span>
								</div>
								:
								<div className="w-full "> 
									<label>Number of Transactions</label>
									<Charts
										type="line"
										labels= {KPI?.customerGraph?.map((item: any) =>item?._id?.day + positionSuffix(item?._id?.day) + " "  +  monthNames[item?._id?.month - 1]  + ", " + item?._id?.year  || "")}
										datasets= {[
											{
												borderWidth: 2,
												pointRadius: 1,
												label: "Customers",
												data: KPI?.transactionGraph?.map((item: any) => item?.count),
												backgroundColor: "#e9c0a147",
												borderColor: "#e9c0a1",
												fill: true,
												spacing: 4,
												offset: 5,
											},
										]}
										dependency={tab}
									/>
									<span className="text-center block">Date</span>
								</div>
						}
			
					</div>
			}
		</AuthLayout>
	);
};

export default Dashboard;
