import React from "react";
import AuthGif from "./AuthGif";

const AuthRight = () => {
  return (
    <div className="bg-primary w-full md:w-6/12 min-h-screen hidden md:block">
      <AuthGif />
      <div className="w-8/12 mx-auto text-left">
        <h1 className="text-white text-3xl  font-openSans">
          A fast way to send your codes
        </h1>
        <p className="text-safewhite mt-3 text-base text-left font-openSans">
          With SAFEPro, you can easily send codes to your
          <br /> customers without breaking a sweat
        </p>
      </div>
    </div>
  );
};

export default AuthRight;
